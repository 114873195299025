import React, { useState } from "react";

// Libraries
import useForm from "@hybris-software/use-ful-form";
import { InputField, Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import AuthCode from "react-auth-code-input";

// Utils
import countries from "../../../../Utils/countries";

// Icons
import { IoIosArrowDown } from "react-icons/io";

// Styles
import Style from "./BasicInformation.module.css";
import GeneralPopup from "../../../../Components/Commons/GeneralPopup/GeneralPopup";

const BasicInformation = ({
	title,
	country,
	data,
	// username,
	// name,
	// email,
	// phone,
	// city,
	// zipCode,
	// wallet,
}) => {

	const [openDropdown, setOpenDropdown] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState({ lang: "", value: "" });
	const [twoFactor, setTwoFactor] = useState(false);
	const [errorCall, setErrorCall] = useState(null);
	const [authCode, setAuthCode] = useState("");

	const form = useForm({
		inputs: {
			email: {
				nature: "email",
				required: true,
				value: data?.email || "",
			},
			country: {
				required: true,
				value: data?.country || "",
			},
			phoneNumber: {
				required: false,
				value: data?.phoneNumber || "",
			},
		}
	})

	const updateProfile = useQuery({
		url: "auth/user/",
		method: "POST",
		executeImmediately: false,
		onSuccess: (response) => {
			setTwoFactor(true)
		},
		onError: (error) => {
		}
	})

	const confirmUpdate = useQuery({
		url: "auth/user/update-confirm/",
		method: "POST",
		executeImmediately: false,
		onError: (error) => {
			setErrorCall(error.response.data.message)
		}
	})

	return (
		<section className={Style.basicInfo}>

			{twoFactor &&
				<GeneralPopup>
					<div className={Style.confirm}>
						<h4>Confirm Update Information</h4>
						<p>For security reasons, we need to confirm your identity. Please enter the code sent to your email.</p>
						<div className={Style.form}>
							<AuthCode
								allowedCharacters="numeric"
								onChange={(e) => {
									setAuthCode(e);
									setErrorCall(null);
								}}
							/>
							{errorCall && <div className={Style.errorCall}>{errorCall}</div>}
						</div>

						<div className={Style.buttons}>
							<Button
								onClick={() => { setTwoFactor(false) }}
							>
								Close
							</Button>
							<Button
								onClick={() => {
									confirmUpdate.executeQuery({
										otp: authCode,
									})
								}}
							>
								Confirm
							</Button>
						</div>
					</div>
				</GeneralPopup>
			}

			{twoFactor && confirmUpdate.isSuccess &&
				<GeneralPopup>
					<div className={Style.success}>
						<h4>Success</h4>
						<p>Your information has been updated successfully.</p>
						<Button
							onClick={() => { window.location.reload() }}
						>
							Close
						</Button>
					</div>
				</GeneralPopup>
			}


			{title && <h6 className={Style.title}>{title}</h6>}

			<div className={Style.userInfo}>
				<div className={Style.label}>{country} *</div>
				<div className={Style.row}>
					<div className={Style.boxDataSelect}>
						<div
							className={Style.select}
							onClick={() => setOpenDropdown((oldState) => !oldState)}
							onMouseLeave={() => setOpenDropdown(false)}
						>
							<div className={Style.selected}>
								<span className={Style.actual}>
									{data.country && selectedLanguage.lang === ""
										? countries.filter((el) => el.value === data.country).map((el) => el.text) + ` (${data.country})`
										: selectedLanguage.value !== ""
											? selectedLanguage.lang + " (" + selectedLanguage.value + ")"
											: "-"
									}
								</span>
								<span className={openDropdown ? Style.arrowOpened : Style.arrow}>
									<IoIosArrowDown />
								</span>
							</div>
							<div
								className={openDropdown === true ? Style.optionsOpened : Style.options}
								style={openDropdown === true ? { maxHeight: "220px" } : { maxHeight: "0px" }}
							>
								{countries.map((el, i) => {
									return (
										<div key={i} onClick={() => {
											setSelectedLanguage({ lang: el.text, value: el.value });
											form.getInputProps("country").setValue(el.value);
										}}>
											<span className={Style.actual}>
												{el.text + " (" + el.value + ")"}
												{el.selected && <span className={Style.selectedIcon}>✓</span>}
											</span>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>

				<InputField
					label="Email *"
					placeholder="Enter your email"
					className={Style.inputProfile}
					{...form.getInputProps("email")}
				/>

				<InputField
					placeholder="Enter your phone number"
					label="Phone (Optional)"
					className={Style.inputProfile}
					{...form.getInputProps("phoneNumber")}
				/>

				<Button
					className={Style.button}
					disabled={!form.isValid()}
					isLoading={updateProfile.isLoading}
					onClick={() => {
						updateProfile.executeQuery({
							...form.getApiBody(),
							country: selectedLanguage.value !== "" ? selectedLanguage.value : data.country,
						})
					}}
				>
					Save
				</Button>



				{/* <div className={Style.row}>
					<div className={Style.label}> {name}</div>
					<div className={Style.boxData}>{data.fullName || "-"}</div>
				</div>

				<div className={Style.row}>
					<div className={Style.label}>{username}</div>
					<div className={Style.boxData}>{data.username || "-"}</div>
				</div>

				<div className={Style.row}>
					<div className={Style.label}>{city}</div>
					<div className={Style.boxData}>{data.city || "-"}</div>
				</div>

				<div className={Style.row}>
					<div className={Style.label}>{zipCode}</div>
					<div className={Style.boxData}>{data.postCode || "-"}</div>
				</div>

				<div className={Style.row}>
					<div className={Style.label}>{wallet}</div>
					<div className={Style.boxData}>{data.walletAddress || "-"}</div>
				</div> */}
			</div>
		</section >
	);
};

export default BasicInformation;
