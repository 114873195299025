import React, { useState, useEffect } from 'react'

// Libraries
import { Container } from '@hybris-software/ui-kit'
import useQuery from '@hybris-software/use-query'

// Components
import Heading from '../../Components/Commons/Heading/Heading'
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";

// Icons
import { FaCopy } from 'react-icons/fa'

// Hooks
import useText from "../../Hooks/useText";

// Styles
import Style from "./DepositHistory.module.css";

const DepositHistory = () => {
    const texts = useText("depositHistory");
    const { head, page, table } = texts;

    useEffect(() => {
        document.title = "PULSE WORLD | " + page;
    }, [page]);

    const columns = [
        {
            Header: table.labelThree,
            accessor: (row) => {
                return (
                    <div
                        title="Copy this text to clipboard"
                        className={Style.transaction}
                        onClick={() => { navigator.clipboard.writeText(row.transactionId) }}
                    >
                        <div className={Style.icon}>
                            <FaCopy />
                        </div>
                        {row.transactionId}
                    </div>
                )
            },
        },
        {
            Header: table.labelTwo,
            accessor: (row) => { return row.orderId },
        },
        {
            Header: table.labelOne,
            accessor: (row) => {
                if (row.status === "created") {
                    return <span className={Style.pending}>PENDING</span>
                }
                if (row.status === "completed") {
                    return <span className={Style.completed}>COMPLETED</span>
                }
                if (row.status === "expired") {
                    return <span className={Style.expired}>EXPIRED</span>
                }
                if (row.status === "cancelled") {
                    return <span className={Style.cancelled}>CANCELLED</span>
                }
                if (row.status === "partial") {
                    return <span className={Style.partial}>PARTIAL</span>
                }
            },
        },
        {
            Header: table.labelFour,
            accessor: (row) => {
                if (row.amount === null) return "-";
                else return row.amount + " PUSD";
            },
        },
        {
            Header: table.labelFive,
            accessor: (row) => { return row.createdAt },
        },
    ];

    //   Table
    const [tableData, setTableData] = useState(null);
    const [url, setUrl] = useState("wallets/deposit/?page=1&limit=10");

    const tableApi = useQuery({
        url: url,
        method: "GET",
        executeImmediately: false,
        onSuccess: (response) => {
            setTableData(response.data);
        },
    });

    useEffect(() => {
        tableApi.executeQuery();
        // eslint-disable-next-line
    }, [url]);

    return (
        <Container>
            <Heading {...head} />
            {tableData && (
                <GeneralTable
                    height="730px"
                    title={table.title}
                    subtitle={table.alert}
                    data={tableData}
                    columns={columns}
                    setTableData={setTableData}
                    setUrl={setUrl}
                    isLoading={tableApi.isLoading}
                ></GeneralTable>
            )}
        </Container>
    )
}

export default DepositHistory