import React, { useEffect } from "react";

// Librerias
import { Container } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import SendBox from "./Components/SendBox/SendBox";
import Loader from "../../Components/Commons/Loader/Loader";

// Hooks
import useText from "../../Hooks/useText";

const SendAw = () => {

  const navigate = useNavigate();
  const texts = useText("sendAw");
  const { head, sendBox, page } = texts;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const pWalletBalance = useQuery({
    url: "wallets/balance/ausd/",
    method: "GET",
    executeImmediately: true,
  });

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={pWalletBalance.isLoading}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        <Heading {...head} />
        {pWalletBalance.response && (
          <SendBox
            {...sendBox}
            data={pWalletBalance.response.data}
            updateBalance={pWalletBalance}
          />
        )}
      </Container>
    </AuthRoute>
  );
};

export default SendAw;
