import React, { useEffect, useState } from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import SimpleSeedLabel from "./Components/SimpleSeedLabel/SimpleSeedLabel";
import CardGrid from "./Components/CardGrid/CardGrid";
import Loader from "../../Components/Commons/Loader/Loader";
import LimitedCardGrid from "./Components/LimitedCardGrid/LimitedCardGrid";

// Hooks
import useText from "../../Hooks/useText";

const ShopList = () => {

  const navigate = useNavigate();
  const texts = useText("shopList");
  const { head, seed, page } = texts;

  const [tokenPrice, setTokenPrice] = useState(1)

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const roundInfoApi = useQuery({
    url: "xpl/round/",
    method: "GET",
    executeImmediately: true,
  });

  const packagesApi = useQuery({
    url: "xpl/offers/",
    method: "GET",
    executeImmediately: true,
  });

  const specialPackagesApi = useQuery({
    url: "xpl/special-offers/",
    method: "GET",
    executeImmediately: true,
  });

  const tokenPriceApi = useQuery({
    url: 'https://openapi.tokex.pro/sapi/v1/ticker?symbol=xplusdt',
    method: 'GET',
    executeImmediately: true,
    onSuccess: (response) => {
      setTokenPrice(parseFloat(response.data.last))
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      tokenPriceApi.executeQuery();
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={
        roundInfoApi.isLoading ||
        packagesApi.isLoading ||
        specialPackagesApi.isLoading
      }
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        <Heading {...head} />
        {roundInfoApi.response && (
          <SimpleSeedLabel {...seed} tokenPrice={tokenPrice} roundName={roundInfoApi.response.data.name} />
        )}
        {specialPackagesApi.response && roundInfoApi.response && (
          <LimitedCardGrid
            {...seed.cardBuyLimited}
            data={specialPackagesApi.response.data}
            roundInfo={roundInfoApi.response.data}
          />
        )}
        {roundInfoApi.response && packagesApi.response && (
          <CardGrid
            {...seed.cardBuy}
            tokenPrice={roundInfoApi.response.data.tokenPackagePrice}
            data={packagesApi.response.data}
            roundInfo={roundInfoApi.response.data}
          />
        )}
      </Container>
    </AuthRoute>
  );
};

export default ShopList;
