function getDataFromNotification(type, otherUser) {
    if (
        type === "sendPusd" ||
        type === "sendAw" ||
        type === "receivedPusd" ||
        type === "receivedAw" ||
        type === "packagePurchasedFor" ||
        type === "packagePurchasedFrom"
    ) {
        return otherUser;
    } else {
        return "";
    }
}

export default getDataFromNotification;