import { useContext } from "react";

import LanguageContext from "../Contexts/languageContext";

const useLanguages = () => {
  const [language, setLanguage] = useContext(LanguageContext);
  
  return {language,setLanguage}
};

export default useLanguages;
