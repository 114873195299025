import React from "react";

// Libraries
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./Chart.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const Chart = ({ data }) => {
  const texts = useText("dashboard");
  const { title, time } = texts.chart;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            return "$ " + value;
          },
        },
      },
    },
  };

  const labels = data.labels;

  const dataChart = {
    labels,
    datasets: [
      {
        label: "XPL",
        data: data.xplBalance,
        backgroundColor: "#FFCE34",
        borderColor: "#FFCE34",
        tension: 0.3,
      },
      {
        label: "PUSD",
        data: data.pusdBalance,
        backgroundColor: "#2e00af",
        borderColor: "#2e00af",
        tension: 0.3,
      },
      {
        label: "AUSD",
        data: data.pWalletBalance,
        backgroundColor: "#7314ed",
        borderColor: "#7314ed",
        tension: 0.3,
      },
    ],
  };

  return (
    <div className={Style.chartContainer}>
      <div className={Style.header}>
        <h5>{title}</h5>
        <h6>{time}</h6>
      </div>

      <div className={Style.chart}>
        <Line
          options={options}
          data={dataChart}
          style={{
            width: "100%",
            transition: "width 0.3s",
            height: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default Chart;
