import React, { useEffect } from 'react'

// Components
import ComingSoon from '../ComingSoon/ComingSoon'

// Libraries
import { Container } from '@hybris-software/ui-kit'

// Images 
import IconOne from '../../Assets/images/icons/nft-market.svg';
import IconTwo from '../../Assets/images/icons/launch-nft.svg';
import IconThree from '../../Assets/images/icons/library.svg';
import PlaceHolderVideo from '../../Assets/images/nft.png';

// video
import NftVideo from '../../Assets/videos/nft.mp4';

// Hooks
import useText from '../../Hooks/useText'


const Nft = () => {

    const elementsComingSoon = {
        iconOne: IconOne,
        iconTwo: IconTwo,
        iconThree: IconThree,
        image: PlaceHolderVideo,
        texts: useText('nftComing'),
        video: NftVideo,
        page: useText('nftComing').page
    }

    useEffect(() => {
        document.title = 'PULSE WORLD | ' + elementsComingSoon.page
    }, [elementsComingSoon.page])

    return (
        <Container>
            <ComingSoon {...elementsComingSoon} />
        </Container>
    )
}

export default Nft