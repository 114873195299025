import React from 'react'

// Libraries
import { Button, InputField } from '@hybris-software/ui-kit';
import useForm from '@hybris-software/use-ful-form'

// Images
import XplCoin from "../../../../Assets/images/icons/xpl-coin.png";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./Swap.module.css";

const Swap = ({ redeemable, setShowSwap, fees, swapPackagesApi }) => {

    const texts = useText("staking");
    const { title, total, amount, fee, receive, close, swap } = texts.swapPopup;

    const form = useForm({
        inputs: {
            xpl: {
                formatter: (value) => {
                    // Only allow numbers and one decimal point
                    const decimal = value.split(".")[1];
                    if (decimal && decimal.length > 8) {
                        value = value.slice(0, -1);
                    }
                    if (
                        value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1") > parseFloat(redeemable)
                    ) {
                        return parseFloat(redeemable);
                    } else {
                        return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
                    }
                },
                validator: (value) => {
                    if (value > parseFloat(redeemable) || value === "" || value <= 0 || value === "0.") {
                        return [false, ""];
                    } else {
                        return [true, ""];
                    }
                },
            },
        },
    });

    return (
        <section className={Style.swap}>
            <div className={Style.heading}>
                <h3>{title}</h3>
                {total} <strong>{redeemable}</strong> XPL
            </div>

            <div className={Style.form}>
                <div className={Style.label}>
                    <img src={XplCoin} alt="" /> {amount}
                </div>
                <InputField
                    placeholder={"0.0"}
                    onCopy={false}
                    showError={false}
                    {...form.getInputProps("xpl")}
                />
            </div>

            <div>
                <div style={{ fontSize: 13, marginTop: 20 }}>{fee} {fees.swapFee.value}%</div>
                {receive} <strong>{(form.values["xpl"] * (1 - fees.swapFee.value / 100)).toFixed(2)}</strong>
            </div>

            <div className={Style.buttons}>
                <Button
                    className={Style.button}
                    onClick={() => setShowSwap(false)}
                >
                    {close}
                </Button>
                <Button
                    disabled={!form.isValid()}
                    isLoading={swapPackagesApi.isLoading}
                    className={Style.button}
                    onClick={() => {
                        swapPackagesApi.executeQuery({
                            amount: form.values["xpl"]
                        }); setShowSwap(false)
                    }}
                >
                    {swap}
                </Button>
            </div>
        </section>
    )
}

export default Swap