import React from 'react'

// Libraires
import { Button } from '@hybris-software/ui-kit';

// Images
import TransactionComplete from '../../../../Assets/images/transaction-complete-image.png';
import TransictionError from '../../../../Assets/images/error-transaction.png';

// Styles
import Style from "./Feedback.module.css";

const Feedback = ({ message, typeFeedback, setShowFeedback, stakingBalanceApi, stakingPackagesApi }) => {
    return (
        <div className={Style.feedback}>

            <img src={typeFeedback === "success" ? TransactionComplete : TransictionError} alt="" />

            <div className={Style.message}>
                {typeFeedback === "success" && message}
                {typeFeedback === "error" && message}
            </div>
            <Button
                onClick={() => { setShowFeedback(false); stakingBalanceApi.executeQuery(); stakingPackagesApi.executeQuery() }}
            >
                Close
            </Button>
        </div>
    )
}

export default Feedback