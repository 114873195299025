import React, { useState } from "react";

// Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Contexts
import LanguageContext from "./Contexts/languageContext";

// Layouts
import MainLayout from "./Layouts/MainLayout/MainLayout";
// import AuthLayout from './Layouts/AuthLayout/AuthLayout';

// General Views
import Nft from "./Views/Nft/Ntf";
// import Faq from "./Views/Faq/Faq";
import Swap from "./Views/Swap/Swap";
import Wallet from "./Views/Wallet/Wallet";
import SendAw from "./Views/SendAw/SendAw";
import Social from "./Views/Social/Social";
import Profile from "./Views/Profile/Profile";
import SendXpl from "./Views/SendXpl/SendXpl";
import ShopBuy from "./Views/ShopBuy/ShopBuy";
import Staking from "./Views/Staking/Staking";
import ShopList from "./Views/ShopList/ShopList";
import SendPusd from "./Views/SendPusd/SendPusd";
// import Exchange from "./Views/Exchange/Exchange";
import Dashboard from "./Views/Dashboard/Dashboard";
import Metaverse from "./Views/Metaverse/Metaverse";
import Logout from "./Views/ServicePages/Logout/Logout";
import Transaction from "./Views/Transaction/Transaction";
import Page404 from "./Views/ServicePages/Page404/Page404";
import PrivacyPolicy from "./Views/PrivacyPolicy/PrivacyPolicy";
import RiskDisclaimer from "./Views/RiskDisclaimer/RiskDisclaimer";
import DepositHistory from "./Views/DepositHistory/DepositHistory";
import Maintenance from "./Views/ServicePages/Maintenance/Maintenance";
import TermAndConditions from "./Views/TermAndConditions/TermAndConditions";
import LoginTokenExchange from "./Views/LoginTokenExchange/LoginTokenExchange";
import BlockedUser from "./Views/BlockedUser/BlockedUser";

import AuthLayout from "./Layouts/AuthLayout/AuthLayout";
import Login from "./Views/Auth/Login/Login";
import ForgotPassword from "./Views/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./Views/Auth/ResetPassword/ResetPassword";
import TwoFactor from "./Views/Auth/TwoFactor/TwoFactor";

// Styles
import "./Assets/css/index.css";

const App = () => {
  const lng =
    localStorage.getItem("lng") === undefined ||
    localStorage.getItem("lng") === null ||
    !["EN", "ES", "FR"].includes(localStorage.getItem("lng"))
      ? "EN"
      : localStorage.getItem("lng");

  const [language, setLanguage] = useState(lng);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      <BrowserRouter>
        <Routes>
          {/* Service Pages */}
          <Route path="*" element={<Page404 />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="auth/logout" element={<Logout />} />

          <Route path="/auth" element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="2fa" element={<TwoFactor />} />
          </Route>

          {/* Routes */}
          <Route path="/" element={<MainLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="asset/wallet" element={<Wallet />} />
            <Route path="asset/wallet/transaction" element={<Transaction />} />
            <Route path="asset/wallet/withdraw-xpl" element={<SendXpl />} />
            <Route path="asset/wallet/send-pusd" element={<SendPusd />} />
            <Route path="asset/wallet/send-ausd" element={<SendAw />} />
            {/* <Route path="asset/exchange" element={<Exchange />} />  */}
            <Route path="asset/swap" element={<Swap />} />
            <Route path="asset/deposit-history" element={<DepositHistory />} />
            <Route path="my-shop" element={<ShopList />} />
            <Route path="my-shop/buy" element={<ShopBuy />} />
            <Route path="my-rewards" element={<Staking />} />
            <Route path="metaverse" element={<Metaverse />} />
            <Route path="nft" element={<Nft />} />
            <Route
              path="legal/terms-and-condition"
              element={<TermAndConditions />}
            />
            <Route path="legal/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="legal/risk-disclaimer" element={<RiskDisclaimer />} />
            {/* <Route path="legal/faq" element={<Faq />} />  */}
            <Route path="social-media" element={<Social />} />
          </Route>
          <Route path="login-token-exchange" element={<LoginTokenExchange />} />
          <Route path="blocked" element={<BlockedUser />} />
        </Routes>
      </BrowserRouter>
    </LanguageContext.Provider>
  );
};

export default App;
