import React, { useEffect, useState } from "react";

// Components
import LoaderGlobal from "../../../Components/Commons/LoaderGlobal/LoaderGlobal";
import GeneralPopup from "../../../Components/Commons/GeneralPopup/GeneralPopup";

//Libraires
import { Button, InputField } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { Link, useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth";

//Images
import Logo from "../../../Assets/images/logos/logo-pulse-full.png";

//Styles
import Style from "./Login.module.css";

const Login = () => {
  const navigate = useNavigate();
  const [selfLoginDisabled, setSelfLoginDisabled] = useState(false);

  useEffect(() => {
    document.title = "PULSE WORLD | Login";
  }, []);

  const form = useForm({
    inputs: {
      username: {
        required: true,
      },
      password: {
        nature: "password",
        required: true,
        validator: null,
      },
    },
  });

  const loginApi = useQuery({
    url: "auth/self-login/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      localStorage.setItem("loginToken", response.data.loginToken);
      localStorage.setItem("otpMethod", response.data.otpMethod);
      navigate("/auth/2fa");
    },
    onError: (error) => {
      localStorage.removeItem("token");
      console.log(error);
      if (error.response.data.code === "MAINTENANCE_MODE") {
        navigate("/maintenance");
      } else if (error.response.data.code === "FEATURE_DISABLED") {
        setSelfLoginDisabled(true);
      }
    },
  });

  const features = useQuery({
    url: "configuration/features/",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      console.log(response);
      if (response.data.loginEnabled === false) {
        navigate("/maintenance");
      }
    },
  });

  return (
    <AuthRoute
      loader={<LoaderGlobal />}
      minimumLoadingTime={1000}
      forLoggedUser={false}
      apiLoading={features.isLoading}
      action={() => {
        navigate("/");
      }}
    >
      {selfLoginDisabled && (
        <GeneralPopup className={Style.loginDisabled}>
          <div>Login with password is disabled</div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            className={Style.button}
          >
            Try another type of login
          </Button>
        </GeneralPopup>
      )}

      <section className={Style.login}>
        <div className={Style.inner}>
          <img src={Logo} alt="Logo" />

          {features.response?.data?.selfLoginEnabled === true && (
            <>
              <div className={Style.input}>
                <InputField
                  label={"Username"}
                  className={Style.inputUsername}
                  type={"text"}
                  placeholder={"Username"}
                  required={true}
                  showError={false}
                  {...form.getInputProps("username")}
                />
              </div>

              <div className={Style.input}>
                <InputField
                  label={"Password"}
                  className={Style.inputPassword}
                  type={"password"}
                  placeholder={"Password"}
                  required={true}
                  showError={false}
                  {...form.getInputProps("password")}
                />
              </div>
              <div className={Style.error}>
                {loginApi.isError &&
                  (loginApi?.error?.response?.data?.message ||
                    "Something went wrong")}
              </div>
              <Button
                className={Style.buttonLogin}
                isLoading={loginApi.isLoading}
                disabled={!form.isValid()}
                onClick={() => {
                  loginApi.executeQuery(form.getApiBody());
                }}
              >
                Sign in
              </Button>

              <div className={Style.forgot}>
                <Link to="/auth/forgot-password">Forgot password?</Link>
              </div>
            </>
          )}
        </div>
      </section>
    </AuthRoute>
  );
};

export default Login;
