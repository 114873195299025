import React from 'react'
// Libraires
import { Button } from '@hybris-software/ui-kit';
// Icons
import { MdVerified } from "react-icons/md";

// Hooks
import useText from "../../../../Hooks/useText";

//Styles
import Style from "./DepositConfirmation.module.css";

const DepositConfirmation = ({ setDepositConfirm }) => {
    const texts = useText("wallet");
    const { message, button } = texts.depositConfirmation;

    return (
        <section className={Style.depositConfirmation}>
            <div className={Style.iconsVerified}>
                <MdVerified />
            </div>
            <div className={Style.message}>
                {message}
            </div>
            <Button
                onClick={() => setDepositConfirm(false)}
            >
                {button}
            </Button>
        </section>
    )
}

export default DepositConfirmation