import React from 'react';

// Images
import Logo from '../../../../Assets/images/logos/logo-bg-dark.png';

// Styles
import Style from './SimpleSeedLabel.module.css';

const SimpleSeedLabel = ({ title, roundName, tokenPrice }) => {

  return (
    <section>
      <div className={Style.seedBox}>
        <div className={Style.text}>
          <div className={Style.infoSeed}>
            <div className={Style.infoText}>
              <div className={Style.textLabel}>
                <div>
                  <img src={Logo} alt='' />
                </div>
                <div>
                  {roundName && <h2 className={Style.title}>{roundName}</h2>}
                  {title && tokenPrice && (
                    <p className={Style.subTitle}>
                      {title} ${tokenPrice.toFixed(4)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleSeedLabel;
