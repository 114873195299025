import React from "react";

// Libraries
import { useTable } from "react-table";
import styled from "styled-components";
import { Button } from "@hybris-software/ui-kit";

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./GeneralTable.module.css";

const CommonStyles = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  table {
    border-radius: 10px 10px 0px 0px;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    thead {
      th {
        text-align: left;
        border-bottom: 1px solid #292929;
        border-top: 1px solid #292929;
        height: 55px;
        div {
          text-transform: capitalize;
          width: 100%;
          padding: 10px;
          color: var(--text-subtitle);
        }
      }
    }
    tbody {
      tr {
        height: 65px;
        td {
          border-bottom: 1px solid #292929;
          padding-block: 15px;
          padding-left: 10px;
          color: var(--dark);
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
`;

const Table = ({ data, columns, height, Styles, isLoading, setUrl, title, subtitle }) => {
  const texts = useText("generalTable");
  const { showing, to, of, entries, prev, next } = texts;

  const ComputedStyles = Styles ? Styles : CommonStyles;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data.results,
    });

  return (
    <ComputedStyles>
      <div className={Style.tableContainer}>
        {title && <h5>{title}</h5>}
        {subtitle && <p style={{ color: "white", fontSize: 15, marginBottom: 20, textDecoration: "underline" }}>{subtitle}</p>}
        <div style={{ height: height, position: "relative" }}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div className={Style.clampedText}>
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <div className={Style.tableLoader}></div>
            ) : (
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            <div className={Style.clampedText}>
                              {cell.render("Cell")}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className={Style.pagination}>
          <div className={Style.entries}>
            {showing + data.from + to + data.to + of + data.count + entries}
          </div>
          <div className={Style.paginationButtons}>
            <Button
              disabled={data.links.prev === null || isLoading}
              className={Style.button}
              style={{
                cursor: data.links.previous ? "pointer" : "not-allowed",
                opacity: data.links.previous ? 1 : 0.5,
              }}
              onClick={() => {
                if (data?.links.previous !== null) {
                  setUrl(data.links.previous.replace("http://", "https://"));
                }
              }}
            >
              {prev}
            </Button>
            <Button
              disabled={data.links.next === null || isLoading}
              className={Style.button}
              style={{
                cursor: data.links.next ? "pointer" : "not-allowed",
                opacity: data.links.next ? 1 : 0.5,
              }}
              onClick={() => {
                if (data?.links.next !== null) {
                  setUrl(data.links.next.replace("http://", "https://"));
                }
              }}
            >
              {next}
            </Button>
          </div>
        </div>
      </div>
    </ComputedStyles>
  );
};

export default Table;
