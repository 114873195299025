import React, { useEffect } from 'react'

// Components
import ComingSoon from '../ComingSoon/ComingSoon'

// Libraries
import { Container } from '@hybris-software/ui-kit'

// Images 
import IconOne from '../../Assets/images/icons/branded.svg';
import IconTwo from '../../Assets/images/icons/media.svg';
import IconThree from '../../Assets/images/icons/message.svg';
import PlaceHolderVideo from '../../Assets/images/social.png';

// video
import SocialVideo from '../../Assets/videos/social.mp4';

// Hooks
import useText from '../../Hooks/useText'


const Social = () => {

    const elementsComingSoon = {
        iconOne: IconOne,
        iconTwo: IconTwo,
        iconThree: IconThree,
        image: PlaceHolderVideo,
        texts: useText('socialComing'),
        video: SocialVideo,
        page: useText('socialComing').page
    }

    useEffect(() => {
        document.title = 'PULSE WORLD | ' + elementsComingSoon.page
    }, [elementsComingSoon.page])

    return (
        <Container>
            <ComingSoon {...elementsComingSoon} />
        </Container>
    )
}

export default Social