import React, { useState } from "react";

// Components
import GeneralPopup from "./../../../../Components/Commons/GeneralPopup/GeneralPopup";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Images
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";
import ErrorImage from "../../../../Assets/images/error-transaction.png";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./ConfirmSwap.module.css";
import Completed from "../../../Transaction/Components/Completed/Completed";

const ConfirmSwap = ({ setSwapStep, data, fees }) => {
  // Error Popup
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [errorType, setErrorType] = useState("");

  const texts = useText("swap");
  const {
    preTitle,
    postTitle,
    receiveText,
    fromText,
    buttonReject,
    buttonAccept,
  } = texts.confirmSwap;

  const {
    titleSuccess,
    subTitleSuccess,
    buttonSuccess,
    insufficientBalance,
    error,
    buttonClose,
  } = texts.popup;

  const swapApi = useQuery({
    url: "wallets/swap/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (data) => {
      setShowSuccessPopup(true);
    },
    onError: (error) => {
      if (error.response.data.code === "INSUFFICIENT_BALANCE") {
        setShowErrorPopup(true);
        setErrorType("INSUFFICIENT_BALANCE");
      }
    },
  });

  return (
    <section className={Style.confirmSwap}>
      {showSuccessPopup && (
        <GeneralPopup className={Style.successPopup}>
          <Completed title={titleSuccess} subTitle={subTitleSuccess} />
          <Button
            onClick={() => {
              setSwapStep(1);
              setShowSuccessPopup(false);
            }}
          >
            {buttonSuccess}
          </Button>
        </GeneralPopup>
      )}

      {showErrorPopup && (
        <GeneralPopup className={Style.errorPopup}>
          <img src={ErrorImage} alt="" width={"100%"} />
          {errorType === "INSUFFICIENT_BALANCE" ? (
            <p>{insufficientBalance}</p>
          ) : (
            <p>{error}</p>
          )}
          <Button
            className={Style.buttonError}
            onClick={() => {
              setShowErrorPopup(false);
              setErrorType("");
            }}
          >
            {buttonClose}
          </Button>
        </GeneralPopup>
      )}

      {preTitle && (
        <h4 className={Style.title}>
          {preTitle}
          <span> XPL </span>
          {postTitle}
          <span> PUSD </span>
        </h4>
      )}

      <div className={Style.received}>
        {receiveText}
        <div className={Style.amount}>
          {(data.pusd * (1 - fees.swapFee.value / 100)).toFixed(2)} PUSD
          <img src={PusdCoin} alt="" />
        </div>
        {fromText}
        {/* Devo metterci la feee TO DOOOO */}
        <div>{data.pusd} PUSD</div>
      </div>

      <div className={Style.buttons}>
        <Button
          className={Style.button}
          onClick={() => {
            setSwapStep(1);
          }}
        >
          {buttonReject}
        </Button>
        <Button
          className={Style.button}
          isLoading={swapApi.isLoading}
          onClick={() => {
            swapApi.executeQuery({
              amount: data.xpl,
            });
          }}
        >
          {buttonAccept}
        </Button>
      </div>
    </section>
  );
};

export default ConfirmSwap;
