import React, { useEffect } from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate } from "react-router-dom";

// Components
import LoaderGlobal from "../../../Components/Commons/LoaderGlobal/LoaderGlobal";

// Images
import LogoPulseFull from "../../../Assets/images/logos/logo-pulse-full.png";
import LogoutImage from "../../../Assets/images/logout.png";

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./Logout.module.css";

const Logout = () => {
  const texts = useText("logout");
  const { title, button, page } = texts;

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
    localStorage.removeItem("token");
  }, [page]);

  return (
    <AuthRoute
      loader={<LoaderGlobal />}
      minimumLoadingTime={2000}
      forLoggedUser={false}
      action={() => {
        navigate("/");
      }}
    >
      <section className={Style.page}>
        <img
          src={LogoPulseFull}
          alt=""
          style={{ cursor: "pointer" }}
          className={Style.logo}
          onClick={() => {
            navigate("/auth/login");
          }}
        />
        <div className={Style.center}>
          <img src={LogoutImage} alt="" />
          <div className={Style.text}>
            <h1>{title}</h1>
            <Button
              className={Style.button}
              onClick={() => {
                navigate("/auth/login");
              }}
            >
              {button}
            </Button>
          </div>
        </div>
      </section>
    </AuthRoute>
  );
};

export default Logout;
