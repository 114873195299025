import React, { useEffect } from 'react'

// Components
import ComingSoon from '../ComingSoon/ComingSoon'

// Libraries
import { Container } from '@hybris-software/ui-kit'

// Images 
import MetaverseImage from '../../Assets/images/icons/metaverse.svg';
import Brands from '../../Assets/images/icons/brands.svg';
import Concerts from '../../Assets/images/icons/concert.svg';
import PlaceHolderVideo from '../../Assets/images/placeholder-video-metaverse.png';

// video
import MetaverseVideo from '../../Assets/videos/metaverse.mp4';

// Hooks
import useText from '../../Hooks/useText'


const Metaverse = () => {

    const elementsComingSoon = {
        iconOne: MetaverseImage,
        iconTwo: Concerts,
        iconThree: Brands,
        image: PlaceHolderVideo,
        texts: useText('metaverseComing'),
        video: MetaverseVideo,
        page: useText('metaverseComing').page
    }


    useEffect(() => {
        document.title = 'PULSE WORLD | ' + elementsComingSoon.page
    }, [elementsComingSoon.page])



    return (
        <Container>
            <ComingSoon {...elementsComingSoon} />
        </Container>
    )
}

export default Metaverse