import React, { useState, useEffect } from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { AuthRoute } from "@hybris-software/use-auth";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import CoinsAction from "./Components/CoinsAction/CoinsAction";
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";
import Chart from "./Components/Chart/Chart";
import Loader from "../../Components/Commons/Loader/Loader";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import SelectToken from "../../Components/Commons/SelectToken/SelectToken";
import DepositConfirmation from "./Components/DepositConfirmation/DepositConfirmation";
// import SelectTokenFee from "../../Components/Commons/SelectTokenFee/SelectTokenFee";

// Hooks
import useText from "../../Hooks/useText";

// Utils
import chooseIcon from "../../Utils/chooseIcon";

// Styles
// import Style from "./Wallet.module.css";

const Wallet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const texts = useText("wallet");
  const types = useText("typeTable");
  const { head, assetCoin, popUp, table, page } = texts;
  const {
    sendw,
    sendwAccepted,
    sendwRefused,
    buyFrom,
    buyFor,
    buy,
    swap,
    deposit,
    send,
  } = types;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const columns = [
    {
      Header: table.labelOne,
      accessor: (row) => {
        const string = `#${row.transactionId}`;
        return chooseIcon(row.type, string);
      },
    },
    {
      Header: table.labelTwo,
      accessor: "timestamp",
    },
    {
      Header: table.labelThree,
      accessor: "amount",
    },
    {
      Header: table.labelFour,
      accessor: (row) => {
        if (row.type === "send") {
          return send;
        }
        if (row.type === "sendw") {
          return sendw;
        }
        if (row.type === "sendw_accepted") {
          return sendwAccepted;
        }
        if (row.type === "sendw_refused") {
          return sendwRefused;
        }
        if (row.type === "buy_from") {
          const title = `${buyFrom} ${row.otherUser}`;
          return <span title={title}>{title}</span>;
        }
        if (row.type === "buy_to") {
          const title = `${buyFor} ${row.otherUser}`;
          return <span title={title}>{title}</span>;
        }
        if (row.type === "buy") {
          return buy;
        }
        if (row.type === "swap") {
          return swap;
        }
        if (row.type === "deposit") {
          return deposit;
        }
      },
    },
  ];

  //+ Parameters URL Get 
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const depositCode = urlParams.get("transaction");
  const transId = urlParams.get("transID");

  /* POPUP */
  const [isOpen, setIsOpen] = useState(location.state?.popup || false);
  // const [isOpenFee, setIsOpenFee] = useState(false);
  const [depositTokenData, setDepositTokenData] = useState(null);
  const [depositConfirm, setDepositConfirm] = useState(depositCode === "made" ? true : false);

  //   Table
  const [tableData, setTableData] = useState(null);
  const [url, setUrl] = useState("wallets/history/?page=1&limit=10");

  const walletsApi = useQuery({
    url: "wallets/balance/",
    method: "GET",
    executeImmediately: true,
  });

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setTableData(response.data);
    },
  });

  const sendNotificationDeposit = useQuery({
    url: "notifications/send/deposit-pending/",
    method: "POST",
    executeImmediately: false,
  })


  const lineChartApi = useQuery({
    url: "wallets/balance-history/",
    method: "GET",
    executeImmediately: true,
  });

  const feesApi = useQuery({
    url: "configuration/fees/",
    method: "GET",
    executeImmediately: true,
  });

  useEffect(() => {
    tableApi.executeQuery();
    if (transId) {
      sendNotificationDeposit.executeQuery({
        transactionId: transId,
      })
    }
    // eslint-disable-next-line 
  }, [url, transId]);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={
        lineChartApi.isLoading || feesApi.isLoading || walletsApi.isLoading
      }
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        {isOpen && (
          <GeneralPopup>
            {feesApi.response && (
              <SelectToken
                {...popUp}
                setIsOpen={setIsOpen}
                depositTokenData={depositTokenData}
                setDepositTokenData={setDepositTokenData}
                fees={feesApi.response.data}
              />
            )}
          </GeneralPopup>
        )}

        {depositConfirm && (
          <GeneralPopup>
            <DepositConfirmation
              setDepositConfirm={setDepositConfirm}
            />
          </GeneralPopup>
        )}

        {/* {isOpenFee && (
          <GeneralPopup>
            <SelectTokenFee
              {...popUp}
              setIsOpenFee={setIsOpenFee}
              depositTokenData={depositTokenData}
              setDepositTokenData={setDepositTokenData}
            />
          </GeneralPopup>
        )} */}

        <Heading {...head} />
        {walletsApi.response && (
          <CoinsAction
            {...assetCoin}
            setIsOpen={setIsOpen}
            // setIsOpenFee={setIsOpenFee}
            data={walletsApi.response.data}
          />
        )}
        {lineChartApi.response && walletsApi.response && (
          <Chart
            walletInfo={walletsApi.response.data}
            data={lineChartApi.response.data}
          />
        )}
        {tableData && (
          <GeneralTable
            height="730px"
            title={table.title}
            data={tableData}
            columns={columns}
            setTableData={setTableData}
            setUrl={setUrl}
            isLoading={tableApi.isLoading}
          />
        )}
      </Container>
    </AuthRoute>
  );
};

export default Wallet;
