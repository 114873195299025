import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Styles
import Style from "./ConfirmationBuy.module.css";

const ConfirmationBuy = ({ title, subTitle, button }) => {
  const navigate = useNavigate();

  return (
    <section className={Style.confirm}>
      <div className={Style.header}>
        <h3>{title}</h3>
        <h6>{subTitle}</h6>
      </div>

      <Button
        className={Style.button}
        onClick={() => {
          navigate("/my-rewards");
        }}
      >
        {button}
      </Button>
    </section>
  );
};

export default ConfirmationBuy;
