import React from "react";

// Components
import NoStakingStats from "../NoStakingStats/NoStakingStats";
import YesStakingStats from "../YesStakingStats/YesStakingStats";

// Styles
import Style from "./StakingStats.module.css";

const StakingStats = ({
  stakingExist,
  noStakingStats,
  yesStakingStats,
  setShowPopup,
  data,
}) => {
  return (
    <section className={Style.stakingStats}>
      {!stakingExist && <NoStakingStats {...noStakingStats} />}
      {stakingExist && (
        <YesStakingStats
          {...yesStakingStats}
          data={data}
        />
      )}
    </section>
  );
};

export default StakingStats;
