import React from "react";

// Components
import Balance from "../Balance/Balance";
import Currency from "../Currency/Currency";
import LatestActivities from "../LatesActivitis/LatestActivities";
import Chart from "../Chart/Chart";

// Styles
import Style from "./GridLayoutDashboard.module.css";

const GridLayoutDashboard = ({ balance, donut, notifications, lineChart, tokenPrice }) => {
  return (
    <section className={Style.layout}>

      <div className={Style.balance}>
        <Balance data={balance} tokenPrice={tokenPrice} />
      </div>
      <div className={Style.statistic}>
        <Chart data={lineChart} chartHeight="300px" />
      </div>

      <div className={Style.currency}>
        <Currency data={donut} tokenPrice={tokenPrice} />
      </div>

      <div className={Style.activity}>
        <LatestActivities data={notifications} />
      </div>
    </section>
  );
};

export default GridLayoutDashboard;
