import React from "react";

// Libraries
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./Currency.module.css";

ChartJS.register(ArcElement, Tooltip);

const Currency = ({ data, tokenPrice }) => {

  const texts = useText("dashboard");
  const { title, labelOne, ofWhat } = texts.currency;

  const xplBalanceUsd = data.xplBalance * tokenPrice;

  const dataChart = {
    labels: ["XPL", "AUSD", "PUSD"],
    datasets: [
      {
        label: "# of Votes",
        data: [xplBalanceUsd, data.pWalletBalance, data.pusdBalance],
        backgroundColor: ["#FFCE34", "#7314ed", "#2e00af"],
        spacing: 15,
        borderWidth: 0,
        borderRadius: 10,
        cutout: "70%",
        radius: "90%",
        hoverOffset: 10,
      },
    ],
  };

  return (
    <aside className={Style.currency}>
      <h6 className={Style.title}>{title}</h6>
      <div
        style={{
          height: "300px",
          width: "300px",
          position: "relative",
        }}
      >
        <div className={Style.innerChartText}>
          <h6>{labelOne}</h6>
          <h5>
            {(
              (xplBalanceUsd /
                (xplBalanceUsd + data.pWalletBalance + data.pusdBalance)) *
              100 || 0
            ).toFixed(2)}
            % {ofWhat}
          </h5>
          <h6>$ {xplBalanceUsd.toFixed(4)}</h6>
        </div>
        <div style={{ margin: "auto" }}>
          <Doughnut data={dataChart} />
        </div>
      </div>

      <div className={Style.labels}>
        <div className={Style.graphBox}>
          <div className={Style.circle} style={{ backgroundColor: "#b870f9" }}></div>
          XPL
        </div>
        <div className={Style.graphBox}>
          <div className={Style.circle} style={{ backgroundColor: "#7314ed" }}></div>
          AUSD
        </div>
        <div className={Style.graphBox}>
          <div className={Style.circle} style={{ backgroundColor: "#2e00af" }}></div>
          PUSD
        </div>
      </div>
    </aside>
  );
};

export default Currency;
