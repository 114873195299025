import React, { useEffect } from 'react'

// Componets
import Heading from '../../Components/Commons/Heading/Heading'

// Libraires
import { Container } from '@hybris-software/ui-kit'

// Hooks
import useText from '../../Hooks/useText'
import TermBox from './Components/TermBox/TermBox'

const TermAndConditions = () => {

    const texts = useText('terms');
    const { head, page } = texts;

    useEffect(() => {
        document.title = 'PULSE WORLD | ' + page
    }, [page])


    return (
        <Container>
            <Heading {...head} />
            <TermBox />
        </Container>
    )
}

export default TermAndConditions