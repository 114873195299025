import React from "react";

//  Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Images
import XplIcon from "../../../../Assets/images/icons/xpl-coin.png";

// Styles
import Style from "./YesStakingStats.module.css";

const YesStakingStats = ({
  totalLabel,
  freeLabel,
  lockedLabel,
  buttonBuy,
  data,
}) => {
  const navigate = useNavigate();

  return (
    <section>
      <div className={Style.advertiser}>
        <div className={Style.leftSide}>
          <div className={Style.coin}>
            <img src={XplIcon} alt="icon" />
            XPL
          </div>
          <div className={Style.labelStaking}>
            {totalLabel}
            <div className={Style.number}>{data.poolTotalXpl} XPL</div>
          </div>
        </div>

        <div className={Style.rightSide}>
          <div className={Style.free}>
            <div className={Style.labelStaking}>
              {/* {freeLabel}
              <div className={Style.number}>{data.poolFreeXpl} XPL</div> */}
            </div>
            <div className={Style.buttons}>
              <Button
                className={Style.button}
                onClick={() => {
                  navigate("/my-shop");
                }}
              >
                {buttonBuy}
              </Button>
            </div>
          </div>
          <div className={Style.lock} style={{ minHeight: 85 }}>
            <div className={Style.labelStaking}>
              {/* {lockedLabel}
              <div className={Style.number}>{data.poolLockedXpl} XPL</div> */}
            </div>
          </div>
        </div>

        <div className={Style.buttonsMobile}>
          <Button
            className={Style.button}
            onClick={() => {
              navigate("/my-shop");
            }}
          >
            {buttonBuy}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default YesStakingStats;
