import React, { useEffect } from "react";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import StakingStats from "./Components/StakingStats/StakingStats";
import StakingPackages from "./Components/StakingPackages/StakingPackages";
import Loader from "../../Components/Commons/Loader/Loader";
// import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";

// Libraries
import { Container/* , Button  */ } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

//Images
// import XplCoin from "../../Assets/images/icons/xpl-coin.png";

// Icons
// import { AiOutlineHistory } from "react-icons/ai";

// Hooks
import useText from "../../Hooks/useText";

// Styles
import Style from "./Staking.module.css";


const Staking = () => {
  const navigate = useNavigate();
  const texts = useText("staking");
  const {
    head,
    noStakingStats,
    yesStakingStats,
    stakingPackages,
    // historyButton,
    page
  } = texts;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const stakingBalanceApi = useQuery({
    url: "xpl/staking-balance/",
    method: "GET",
    executeImmediately: true,
  });

  const stakingPackagesApi = useQuery({
    url: "xpl/bought/",
    method: "GET",
    executeImmediately: true,
  });

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={stakingBalanceApi.isLoading && stakingPackagesApi.isLoading}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container className={Style.container}>
        {/* <Button
          className={Style.button}
        >
          <AiOutlineHistory />
          {historyButton}
        </Button> */}
        <Heading {...head} />
        {stakingBalanceApi.response && stakingPackagesApi.response && (
          <StakingStats
            yesStakingStats={yesStakingStats}
            noStakingStats={noStakingStats}
            stakingExist={
              stakingPackagesApi.response.data.length > 0 ? true : false
            }
            data={stakingBalanceApi.response.data}
          />
        )}

        {stakingPackagesApi.response && (
          <StakingPackages
            stakingBalanceApi={stakingBalanceApi}
            stakingPackagesApi={stakingPackagesApi}
            {...stakingPackages}
            stakingExist={
              stakingPackagesApi.response.data.length > 0 ? true : false
            }
            data={stakingPackagesApi.response.data}
          />
        )}
      </Container>
    </AuthRoute>
  );
};

export default Staking;
