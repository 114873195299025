import React, { useState } from "react";

// Components
import { Link, useLocation } from "react-router-dom";

// Assets
import LogoDark from "../../../Assets/images/logos/logo-bg-dark.png";
import LogoDarkfull from "../../../Assets/images/logos/logo-pulse-full.png";
import { FiChevronDown } from "react-icons/fi";
import { HiLockClosed } from "react-icons/hi";
import { MdDashboardCustomize } from "react-icons/md";
import { RiHome5Fill } from "react-icons/ri";
import { MdShoppingBasket } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
import { BsShopWindow } from "react-icons/bs";
import { IoIosPaper } from "react-icons/io";
import { IoShareSocialSharp } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";

// Hooks
import useMediaQuery from "../../../Hooks/useMediaQuery";
import useText from "../../../Hooks/useText";

// Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./Sidebar.module.css";


const sidebarClass = (sidebarIsOpen, over, isDesktop) => {
  if (isDesktop) {
    // Desktop
    if (sidebarIsOpen) {
      return Style.sidebar;
    } else {
      if (over === true) return Style.sidebar;
      else return Style.sidebarCollapse;
    }
  } else {
    // Mobile
    if (sidebarIsOpen) {
      return Style.sidebarMobile;
    } else {
      return Style.sidebarCollapseMobile;
    }
  }
};

const dropdownLabelClass = (activeFor, location) => {
  if (activeFor.includes(location.pathname)) return Style.elementActive;
  else return Style.element;
};

const linkLabelClass = (isOpen) => {
  if (isOpen) return Style.mainCategoryOpenCollapsed;
  else return Style.mainCategory;
};

const Sidebar = ({ theme, sidebarIsOpen, closeSidebar, setSidebarIsOpen }) => {

  const texts = useText("sidebar");

  const sidebarConstructor = [
    {
      title: texts.dashboard,
      icon: <RiHome5Fill className={Style.icon} />,
      link: "/",
      activeFor: ["/"],
    },
    {
      title: texts.myAsset,
      icon: <MdDashboardCustomize className={Style.icon} />,
      link: "/asset",
      activeFor: ["/asset/wallet", "/asset/exchange", "/asset/swap", "/assets/deposit-history"],
      subItems: [
        {
          title: texts.wallet,
          link: "/asset/wallet",
          activeFor: ["/asset/wallet"],
        },
        {
          title: texts.swap,
          link: "/asset/swap",
          activeFor: ["/asset/swap"],
        },
        {
          title: texts.depositHistory,
          link: "/asset/deposit-history",
          activeFor: ["/asset/deposit-history"],
        },
        // {
        //   title: texts.exchange,
        //   link: "/asset/exchange",
        //   activeFor: ["/asset/exchange"],
        // },
      ],
    },
    {
      title: texts.myShop,
      icon: <MdShoppingBasket className={Style.icon} />,
      link: "/my-shop",
      activeFor: ["/my-shop"],
    },
    {
      title: texts.myStaking,
      icon: <HiLockClosed className={Style.icon} />,
      link: "/my-rewards",
      activeFor: ["/my-rewards"],
    },
    {
      title: texts.metaverse,
      icon: <FaGlobe className={Style.icon} />,
      link: "/metaverse",
      activeFor: ["/metaverse"],
    },
    {
      title: texts.nft,
      icon: <BsShopWindow className={Style.icon} />,
      link: "/nft",
      activeFor: ["/nft"],
    },
    {
      title: texts.social,
      icon: <IoShareSocialSharp className={Style.icon} />,
      link: "/social-media",
      activeFor: ["/social-media"],
    },
    {
      title: texts.legal,
      icon: <IoIosPaper className={Style.icon} />,
      link: "/legal",
      activeFor: [
        "/legal/term-and-condition",
        "/legal/privacy-policy",
        "/legal/faq",
        "/legal/risk-disclaimer"
      ],
      subItems: [
        {
          title: texts.term,
          link: "/legal/terms-and-condition",
          activeFor: ["/legal/terms-and-condition"],
        },
        {
          title: texts.privacy,
          link: "/legal/privacy-policy",
          activeFor: ["/legal/privacy-policy"],
        },
        {
          title: texts.disclaimer,
          link: "/legal/risk-disclaimer",
          activeFor: ["/legal/risk-disclaimer"],
        },
        // {
        //   title: texts.faq,
        //   link: "/legal/faq",
        //   activeFor: ["/legal/faq"],
        // },
      ],
    },
    {
      title: texts.support,
      icon: <MdOutlineSupportAgent className={Style.icon} />,
      link: "https://support.pulseworld.com/",
      externalLink: true,
      activeFor: [""],
    },
  ];

  const [over, setOver] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState(
    sidebarConstructor.map(() => false)
  );
  const isDesktop = useMediaQuery("(min-width: 991px)");

  const setDropdownOpen = (index, open) => {
    setOpenDropdowns((oldOpenDropdowns) => {
      const newOpenDropdowns = [...oldOpenDropdowns];
      newOpenDropdowns[index] = open;
      return newOpenDropdowns;
    });
  };

  return (
    <div
      className={sidebarClass(sidebarIsOpen, over, isDesktop)}
      onMouseOver={() => {
        if (sidebarIsOpen === false) {
          setOver(true);
          setSidebarIsOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (over) {
          setOver(false);
          setSidebarIsOpen(false);
        }
      }}
    >
      {/* TOP Sidebar */}
      <div className={Style.boxLogo}>
        {isDesktop && (sidebarIsOpen || over) ? (
          <Link to="/">
            <img src={theme === "dark" ? LogoDarkfull : LogoDarkfull} alt="Logo" />
          </Link>
        ) : !isDesktop && (sidebarIsOpen || !sidebarIsOpen) ? (
          <Link to="/">
            <img src={theme === "dark" ? LogoDarkfull : LogoDarkfull} alt="Logo" />
          </Link>
        ) : (
          <Link to="/">
            <img src={LogoDark} alt="" />
          </Link>
        )}
      </div>

      <div className={Style.boxNav}>
        {/* CENTER Sidebar */}
        <div className={Style.navigator}>
          {/* Menu List */}
          <nav>
            <ul>
              {sidebarConstructor.map((el, i) => {
                if (el.subItems)
                  return (
                    <NavMenu
                      closeSidebar={closeSidebar}
                      key={i}
                      {...el}
                      isOpen={(sidebarIsOpen || over) && openDropdowns[i]}
                      setOpen={(open) => setDropdownOpen(i, open)}
                    />
                  );
                else
                  return (
                    <NavSimple closeSidebar={closeSidebar} key={i} {...el} />
                  );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

const NavSimple = ({ title, icon, link, externalLink, activeFor, closeSidebar }) => {
  const location = useLocation();

  return (
    <li className={dropdownLabelClass(activeFor, location)}>
      {externalLink ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <LabelLinkSimple
            closeSidebar={closeSidebar}
            icon={icon}
            title={title}
          />
        </a>) : (
        <Link to={link}>
          <LabelLinkSimple
            closeSidebar={closeSidebar}
            icon={icon}
            title={title}
          />
        </Link>)}
    </li >
  );
};

const LabelLinkSimple = ({ icon, title, closeSidebar }) => {
  return (
    <div onClick={() => closeSidebar()} className={Style.mainCategory}>
      <div className={Style.innerCategory}>
        <div className={Style.boxIcon}>{icon}</div>
        <span className={Style.categoryText}>{title}</span>
      </div>
    </div>
  );
};

const NavMenu = ({
  activeFor,
  subItems,
  icon,
  title,
  isOpen,
  setOpen,
  closeSidebar,
}) => {
  const location = useLocation();

  return (
    <li className={dropdownLabelClass(activeFor, location)}>
      <LabelLink
        icon={icon}
        subItems={subItems}
        title={title}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <Dropdown
        closeSidebar={closeSidebar}
        isOpen={isOpen}
        subItems={subItems}
      />
    </li>
  );
};

const LabelLink = ({
  icon,
  subItems,
  title,
  isOpen,
  setOpen,
  closeSidebar,
}) => {
  return (
    <div
      className={linkLabelClass(isOpen)}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      <div className={Style.innerCategory}>
        <div className={Style.boxIcon}>{icon}</div>
        <span className={Style.categoryText}>{title}</span>
      </div>
      {subItems && (
        <FiChevronDown
          className={classNames(Style.arrow, {
            class: Style.arrowActive,
            condition: isOpen,
          })}
        />
      )}
    </div>
  );
};

const Dropdown = ({ isOpen, subItems, closeSidebar }) => {
  return (
    <ul
      style={{ height: isOpen ? `${subItems.length * 47.6}px` : "0px" }}
      className={classNames(Style.closeDropdown, {
        class: Style.active,
        condition: isOpen,
      })}
    >
      {subItems.map((el, i) => {
        return <DropdownElement closeSidebar={closeSidebar} key={i} {...el} />;
      })}
    </ul>
  );
};

const DropdownElement = ({ link, activeFor, title, closeSidebar }) => {
  const location = useLocation();

  return (
    <li>
      <div className={Style.subCategory}>
        <div className={Style.innerSub}>
          <Link to={link} onClick={() => closeSidebar()}>
            <div
              className={
                activeFor && activeFor.includes(location.pathname) === true
                  ? Style.innerElementActive
                  : Style.innerElement
              }
            >
              {title}
            </div>
          </Link>
        </div>
      </div>
    </li>
  );
};

export default Sidebar;
