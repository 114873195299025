import React, { useState, useEffect } from "react";

// Components
import GeneralPopup from "./../../../../Components/Commons/GeneralPopup/GeneralPopup";
import TimerExpired from "../TimerExpired/TimerExpired";

// Libraries
import { Button, InputField } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

//Images
import ErrorImage from "../../../../Assets/images/error-transaction.png";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./FormBuy.module.css";

const FormBuy = ({
  setShowPopup,
  labelOne,
  optionOne,
  optionTwo,
  labelTwo,
  placeholderTwo,
  labelThree,
  net,
  fee,
  button,
  data
}) => {

  const navigate = useNavigate();
  // Form Data
  const [userType, setUserType] = useState("self");
  const [depositCoin, setDepositCoin] = useState("PUSD");
  const [username, setUsername] = useState("");
  // Timer
  const [minutesLeft, setMinutesLeft] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(null);
  const [popupTimer, setPopupTimer] = useState(false)
  // Error Popup
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorType, setErrorType] = useState("");

  // Texts
  const texts = useText("shopBuy");
  const { insufficientBalance, invalidUsername, invalidPackage, error, close } =
    texts.popupError;
  const { title } = texts.depositButton;
  const { have, payment, header } = texts.timerExpired;

  const buyPackageApi = useQuery({
    url: "xpl/buy/confirm/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (data) => {
      setShowPopup(true);
    },
    onError: (error) => {
      if (error.response.data.code === "INSUFFICIENT_BALANCE") {
        setShowErrorPopup(true);
        setErrorType("INSUFFICIENT_BALANCE");
      }
      if (error.response.data.code === "INVALID_USERNAME") {
        setShowErrorPopup(true);
        setErrorType("INVALID_USERNAME");
      }
      if (error.response.data.code === "INVALID_PACKAGE") {
        setShowErrorPopup(true);
        setErrorType("INVALID_PACKAGE");
      }
    },
  });

  useEffect(() => {
    if (data.expiresAt) {
      const interval = setInterval(() => {
        setMinutesLeft(Math.floor(((Date.parse(data.expiresAt) - (new Date()).getTime()) / 1000) / 60));
        setSecondsLeft(Math.floor(((Date.parse(data.expiresAt) - (new Date()).getTime()) / 1000) % 60));
        if ((Date.parse(data.expiresAt) - (new Date()).getTime()) <= 0) {
          clearInterval(interval);
          setPopupTimer(true);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [data.expiresAt]);

  return (
    <section className={Style.buy}>

      {/* {(Date.parse(data.expiresAt) - (new Date()).getTime())} */}

      {popupTimer &&
        <GeneralPopup>
          <TimerExpired />
        </GeneralPopup>
      }

      {showErrorPopup && (
        <GeneralPopup className={Style.errorPopup}>
          <img src={ErrorImage} alt="" width={"100%"} />
          {errorType === "INSUFFICIENT_BALANCE" ? (
            <p>{insufficientBalance}</p>
          ) : errorType === "INVALID_USERNAME" ? (
            <p>{invalidUsername}</p>
          ) : errorType === "INVALID_PACKAGE" ? (
            <p>{invalidPackage}</p>
          ) : (
            <p>{error}</p>
          )}
          <Button
            onClick={() => {
              setShowErrorPopup(false);
              setErrorType("");
            }}
          >
            {close}
          </Button>
        </GeneralPopup>
      )}


      <div className={secondsLeft || minutesLeft ? Style.timerDone : Style.timer}>
        {(secondsLeft || minutesLeft) && secondsLeft >= 0 && minutesLeft >= 0
          ? `${have} ${minutesLeft}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft} ${payment}`
          : header
        }
      </div>
      <div className={Style.inputs}>
        <div className={Style.user}>
          <div className={Style.label}>
            {labelOne} <span className={Style.mandatory}>*</span>
          </div>
          <select
            className={Style.select}
            value={userType}
            onChange={(e) => {
              if (e.target.value === "other") {
                setDepositCoin("PUSD");
              }
              setUserType(e.target.value);
            }}
          >
            <option value="self">{optionOne}</option>
            <option value="other">{optionTwo}</option>
          </select>
        </div>

        {userType === "other" && (
          <div className={Style.username}>
            <div className={Style.label}>
              {labelTwo} <span className={Style.mandatory}>*</span>
            </div>
            <InputField
              type={"text"}
              placeholder={placeholderTwo}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value.toLowerCase());
              }}
            />
          </div>
        )}

        <div className={Style.deposit}>
          <div className={Style.label}>
            {labelThree} <span className={Style.mandatory}>*</span>
          </div>
          <select
            className={Style.select}
            value={depositCoin}
            onChange={(e) => {
              setDepositCoin(e.target.value);
            }}
          >
            <option value="PUSD">PUSD</option>
            {userType === "self" && <option value="pwallet">AUSD</option>}
          </select>
        </div>
      </div>

      <div className={Style.fee}>{fee} $ {data.feeAmount}</div>
      <div className={Style.fee}>Token: $ {(parseFloat(data.tokenPrice)).toFixed(4)}</div>
      <div className={Style.bottom}>
        <div className={Style.toPay}>
          <div className={Style.amountXpl}>
            XPL:<span>{(parseFloat(data.xplTotalAmount)).toFixed(4)} </span>
          </div>
          {net}
          <span>
            $ {data.gross}
          </span>
        </div>

        <div className={Style.buttons}>
          <Button
            disabled={false}
            className={Style.button}
            onClick={() => { navigate("/asset/wallet", { state: { popup: true } }) }}
          >
            {title}
          </Button>

          <Button
            className={Style.button}
            isLoading={buyPackageApi.isLoading}
            disabled={userType === "self" || username !== "" ? false : true}
            onClick={() => {
              if (userType === "self") {
                buyPackageApi.executeQuery({
                  paymentMethod: depositCoin,
                  id: data.id,
                });
              } else {
                buyPackageApi.executeQuery({
                  paymentMethod: depositCoin,
                  id: data.id,
                  username: username,
                });
              }
            }}
          >
            {button}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FormBuy;
