import { useContext } from "react";

import texts from "../Data/text";

import LanguageContext from "../Contexts/languageContext";

const useText = (viewName) => {
  const [language] = useContext(LanguageContext);

  if (viewName) {
    return texts[language][viewName];
  } else {
    return texts[language];
  }
};

export default useText;
