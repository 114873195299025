import React from 'react'

// Styles
import Style from './Banner.module.css'

// Images
import Avatar from '../../../../Assets/images/logos/logo-bg-dark.png'

const Banner = ({ data }) => {

    return (
        <section className={Style.banner}>
            <div className={Style.backgroundBanner}>
                <div className={Style.avatar} style={{ backgroundImage: `url(${Avatar})` }}></div>
            </div>
            <div className={Style.info}>
                <div className={Style.name}>@{data.username ? data.username : "-"}</div>
            </div>
        </section>
    )
}

export default Banner