import React from 'react'

// Images
import CompletedImage from '../../../../Assets/images/transaction-complete-image.png'

// Styles
import Style from './Completed.module.css'

const Completed = ({ title, subTitle }) => {
    return (
        <section className={Style.completed}>
            <img src={CompletedImage} alt="verificated" />
            {title && <h5>{title}</h5>}
            {subTitle && <h6>{subTitle}</h6>}
        </section>
    )
}

export default Completed