import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Images
import XplCoin from "../../../../Assets/images/icons/xpl-coin.png";
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";
import AwCoin from "../../../../Assets/images/icons/aw-coin.png";
// import UsdtCoin from "../../../../Assets/images/icons/tether-coin.png";
// import Grow from "../../../../Assets/images/icons/graphic-grow.svg";
// import Low from "../../../../Assets/images/icons/graphic-low.svg";

// Icons
import { IoMdSwap } from "react-icons/io";
import { ImArrowUp2 } from "react-icons/im";
// import { MdOutlineCallMade } from "react-icons/md";
import { FiArrowDownCircle } from "react-icons/fi";
import { BsQuestion } from "react-icons/bs";

// Styles
import Style from "./CoinsAction.module.css";

const CoinsAction = ({ xpl, pusd, aw, usdt, setIsOpen, data, setIsOpenFee }) => {

	const navigate = useNavigate();

	const { xTitle, xAmount, xSwapButton, xSendButton } = xpl;
	const { pTitle, pAmount, pDepositButton, pSendButton } = pusd;
	const { aTitle, aAmount, aSendButton, valueAmount } = aw;
	// const { uTitle, uAmount, uButton, uValueAmount } = usdt;

	return (
		<section className={Style.coinsAction}>


			<div className={Style.coinCard}>
				<div className={Style.deepOne}>
					<div className={Style.card}>
						<div className={Style.information}>
							<div className={Style.coinBox}>
								<img src={XplCoin} alt="" className={Style.iconImage} />
								<div>{xTitle}</div>
							</div>

							{/* <div className={data.xpl.increase ? Style.growing : Style.lower}>
								<div className={Style.chart}>
									<img src={data.xpl.increase ? Grow : Low} alt="chart" />
								</div>
								<div className={Style.growNumber}>
									<MdOutlineCallMade
										className={data.xpl.increase ? Style.up : Style.down}
									/>
									<div>
										{data.xpl.increase ? "+" : "-"}
										{data.xpl.variation}
									</div>
								</div>
							</div> */}
						</div>

						<div className={Style.coinAmount}>
							<div className={Style.label}>{xAmount}</div>
							<div className={Style.amount}>
								{data.xpl.balance + " "}
								<span>{xTitle}</span>
							</div>
							<div className={Style.blocked}>
								{data.xpl.blocked !== 0 && data.xpl.blocked &&
									<div className={Style.info}>
										<div className={Style.mark}>
											<div className={Style.tooltip}>This amount was blocked, because suspicious transactions were found</div>
											<BsQuestion />
										</div>
										{data.xpl.blocked + " XPL"}

									</div>
								}
							</div>
						</div>
					</div>
				</div>
				{/* XPL Swap Withdraw */}
				<div className={Style.buttons}>
					<Button
						className={Style.button}
						onClick={() => navigate("/asset/swap")}
					>
						<IoMdSwap /> {xSwapButton}
					</Button>
					<Button
						className={Style.button}
						onClick={() => navigate("/asset/wallet/withdraw-xpl")}
					>
						<ImArrowUp2 /> {xSendButton}
					</Button>
				</div>
			</div>

			<div className={Style.coinCard}>
				<div className={Style.deepTwo}>
					<div className={Style.card}>
						<div className={Style.information}>
							<div className={Style.coinBox}>
								<img src={PusdCoin} alt="" className={Style.iconImage} />
								<div>{pTitle}</div>
							</div>

							{/* <div className={data.pusd.increase ? Style.growing : Style.lower}>
								<div className={Style.chart}>
									<img src={data.pusd.increase ? Grow : Low} alt="chart" />
								</div>
								<div className={Style.growNumber}>
									<MdOutlineCallMade
										className={data.pusd.increase ? Style.up : Style.down}
									/>
									<div>
										{data.pusd.increase ? "+" : "-"}
										{data.pusd.variation}
									</div>
								</div>
							</div> */}
						</div>

						<div className={Style.coinAmount}>
							<div className={Style.label}>{pAmount}</div>
							<div className={Style.amount}>
								{data.pusd.balance + " "}
								<span>{pTitle}</span>
							</div>
							<div className={Style.blocked}>
								{data.pusd.blocked !== 0 && data.pusd.blocked &&
									<div className={Style.info}>
										<div className={Style.mark}>
											<div className={Style.tooltip}>This amount was blocked, because suspicious transactions were found</div>
											<BsQuestion />
										</div>
										{data.pusd.blocked + " PUSD"}

									</div>
								}
							</div>
						</div>
					</div>
				</div>

				{/* Deposit - Send */}
				<div className={Style.buttons}>
					<Button
						className={Style.button}
						onClick={() => { setIsOpen(true); }}
					>
						<FiArrowDownCircle /> {pDepositButton}
					</Button>
					<Button
						className={Style.button}
						onClick={() => navigate("/asset/wallet/send-pusd")}
					>
						<ImArrowUp2 /> {pSendButton}
					</Button>
				</div>
			</div>

			<div className={Style.coinCard}>
				<div className={Style.deepThree}>
					<div className={Style.card}>
						<div className={Style.information}>
							<div className={Style.coinBox}>
								<img src={AwCoin} alt="" className={Style.iconImage} />
								<div>{aTitle}</div>
							</div>

							{/* <div
								className={data.pWallet.increase ? Style.growing : Style.lower}
							>
								<div className={Style.chart}>
									<img src={data.pWallet.increase ? Grow : Low} alt="chart" />
								</div>
								<div className={Style.growNumber}>
									<MdOutlineCallMade
										className={data.pWallet.increase ? Style.up : Style.down}
									/>
									<div>
										{data.pWallet.increase ? "+" : "-"}
										{data.pWallet.variation}
									</div>
								</div>
							</div> */}
						</div>

						<div className={Style.coinAmount}>
							<div className={Style.label}>{aAmount}</div>
							<div className={Style.amount}>
								{data.pWallet.balance + " "}
								<span>{valueAmount}</span>
							</div>
							<div className={Style.blocked}>
								{data.pWallet.blocked !== 0 && data.pWallet.blocked &&
									<div className={Style.info}>
										<div className={Style.mark}>
											<div className={Style.tooltip}>This amount was blocked, because suspicious transactions were found</div>
											<BsQuestion />
										</div>
										{data.pWallet.blocked + " AUSD"}

									</div>
								}
							</div>
						</div>
					</div>
				</div>

				{/* Send */}
				<div className={Style.buttons}>
					<Button className={Style.button}></Button>
					<Button
						className={Style.button}
						onClick={() => navigate("/asset/wallet/send-ausd")}
					>
						<ImArrowUp2 /> {aSendButton}
					</Button>
				</div>
			</div>

			{/* <div className={Style.coinCard}>
				<div className={Style.deepFour}>
					<div className={Style.card}>
						<div className={Style.information}>
							<div className={Style.coinBox}>
								<img src={UsdtCoin} alt="" className={Style.iconImage} />
								<div>{uTitle}</div>
							</div>

							<div style={{ height: 47 }}></div>
						</div>

						<div className={Style.coinAmount}>
							<div className={Style.label}>{uAmount}</div>
							<div className={Style.amount}>
								{data.usdt.balance + " "}
								<span>{uValueAmount}</span>
							</div>
						</div>
					</div>
				</div>

				<div className={Style.buttons}>
					<Button className={Style.button}></Button>
					<Button
						disabled={true}
						className={Style.button}
						onClick={() => setIsOpenFee(true)}
					>
						<FiArrowDownCircle /> {uButton}
					</Button>
				</div>
			</div> */}


		</section>
	);
};

export default CoinsAction;
