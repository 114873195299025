import React, { useState, useEffect } from "react";

// Components
import GeneralTable from "../../../../Components/Commons/GeneralTable/GeneralTable";

// Libraries
import styled from "styled-components";
import useQuery from "@hybris-software/use-query";

// Styles
// import Style from "./RecentDevices.module.css";

const RecentDevices = ({ browser, device, location, ip, time }) => {
  const columns = [
    {
      Header: browser,
      accessor: "browserFamily",
    },
    {
      Header: device,
      accessor: "deviceType",
    },
    {
      Header: location,
      accessor: "location",
    },
    {
      Header: ip,
      accessor: "ipAddress",
    },
    {
      Header: time,
      accessor: "lastInteraction",
    },
  ];

  const Styles = styled.div`
    overflow-x: auto;
    overflow-y: hidden;

    table {
      border-radius: 10px 10px 0px 0px;
      table-layout: fixed;
      border-spacing: 0;
      width: 100%;
      font-size: 13px;
      font-weight: 600;
      thead {
        th {
          div {
            width: 80%;
            text-align: center;
            margin: 0 auto;
            background-color: var(--main);
            border-radius: 9px;
            padding: 10px;
            color: white;
          }
        }
      }
      tbody {
        tr {
          margin-left: 30px;
          border-radius: 9px;
          :nth-child(even) {
            background-color: #87276c31;
          }
          td {
            padding-block: 15px;
            text-align: center;
            color: var(--text-subtitle);
            font-weight: 600;
            font-size: 14px;
            :first-child {
              border-radius: 9px 0 0 9px;
            }
            :last-child {
              border-radius: 0 9px 9px 0;
            }
          }
        }
      }
    }
  `;

  const [tableData, setTableData] = useState(null);
  const [url, setUrl] = useState(
    "recent-devices/?limit=10"
  );

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setTableData(response.data);
    },
  });

  useEffect(() => {
    tableApi.executeQuery();
    // eslint-disable-next-line
  }, [url]);

  return (
    <section>
      {tableData && (
        <GeneralTable
          height="580px"
          title={"Recent Devices"}
          data={tableData}
          columns={columns}
          Styles={Styles}
          setTableData={setTableData}
          setUrl={setUrl}
          isLoading={tableApi.isLoading}
        />
      )}
    </section>
  );
};

export default RecentDevices;
