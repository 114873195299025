// Icons
import {
    BsArrowLeft,
    BsArrowRight,
    BsArrowLeftRight,
    BsArrowDown,
    BsArrowUp,
    BsGift
} from "react-icons/bs";

// Styles
import Style from '../Components/Commons/GeneralTable/GeneralTable.module.css'

function chooseIcon(type, string) {

    // Send Widtdrawal
    if (type === "sendw" || type === "sendw_refused" || type === "sendw_accepted") {
        return <div
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <span
                style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    display: "inline-block",
                    borderRadius: "50%",
                    backgroundColor: "#FC34001a",
                    marginRight: "10px",
                }}
            >
                <BsArrowUp
                    style={{
                        fontSize: 18,
                        color: "#FC3400",
                        marginTop: "5px",
                    }}
                />
            </span>
            <span className={Style.clampedText}> {string}</span>
        </div>
    }

    // Swap
    if (type === "swap") {
        return <div
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <span
                style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    display: "inline-block",
                    borderRadius: "50%",
                    backgroundColor: "rgba(0, 142, 255, 0.3)",
                    marginRight: "10px",
                }}
            >
                <BsArrowLeftRight
                    style={{
                        fontSize: 18,
                        color: "#008EFF",
                        marginTop: "5px",
                    }}
                />
            </span>
            <span className={Style.clampedText}> {string}</span>
        </div>
    }

    // Receive
    if (type === "receive") {
        return <div
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <span
                style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    display: "inline-block",
                    borderRadius: "50%",
                    backgroundColor: "#52c41a1a",
                    marginRight: "10px",
                }}
            >
                <BsArrowLeft
                    style={{
                        fontSize: 18,
                        color: "var(--success-secondary)",
                        marginTop: "5px",
                    }}
                />
            </span>
            <span className={Style.clampedText}> {string}</span>
        </div>
    }

    // Send
    if (type === "send") {
        return <div
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <span
                style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    display: "inline-block",
                    borderRadius: "50%",
                    backgroundColor: "#FC34001a",
                    marginRight: "10px",
                }}
            >
                <BsArrowRight
                    style={{
                        fontSize: 18,
                        color: "#FC3400",
                        marginTop: "5px",
                    }}
                />
            </span>
            <span className={Style.clampedText}> {string}</span>
        </div>
    }

    // Deposit
    if (type === "deposit" || type === "depositUsdt" || type === "depositPending") {
        return <div
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <span
                style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    display: "inline-block",
                    borderRadius: "50%",
                    backgroundColor: "#52c41a1a",
                    marginRight: "10px",
                }}
            >
                <BsArrowDown
                    style={{
                        fontSize: 18,
                        color: "var(--success-secondary)",
                        marginTop: "5px",
                    }}
                />
            </span>
            <span className={Style.clampedText}> {string}</span>
        </div>
    }

    // Buy
    if (type === "buy" || type === "buy_from" || type === "buy_to" || type === "giftReceived") {
        return <div
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <span
                style={{
                    minWidth: "30px",
                    minHeight: "30px",
                    display: "inline-block",
                    borderRadius: "50%",
                    backgroundColor: "#f5a2001f",
                    marginRight: "10px",
                }}
            >
                <BsGift
                    style={{
                        fontSize: 16,
                        color: "var(--warning)",
                        marginTop: "6px",
                    }}
                />
            </span>
            <span className={Style.clampedText}> {string}</span>
        </div>
    }

    return string
}

export default chooseIcon;