import React, { useState, useEffect } from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Components
import SwapForm from "./Components/SwapForm/SwapForm";
import Heading from "../../Components/Commons/Heading/Heading";
import ConfirmSwap from "./Components/ConfirmSwap/ConfirmSwap";
import Loader from "../../Components/Commons/Loader/Loader";
import GeneralTable from "../../Components/Commons/GeneralTable/GeneralTable";

// Utils
import chooseIcon from "../../Utils/chooseIcon";

// Hooks
import useText from "../../Hooks/useText";

const Swap = () => {

  const navigate = useNavigate();
  const texts = useText("swap");
  const { head, swapBox, confirmSwap, table, page } = texts;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const [swapStep, setSwapStep] = useState(1);
  const [swapCheckout, setSwapCheckout] = useState(null);

  //   Table
  const [tableData, setTableData] = useState(null);
  const [url, setUrl] = useState("wallets/history/?page=1&limit=10&type=swap");

  const columns = [
    {
      Header: table.labelOne,
      accessor: (row) => {
        const string = `#${row.transactionId}`;
        return chooseIcon(row.type, string);
      },
    },
    {
      Header: table.labelTwo,
      accessor: "timestamp",
    },
    {
      Header: table.labelThree,
      accessor: "amount",
    },
    {
      Header: table.labelFour,
      accessor: "type",
    },
  ];

  const balanceApi = useQuery({
    url: "xpl/balance/",
    method: "GET",
    executeImmediately: true,
  });

  const roundInfoApi = useQuery({
    url: "xpl/round/",
    method: "GET",
    executeImmediately: true,
  });

  const feeInfoApi = useQuery({
    url: "configuration/fees/",
    method: "GET",
    executeImmediately: true,
  });

  const tableApi = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setTableData(response.data);
    },
  });

  useEffect(() => {
    tableApi.executeQuery();
    // eslint-disable-next-line
  }, [url]);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={
        balanceApi.isLoading || roundInfoApi.isLoading || feeInfoApi.isLoading
      }
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        <Heading {...head} />
        {swapStep === 1 && balanceApi.response && roundInfoApi.response && (
          <SwapForm
            {...swapBox}
            setSwapStep={setSwapStep}
            balance={balanceApi.response.data.xplBalance}
            tokenPrice={roundInfoApi.response.data.tokenMarketPrice}
            setSwapCheckout={setSwapCheckout}
          />
          /* !TAble */
        )}
        {swapStep === 2 && (
          <ConfirmSwap
            {...confirmSwap}
            setSwapStep={setSwapStep}
            data={swapCheckout}
            fees={feeInfoApi.response.data}
          />
        )}
        {tableData && (
          <GeneralTable
            height="730px"
            title={table.title}
            data={tableData}
            columns={columns}
            setTableData={setTableData}
            setUrl={setUrl}
            isLoading={tableApi.isLoading}
          />
        )}
      </Container>
    </AuthRoute>
  );
};

export default Swap;
