import React, { useContext } from "react";

// Libraries
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Icon
import { MdOutlineCallMade } from "react-icons/md";

// Contexts
import SidebarContext from "../../../../Contexts/sidebarContext";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./Chart.module.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const Chart = ({ data, walletInfo }) => {

  const texts = useText("wallet");
  const { title, labelOne, labelTwo, labelThree } = texts.chart;

  const sideBarStatus = useContext(SidebarContext);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + " $";
          },
        },
      },
    },
  };

  const labels = data.labels;

  const dataChart = {
    labels,
    datasets: [
      {
        label: "XPL",
        barThickness: "flex",
        barPercentage: 0.6,
        categoryPercentage: 0.25,
        borderRadius: {
          topRight: 6,
          topLeft: 6,
        },
        data: data.xplBalance,
        backgroundColor: "#FFCE34",
      },
      {
        label: "PUSD",
        barThickness: "flex",
        barPercentage: 0.6,
        categoryPercentage: 0.25,
        borderRadius: {
          topRight: 6,
          topLeft: 6,
        },
        data: data.pusdBalance,
        backgroundColor: "#2e00af",
      },
      {
        label: "AUSD",
        barThickness: "flex",
        barPercentage: 0.6,
        categoryPercentage: 0.25,
        borderRadius: {
          topRight: 6,
          topLeft: 6,
        },
        data: data.pWalletBalance,
        backgroundColor: "#7314ed",
      },
    ],
  };

  return (
    <div className={Style.chartContainer}>
      <h5>{title}</h5>



      <div className={Style.chartLabelsContainer}>
        <div className={Style.chartLabels}>
          <div className={Style.label}>
            <div className={Style.circle} style={{ backgroundColor: "#FFCE34" }}></div>
            {labelOne}
          </div>
          <div className={Style.totalData}>
            <p className={Style.balance}>{walletInfo.xpl.balance}</p>
            <div style={{ minWidth: 120 }}>
              <p className={walletInfo.xpl.increase ? Style.numbersUp : Style.numbers}>
                <MdOutlineCallMade className={walletInfo.xpl.increase ? Style.up : Style.down} />
                {walletInfo.xpl.increase ? "+" : "-"}
                {walletInfo.xpl.variation} %
              </p>
            </div>
          </div>
        </div>

        <div className={Style.chartLabels}>
          <div className={Style.label}>
            <div className={Style.circle} style={{ backgroundColor: "#2e00af" }}></div>
            {labelTwo}
          </div>
          <div className={Style.totalData}>
            <p className={Style.balance}>{walletInfo.pusd.balance}</p>
            <div style={{ minWidth: 120 }}>
              <p className={walletInfo.pusd.increase ? Style.numbersUp : Style.numbers}>
                <MdOutlineCallMade className={walletInfo.pusd.increase ? Style.up : Style.down} />
                {walletInfo.pusd.increase ? "+" : "-"}
                {walletInfo.pusd.variation} %
              </p>
            </div>
          </div>
        </div>

        <div className={Style.chartLabels}>
          <div className={Style.label}>
            <div className={Style.circle} style={{ backgroundColor: "#7314ed" }}></div>
            {labelThree}
          </div>
          <div className={Style.totalData}>
            <p className={Style.balance}>{walletInfo.pWallet.balance}</p>
            <div style={{ minWidth: 120 }}>
              <p className={walletInfo.pWallet.increase ? Style.numbersUp : Style.numbers}>
                <MdOutlineCallMade className={walletInfo.pWallet.increase ? Style.up : Style.down} />
                {walletInfo.pWallet.increase ? "+" : "-"}
                {walletInfo.pWallet.variation} %
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.chart}>
        <Bar
          options={options}
          data={dataChart}
          height="100%"
          style={{
            width:
              !sideBarStatus || window.innerWidth < 991
                ? "100%"
                : "calc(100% - var(--width-sidebar))",
            maxWidth: "100%",
            transition: "width 0.3s",
            height: "100%",
          }}
        />
      </div>
    </div >
  );
};

export default Chart;
