import React, { useEffect } from "react";
// Components
import LoaderGlobal from "../../../Components/Commons/LoaderGlobal/LoaderGlobal";
// Libraires
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";

// Images
import MaintenanceImage from "../../../Assets/images/maintence.png";
import PointRight from "../../../Assets/images/point-right.png";
import PointLeft from "../../../Assets/images/point-left.png";
import LogoPulseFull from "../../../Assets/images/logos/logo-pulse-full.png";

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./Maintenance.module.css";

const Maintenance = () => {
  const navigate = useNavigate();
  const texts = useText("maintenance");
  const { title, subTitle, page } = texts;

  const featuresApi = useQuery({
    url: "configuration/features/",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      console.log(response);
      if (response.data.loginEnabled === false) {
      } else {
        navigate("/auth/login");
      }
    },
  });

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  return (
    <section className={Style.page}>
      {featuresApi.isLoading ? (
        <LoaderGlobal /> // Replace with your loader component or JSX
      ) : (
        <>
          <img
            src={LogoPulseFull}
            alt=""
            className={Style.logo}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/auth/login");
            }}
          />
          <img src={PointRight} alt="" className={Style.pointRight} />
          <img src={PointLeft} alt="" className={Style.pointLeft} />
          <div className={Style.center}>
            <img src={MaintenanceImage} alt="" />
            <div className={Style.text}>
              <h1>{title}</h1>
              <h4>{subTitle}</h4>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Maintenance;
