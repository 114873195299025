import React from "react";

// Libraries
import { Button, InputField } from "@hybris-software/ui-kit";
import useForm from "@hybris-software/use-ful-form";

// Imgaes
import XplCoin from "../../../../Assets/images/icons/xpl-coin.png";
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";

// Icons
import { HiOutlineArrowNarrowDown } from "react-icons/hi";

// Hooks
import useText from "../../../../Hooks/useText";

// Style
import Style from "./SwapForm.module.css";
import { Link } from "react-router-dom";

const SwapForm = ({ balance, setSwapStep, tokenPrice, setSwapCheckout }) => {
  const texts = useText("swap");
  const {
    title,
    input,
    balanceText,
    enterAll,
    output,
    toAccept,
    legal,
    button,
  } = texts.swapBox;

  const form = useForm({
    inputs: {
      xpl: {
        formatter: (value) => {
          // Only allow numbers and one decimal point
          const decimal = value.split(".")[1];
          if (decimal && decimal.length > 8) {
            value = value.slice(0, -1);
          }
          if (
            value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1") > parseFloat(balance)
          ) {
            return parseFloat(balance);
          } else {
            return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
          }
        },
        validator: (value) => {
          if (value > parseFloat(balance) || value === "" || value <= 0 || value === "0.") {
            return [false, ""];
          } else {
            return [true, ""];
          }
        },
      },
      acceptTerms: {
        nature: "checkbox",
        sendToApi: false,
        required: true,
      },
    },
  });

  return (
    <section className={Style.swapBox}>
      {title && <h4 className={Style.title}>{title}</h4>}

      <div className={Style.input}>
        <div className={Style.coin}>
          <img src={XplCoin} alt="" />
          XPL
        </div>

        <div className={Style.inputText}>{input}</div>
        <InputField
          baseClassName={Style.inputField}
          errorClassName={Style.inputField}
          successClassName={Style.inputField}
          placeholder={"0.0"}
          onCopy={false}
          {...form.getInputProps("xpl")}
        />


        <div className={Style.balanceText}>
          <span>
            {balanceText} {balance} XPL
          </span>
          <span
            className={Style.all}
            onClick={() => {
              form.getInputProps("xpl").setValue(balance.toString());
            }}
          >
            {enterAll}
          </span>
        </div>
      </div>

      <div className={Style.arrow}>
        <div className={Style.icon}>
          <HiOutlineArrowNarrowDown />
        </div>
      </div>

      <div className={Style.output}>
        <div className={Style.coin}>
          <img src={PusdCoin} alt="" />
          PUSD
        </div>

        <div className={Style.outputText}>{output}</div>
        <InputField
          baseClassName={Style.inputFieldOut}
          placeholder={"0.0"}
          value={form.values["xpl"] * tokenPrice}
          readOnly
        />
      </div>

      <div className={Style.bottom}>
        <div className={Style.check}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              style={{ width: "20px", height: "20px", color: "white" }}
              type="checkbox"
              id="swap"
              value={form.getInputProps("acceptTerms").value}
              onChange={(e) => {
                form.getInputProps("acceptTerms").setValue(e.target.checked);
              }}
            />
          </div>
          <label htmlFor="swap">
            {toAccept}
            <Link to="/legal/terms-and-condition">
              <span className={Style.terms}> {legal}</span>
            </Link>
          </label>
        </div>

        <Button
          className={Style.button}
          disabled={!form.isValid()}
          onClick={() => {
            setSwapStep(2);
            setSwapCheckout({
              pusd: form.values["xpl"] * tokenPrice,
              xpl: form.values["xpl"],
            });
          }}
        >
          {button}
        </Button>
      </div>
    </section>
  );
};

export default SwapForm;
