import React from 'react'

// Libraries
import { Button } from '@hybris-software/ui-kit'
import { useNavigate } from 'react-router-dom';

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./CheckInfoUser.module.css";

const CheckInfoUser = ({ setCheckUser }) => {

    const navigate = useNavigate();

    const texts = useText("updateProfile");
    const {
        title,
        subtitle,
    } = texts;

    return (
        <section className={Style.disclaimer}>
            <div className={Style.header}>
                <h3>PULSE WORLD</h3>
                <h6>{title}</h6>
            </div>

            <div className={Style.body}>
                <p>{subtitle}</p>
            </div>

            <div className={Style.buttons}>
                <Button
                    className={Style.button}
                    onClick={() => { navigate("/profile"); setCheckUser(false) }}
                >
                    Update Profile
                </Button>
                <Button
                    className={Style.buttonClose}
                    onClick={() => { setCheckUser(false) }}
                >
                    Close
                </Button>
            </div>
        </section>
    )
}

export default CheckInfoUser