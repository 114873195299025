import React from "react";

// Image
import LogoPulseFull from "../../Assets/images/logos/logo-pulse-full.png";

// Styles
import Style from "./BlockedUser.module.css";

const BlockedUser = () => {
  return (
    <section className={Style.page}>
      <img src={LogoPulseFull} alt="" className={Style.logo} />
      <div className={Style.center}>
        <div className={Style.text}>
          <h1>Your account has been suspended for suspicious transactions.</h1>
          <h4>Contact support for more information.</h4>
          <a href="https://support.pulseworld.com/">Click here to contact support</a>
        </div>
      </div>
    </section>
  );
};

export default BlockedUser;
