import React from "react";

// Componetns
import CardShop from "../../../../Components/Commons/CardShop/CardShop";

// Styles
import Style from "./CardGrid.module.css";

const CardGrid = ({ title, data, roundInfo, tokenPrice }) => {
  return (
    <section>
      {data.length > 0 && <h5 className={Style.title}>{title}</h5>}
      <div className={Style.grid}>
        {data.map((item, index) => (
          <CardShop key={index} item={item} roundInfo={roundInfo} tokenPrice={tokenPrice} />
        ))}
      </div>
    </section>
  );
};

export default CardGrid;
