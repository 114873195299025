import React, { useState } from "react";

// Components
import GeneralPopup from "../../../Components/Commons/GeneralPopup/GeneralPopup";

// Librerias
import { Button, InputField } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import Web3 from "web3";

// Images
import XplCOin from "../../../Assets/images/icons/xpl-coin.png";
import ErrorImage from "../../../Assets/images/error-transaction.png";

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./SendBox.module.css";
import Completed from "../../Transaction/Components/Completed/Completed";

const SendBox = ({ updateBalance, yourBalance, data, fees }) => {
  const web3 = new Web3();

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorType, setErrorType] = useState("");

  const texts = useText("sendXpl");
  const {
    title,
    inputTo,
    inputAmount,
    placeholderAmount,
    button,
    youWillReceive,
    netAmount,
    // warningEnd,
    // warning,
  } = texts.sendBox;

  const {
    titleSuccess,
    subTitleSuccess,
    buttonSuccess,
    insufficientBalance,
    error,
    buttonClose,
  } = texts.popup;

  const form = useForm({
    inputs: {
      address: {
        required: true,
        validator: (value) => {
          if (web3.utils.isAddress(value) === true) {
            return [true, ""];
          } else {
            return [false, "Invalid wallet address"];
          }
        },
      },
      amount: {
        formatter: (value) => {
          // Remove the last decimal digits after the 8th decimal place on value
          const decimal = value.split(".")[1];
          if (decimal && decimal.length > 8) {
            value = value.slice(0, -1);
          }

          if (
            value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1") >
            parseFloat(data.xplBalance)
          ) {
            return data.xplBalance;
          } else {
            return value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
          }
        },
        validator: (value) => {
          if (
            value > parseFloat(data.xplBalance) ||
            value === "" ||
            value < fees.withdrawFee.minXplWithdraw
          ) {
            return [false, ""];
          } else {
            return [true, ""];
          }
        },
      },
    },
  });

  const xplSend = useQuery({
    url: "wallets/send-request/",
    method: "POST",
    executeImmediately: false,
    onSuccess: () => {
      setConfirmPopup(true);
    },
    onError: (error) => {
      if (error.response.data.code === "INSUFFICIENT_BALANCE") {
        setErrorPopup(true);
        setErrorType("INSUFFICIENT_BALANCE");
      }
    },
  });

  return (
    <section className={Style.xpl}>
      {confirmPopup && (
        <GeneralPopup className={Style.confirmPopup}>
          <Completed title={titleSuccess} subTitle={subTitleSuccess} />
          <Button
            onClick={() => {
              setConfirmPopup(false);
              updateBalance.executeQuery();
            }}
          >
            {buttonSuccess}
          </Button>
        </GeneralPopup>
      )}

      {errorPopup && (
        <GeneralPopup className={Style.errorPopup}>
          <img src={ErrorImage} alt="" width={"100%"} />
          {errorType === "INSUFFICIENT_BALANCE" ? (
            <p>{insufficientBalance}</p>
          ) : (
            <p>{error}</p>
          )}
          <Button
            onClick={() => {
              setErrorPopup(false);
              setErrorType("");
            }}
          >
            {buttonClose}
          </Button>
        </GeneralPopup>
      )}
      {title && <h4 className={Style.title}>{title}</h4>}
      {inputTo && (
        <div className={Style.inputTo}>
          <InputField
            label={inputTo}
            type={"text"}
            placeholder={"0xD7768FcFEC7D9179085D1009149a2Aa79D37f747"}
            required={true}
            {...form.getInputProps("address")}
          />
        </div>
      )}

      {inputAmount && (
        <div className={Style.amountTotal}>
          <InputField
            label={
              inputAmount + ` (Minimum: ${fees.withdrawFee.minXplWithdraw} XPL)`
            }
            type={"text"}
            placeholder={placeholderAmount}
            required={true}
            {...form.getInputProps("amount")}
            onCopy={false}
          />
          <img className={Style.coinIndetifier} src={XplCOin} alt="" />
        </div>
      )}

      <div>{youWillReceive} {fees.withdrawFee.value} XPL</div>

      <div style={{ marginBottom: 10 }}>
        {netAmount}
        {form.values["amount"] > fees.withdrawFee.value ?
          (form.values["amount"] - fees.withdrawFee.value).toFixed(4)
          : (0).toFixed(4)
        } XPL
      </div>

      {/* <div style={{ marginBottom: 10 }}>
          {(form.values["amount"] - (form.values["amount"] * (1 - fees.withdrawFee.value / 100)) + 1).toFixed(4)} XPL ({fees.withdrawFee.value}% + 1$)
        </div> */}

      {/* <div style={{ maxWidth: "500px", fontSize: 13 }}>
        {warning} {fees.withdrawFee.rewardsMalus}% {warningEnd}
      </div> */}
      <div className={Style.bottom}>
        {yourBalance && (
          <div className={Style.balance}>
            <div className={Style.label}>{yourBalance}</div>
            <div className={Style.amount}>
              {data.xplBalance} <img src={XplCOin} alt="" />
            </div>
          </div>
        )}
        <Button
          className={Style.button}
          isLoading={xplSend.isLoading}
          disabled={!form.isValid()}
          onClick={() => {
            xplSend.executeQuery(form.getApiBody());
          }}
        >
          {button}
        </Button>
      </div>
    </section>
  );
};

export default SendBox;
