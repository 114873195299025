const Config = {
    API_BASE_URL:
        process.env.REACT_APP_API_BASE_URL ||
        "https://api.pulseworld.com/api/v1/",
    OMEGA_LOGIN_PAGE:
        process.env.REACT_APP_OMEGA_LOGIN_PAGE ||
        "https://backoffice.omegapro.world/",
};

export default Config;
