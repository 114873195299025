const data = {
  EN: {
    updateProfile: {
      title: "Hello,",
      subtitle:
        "Your account information is not accurate , make sure to update it by pressing the 'Update Profile' button below.",
    },
    sidebar: {
      dashboard: "Dashboard",
      myAsset: "My Asset",
      wallet: "Wallet",
      depositHistory: "Deposit History",
      exchange: "Exchange",
      swap: "Swap",
      myShop: "My Shop",
      myStaking: "My Rewards",
      metaverse: "Metaverse",
      nft: "NFT",
      legal: "Legal",
      term: "Terms and Condition",
      privacy: "Privacy Policy",
      disclaimer: "Risk Disclaimer",
      faq: "FAQ",
      social: "Social Media",
      support: "Support",
    },
    navbar: {
      profilePopupText: {
        logout: "Logout",
        profile: "Profile",
        recentDevices: "Recent Devices",
      },
      notificationPopupText: {
        packagePurchased: "Package purchased correctly!",
        packagePurchasedFor: "Package purchased correctly for ",
        packagePurchasedFrom: "Package purchased correctly from ",
        deposit: "Deposit made successfully!",
        sendPusd: "PUSD sent to ",
        sendAw: "AUSD sent to ",
        receivedPusd: "PUSD received from ",
        receivedAw: "AUSD received from ",
        swap: "Swap made successfully!",
        pusdReceivedFromOmega: "PUSD received",
        awReceivedFromOmega: "AUSD received",
        readAll: "Read all",
        noNotifications: "No notifications",
        titleActivities: "Latest Activities",
        noActivities: "No activities",
        depositUsdt: "Deposit made successfully for USDT",
        giftReceived: "Gift received!",
        depositPending: "Confirmation of your deposit is in progress",
      },
    },
    profile: {
      page: "Profile",
      menu: {
        profile: "Profile",
        recentDevices: "Recent Devices",
      },
      basicInformation: {
        title: "Basic Information",
        name: "Full Name",
        username: "Username",
        email: "Email",
        phone: "Phone",
        country: "Country",
        city: "City",
        zipCode: "Zip Code",
        wallet: "Airdrop wallet",
      },
      recentDevices: {
        browser: "Browser",
        device: "Devices",
        location: "Location",
        ip: "Address",
        time: "Timestamp",
      },
    },
    dashboard: {
      page: "Dashboard",
      head: {
        viewTitle: "Dashboard",
        viewSubTitle: "",
      },
      stats: {
        labelOne: "Marketcap",
        labelTwo: "XPL Price",
        labelThree: "Total PUSD",
        labelFour: "Total AUSD",
      },
      staking: {
        title: "XPL Overview",
        labelOne: "Total",
        labelTwo: "Daily Rewards",
        buttonSwap: "Wallet",
        buttonSend: "Rewards",
      },
      currency: {
        title: "Value of each Currency in USD",
        labelOne: "XPL in USD",
        ofWhat: "of total",
      },
      chart: {
        title: "Wallet Overview",
        time: "Last 7 days",
      },
    },
    wallet: {
      page: "Wallet",
      head: {
        viewTitle: "Wallet",
        viewSubTitle: "",
      },
      assetCoin: {
        xpl: {
          xTitle: "XPL",
          xAmount: "Total amount",
          xSwapButton: "Swap",
          xSendButton: "Withdraw",
        },
        pusd: {
          pTitle: "PUSD",
          pAmount: "Total amount",
          pDepositButton: "Deposit",
          pSendButton: "Send",
        },
        aw: {
          aTitle: "AUSD",
          aAmount: "Total amount",
          valueAmount: "AUSD",
          aSendButton: "Send",
        },
        usdt: {
          uTitle: "USDT for Fee",
          uAmount: "Total amount",
          uButton: "Deposit",
          uValueAmount: "USDT",
        },
      },
      depositConfirmation: {
        message:
          "We are processing your transaction, we will send you a notification when the amount is credited to your account.",
        button: "Close",
      },
      popUp: {
        title: "Select a Token",
        title2: "Select Amount",
        button: "Confirm",
        feeText: "Deposit Fee:",
        btcTime: "BTC may take longer than other currencies.",
        alert:
          "The deposit can take up to 4 hours. You will receive a notification when the deposit has been made.",
      },
      chart: {
        title: "Wallet Overview",
        labelOne: "Total XPL",
        labelTwo: "Total PUSD",
        labelThree: "Total AUSD",
      },
      table: {
        title: "History",
        labelOne: "Transaction ID",
        labelTwo: "Date",
        labelThree: "Amount",
        labelFour: "Type",
      },
    },
    sendXpl: {
      page: "Withdraw XPL",
      head: {
        viewTitle: "Withdraw XPL",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Withdraw XPL",
        inputTo: "Wallet Address - BSC (BEP20)",
        placeholderTo: "Wallet address",
        inputAmount: "Amount to Send",
        placeholderAmount: "--",
        yourBalance: "Your balance:",
        youWillReceive: "Withdraw Fee:",
        warning:
          "Warning: If you make a withdrawal you will be affected by a malus. Your daily rewards on packages already purchased will drop to",
        warningEnd: "per day",
        button: "Proceed",
        netAmount: "Net Amount: ",
      },
      popup: {
        titleSuccess: "Congratulations!",
        subTitleSuccess: "Your request has been sent!",
        buttonSuccess: "Confirm",
        buttonClose: "Close",
        insufficientBalance:
          "You do not have a sufficient balance to purchase the withdraw. Please deposit more coins.",
        error: "An error has occurred. Please try again later.",
      },
    },
    sendAw: {
      page: "Send AUSD",
      head: {
        viewTitle: "Send AUSD",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Send AUSD",
        inputTo: "To",
        placeholderTo: "Username",
        inputAmount: "Amount to Send:",
        placeholderAmount: "--",
        yourBalance: "Your balance:",
        button: "Proceed",
      },
      popup: {
        titleSuccess: "Congratulations!",
        subTitleSuccess: "Your transactions has completed!",
        buttonSuccess: "Confirm",
        buttonClose: "Close",
        insufficientBalance:
          "You do not have a sufficient balance to purchase. Please deposit more coins.",
        invalidUsername:
          " The username you entered is not valid. Please enter a valid username.",
        error: "An error has occurred. Please try again later.",
      },
    },
    sendPusd: {
      page: "Send PUSD",
      head: {
        viewTitle: "Send PUSD",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Send PUSD",
        inputTo: "To",
        placeholderTo: "Username",
        inputAmount: "Amount to Send:",
        placeholderAmount: "--",
        yourBalance: "Your balance:",
        button: "Proceed",
      },
      popup: {
        titleSuccess: "Congratulations!",
        subTitleSuccess: "Your transactions has completed!",
        buttonSuccess: "Confirm",
        buttonClose: "Close",
        insufficientBalance:
          "You do not have a sufficient balance to purchase. Please deposit more coins.",
        invalidUsername:
          " The username you entered is not valid. Please enter a valid username.",
        error: "An error has occurred. Please try again later.",
      },
    },
    transaction: {
      page: "Transaction",
      head: {
        viewTitle: "Transaction request received",
        viewSubTitle: "",
      },
      inProgress: {
        title: "Complete your transaction",
        retry: "If your screen did not open correctly",
        retryButton: "Click here",
        buttonVerify: "Verify",
        subTitle:
          "If the page to carry out the transaction did not open correctly, check that the browser has not blocked the popup.",
        cta: "Return to wallet",
      },
      completed: {
        title: "Congratulations!",
        subTitle: "Your transactions has completed!",
      },
      error: {
        title: "Ops!",
        subTitle: "An error seems to have occurred. Complete your transaction",
      },
    },
    swap: {
      page: "Swap",
      head: {
        viewTitle: "Swap",
        viewSubTitle: "",
      },
      swapBox: {
        title: "Swap Now",
        input: "Input",
        balanceText: "Balance:",
        enterAll: "Enter all",
        output: "Output",
        toAccept: "By doing Swap you accept our",
        legal: "Terms and Conditions",
        button: "Swap",
      },
      confirmSwap: {
        preTitle: "Exchange",
        postTitle: "for",
        receiveText: "You will receive",
        fromText: "from:",
        buttonReject: "Reject",
        buttonAccept: "Confirm Swap",
      },
      table: {
        title: "History",
        labelOne: "Transaction ID",
        labelTwo: "Date",
        labelThree: "Amount",
        labelFour: "Type",
      },
      popup: {
        titleSuccess: "Congratulations!",
        subTitleSuccess: "Your transactions has completed!",
        buttonSuccess: "Return to swap",
        buttonClose: "Close",
        insufficientBalance:
          "You do not have a sufficient balance to purchase the swap. Please deposit more coins.",
        error: "An error has occurred. Please try again later.",
      },
    },
    depositHistory: {
      page: "Deposit history",
      head: {
        viewTitle: "Deposit history",
        viewSubTitle: "",
      },
      table: {
        title: "Your deposit history",
        alert: "Click on Transaction ID to copy it",
        labelOne: "Status",
        labelTwo: "Order ID",
        labelThree: "Transaction ID",
        labelFour: "Amount",
        labelFive: "Created At",
      },
    },
    shopList: {
      page: "My Shop",
      head: {
        viewTitle: "My shop",
        viewSubTitle: "",
      },
      seed: {
        timer: {
          title: "Ends in:",
          days: "Days",
          hours: "Hours",
          minutes: "Minutes",
        },
        title: "Token Market Price",
        titleLabel: "OmegaPro Exclusive Private Sale",
        discount: "Discount",
        pulsePrice: "per PULSE",
        limit: "Limited to",
        cardBuy: {
          title: "XPL Packages",
          textButton: "Buy",
          discount: "Discount",
          price: "Price",
        },
        cardBuyLimited: {
          title: "Limited time offers",
          textButton: "Buy",
          discount: "Discount",
          price: "Price",
        },
      },
    },
    shopBuy: {
      page: "My Shop",
      head: {
        viewTitle: "My Shop",
        viewSubTitle: "",
      },
      popupConfirm: {
        title: "Congratulations!",
        subTitle: "Welcome to the Pulse community",
        button: "See your packages",
      },
      popupError: {
        insufficientBalance:
          "You do not have enough balance to buy this package. Please deposit more coins.",
        invalidUsername:
          " The username you entered is not valid. Please enter a valid username.",
        invalidPackage:
          " The package you are trying to buy is not valid or soldout. Please try again later.",
        error: "An error has occurred. Please try again later.",
        close: "Close",
      },
      formBuy: {
        labelOne: "For",
        optionOne: "You",
        optionTwo: "Other User",
        labelTwo: "Username ",
        placeholderTwo: "Enter username",
        labelThree: "Select deposit position:",
        net: "Net pay by:",
        fee: "Fee:",
        button: "Pay now",
      },
      depositButton: {
        title: "Deposit",
      },
      timerExpired: {
        header: "Expired",
        text: "The time for purchasing the package has expired",
        button: "Go to shop",
        have: "You still have",
        payment: "to make the payment",
      },
    },
    staking: {
      page: "Rewards",
      head: {
        viewTitle: "Rewards",
        viewSubTitle: "",
      },
      historyButton: "History",
      noStakingStats: {
        title:
          "You have currently no package in progress, Check our amazing packages and start rewards",
        button: "Buy",
      },
      yesStakingStats: {
        totalLabel: "Total Rewards",
        freeLabel: "Unlocked Rewards",
        lockedLabel: "Locked Rewards",
        buttonBuy: "Buy",
        buttonRedeem: "Redeem",
      },
      stakingPackages: {
        title: "Rewards Packages",
        noStakingpackages: "No Rewards in Progress...",
        stakingCard: {
          labelOne: "Rewards Generated:",
          labelTwo: "Unlocked Rewards:",
          labelThree: "Locked Rewards:",
          dateOfBuy: "Date of Buy:",
          dateOfExpiry: "Date of Completation:",
          days: "Days",
        },
      },
      redeemPopup: {
        title: "Redeem",
        text: "By redeeming the package, a penalty will be applied and the interest rate will drop to 0.25% per day",
        textTwo: "Are you sure you want to redeem this package?",
        buttonYes: "Yes",
        buttonNo: "No",
      },
      swapPopup: {
        title: "Swap",
        total: "Total Amount:",
        amount: "Select amount",
        fee: "Swap Fee:",
        receive: "You will receive:",
        close: "Close",
        swap: "Swap",
      },
    },
    metaverseComing: {
      page: "Metaverse",
      title: "Pulse Metaverse",
      subTitle: "coming soon",
      titleOne: "Metaverse",
      subTitleOne:
        "Explore and travel in Pulse Metaverse in an immersive map and expand your territory.",
      titleTwo: "Concerts and Events",
      subTitleTwo:
        "Attend exclusive events and concerts virtually with the world’s biggest Artists.",
      titleThree: "Brands",
      subTitleThree:
        "Find and engage with your favorite brands and earn token in the process.",
    },
    nftComing: {
      page: "NFT",
      title: "Pulse NFT",
      subTitle: "coming soon",
      titleOne: "NFT Market Place",
      subTitleOne:
        "Discover and search for highly exclusive and valuable NFTs in Pulse Market Place.",
      titleTwo: "Launch Your Own NFT",
      subTitleTwo:
        "Launch your own NFT on the Pulse Market Place in an easy way.",
      titleThree: "NFT Library",
      subTitleThree:
        "Showcase your NFT collection to the Pulse Community and get rewards.",
    },
    socialComing: {
      page: "Social Media",
      title: "Pulse Socials",
      subTitle: "coming soon",
      titleOne: "Branded Channels",
      subTitleOne:
        "Discover and engage with your favorite brands and earn tokens. ",
      titleTwo: "Media Sharing",
      subTitleTwo: "Share photos, videos, interests, and hobbies.",
      titleThree: "Anonymous Messaging",
      subTitleThree:
        "Communicate with friends through messages and video chat.",
    },
    pageNotFound: {
      page: "404",
      title: "Page not found",
      subTitle: "Sorry we can’t found the page that you’re looking for",
    },
    maintenance: {
      page: "Maintenance",
      title: "Site Under Maintenance",
      subTitle: "We will be back soon",
    },
    logout: {
      page: "Logout",
      title: "You have been logged out!",
      button: "Go to Login",
    },
    terms: {
      page: "Terms and Conditions",
      head: {
        viewTitle: "Terms and Conditions",
        viewSubTitle: "",
      },
    },
    privacy: {
      page: "Privacy Policy",
      head: {
        viewTitle: "Privacy Policy",
        viewSubTitle: "",
      },
    },
    disclaimer: {
      page: "Risk Disclaimer",
      head: {
        viewTitle: "Risk Disclaimer",
        viewSubTitle: "",
      },
    },
    faq: {
      head: {
        viewTitle: "Frequently Asked Question",
        viewSubTitle: "",
      },
    },
    generalTable: {
      showing: "Showing ",
      to: " to ",
      of: " of ",
      entries: " entries",
      prev: "Previous",
      next: "Next",
    },
    typeTable: {
      send: "Send",
      sendw: "Send Requested",
      sendwAccepted: "Send Accepted",
      sendwRefused: "Send Refused",
      buyFrom: "Bought From",
      buyFor: "Bought For",
      buy: "Buy",
      swap: "Swap",
      deposit: "Deposit",
    },
    errorStaking: {
      successRedeem: "You have successfully redeemed your package",
      successSwap: "You have successfully swapped your package",
      errorRedeem: "An error has occurred. Please try again later.",
      errorSwap: "An error has occurred. Please try again later.",
    },
  },
  FR: {
    updateProfile: {
      title: "Bonjour,",
      subtitle:
        'Les informations relatives à votre compte ne sont pas à jour. Veillez à les mettre à jour en cliquant sur le bouton "Mettre à jour le profil" ci-dessous.',
    },
    sidebar: {
      dashboard: "Tableau de bord",
      myAsset: "Mon atout",
      wallet: "Portefeuille",
      exchange: "Échange",
      depositHistory: "Historique des dépôts",
      swap: "Échanger",
      myShop: "Ma boutique",
      myStaking: "Mes récompenses",
      metaverse: "Métaverse",
      nft: "NFT",
      legal: "Juridique",
      term: "Termes et conditions",
      privacy: "Privée Politique",
      disclaimer: "Avis de non-responsabilité",
      faq: "FAQ",
      social: "Média social",
      support: "Soutien",
    },
    navbar: {
      profilePopupText: {
        logout: "Déconnexion",
        profile: "Profil",
        recentDevices: "Dispositifs récents",
      },
      notificationPopupText: {
        packagePurchased: "Paquet acheté correctement!",
        packagePurchasedFor: "Paquet acheté correctement pour ",
        packagePurchasedFrom: "Paquet acheté correctement auprès de ",
        deposit: "Dépôt effectué avec succès!",
        sendPusd: "PUSD a envoyé à ",
        sendAw: "AUSD a envoyé à ",
        receivedPusd: "PUSD reçu de ",
        receivedAw: "AUSD a reçu de ",
        swap: "Échange réussi!",
        pusdReceivedFromOmega: "PUSD a reçu",
        awReceivedFromOmega: "AUSD a reçu",
        readAll: "Lire tout",
        noNotifications: "Aucune notification",
        titleActivities: "Dernières activités",
        noActivities: "Aucune activité",
        depositUsdt: "Dépôt effectué avec succès pour USDT",
        giftReceived: "Cadeau reçu!",
        depositPending: "La confirmation de votre dépôt est en cours",
      },
    },
    profile: {
      page: "Profil",
      menu: {
        profile: "Profil",
        recentDevices: "Dispositifs récents",
      },
      basicInformation: {
        title: "Informations de base",
        name: "Nom complet",
        username: "Nom d'utilisateur",
        email: "Courriel",
        phone: "Téléphone",
        country: "Pays",
        city: "Ville",
        zipCode: "Code postal",
        wallet: "Airdrop Portefeuille",
      },
      recentDevices: {
        browser: "Navigateur",
        device: "Dispositifs",
        location: "Localisation",
        ip: "Adresse",
        time: "Horodatage",
      },
    },
    dashboard: {
      page: "Tableau de bord",
      head: {
        viewTitle: "Tableau de bord",
        viewSubTitle: "",
      },
      stats: {
        labelOne: "Marketcap",
        labelTwo: "Prix XPL",
        labelThree: "Total PUSD",
        labelFour: "Total AUSD",
      },
      staking: {
        title: "Aperçu de XPL",
        labelOne: "Total",
        labelTwo: "Primes journalières",
        buttonSwap: "Portefeuille",
        buttonSend: "Récompenses",
      },
      currency: {
        title: "Valeur de chaque devise en USD",
        labelOne: "XPL en USD",
        ofWhat: "du total",
      },
      chart: {
        title: "Aperçu des portefeuilles",
        time: "7 derniers jours",
      },
    },
    wallet: {
      page: "Portefeuille",
      head: {
        viewTitle: "Portefeuille",
        viewSubTitle: "",
      },
      assetCoin: {
        xpl: {
          xTitle: "XPL",
          xAmount: "Montant total",
          xSwapButton: "Échanger",
          xSendButton: "Retirer",
        },
        pusd: {
          pTitle: "PUSD",
          pAmount: "Montant total",
          pDepositButton: "Dépôt",
          pSendButton: "Envoyer",
        },
        aw: {
          aTitle: "AUSD",
          aAmount: "Montant total",
          valueAmount: "AUSD",
          aSendButton: "Envoyer",
        },
        usdt: {
          uTitle: "USDT pour Frais",
          uAmount: "Montant total",
          uButton: "Dépôt",
          uValueAmount: "USDT",
        },
      },
      depositConfirmation: {
        message:
          "Nous traitons votre transaction, nous vous enverrons une notification lorsque le montant sera crédité sur votre compte.",
        button: "Fermer",
      },
      popUp: {
        title: "Sélectionnez un Token",
        title2: "Sélectionnez le montant",
        button: "Confirmer",
        feeText: "Frais de dépôt:",
        btcTime: "Le BTC peut prendre plus de temps que les autres monnaies",
        alert:
          "Le dépôt peut prendre jusqu'à 4 heures. Vous recevrez une notification lorsque le dépôt aura été effectué.",
      },
      chart: {
        title: "Aperçu des portefeuilles",
        labelOne: "Total XPL",
        labelTwo: "Total PUSD",
        labelThree: "Total AUSD",
      },
      table: {
        title: "Histoire",
        labelOne: "ID transaction",
        labelTwo: "Date",
        labelThree: "Montant",
        labelFour: "Type",
      },
    },
    sendXpl: {
      page: "Retirer XPL",
      head: {
        viewTitle: "Retirer XPL",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Retirer XPL",
        inputTo: "Adresse du portefeuille - BSC (BEP20)",
        placeholderTo: "Adresse du portefeuille",
        inputAmount: "Montant à envoyer",
        placeholderAmount: "--",
        yourBalance: "Votre équilibre:",
        youWillReceive: "Frais de retrait:",
        warning:
          "Avertissement : Si vous effectuez un retrait, vous serez affecté par un malus. Vos récompenses journalières sur les paquets déjà achetés baisseront à",
        warningEnd: "par jour",
        button: "Procédez",
        netAmount: "Montant net: ",
      },
      popup: {
        titleSuccess: "Félicitations!",
        subTitleSuccess: "Votre demande a été envoyée!",
        buttonSuccess: "Confirmer",
        buttonClose: "Fermer",
        insufficientBalance:
          "Vous n'avez pas un solde suffisant pour acheter le retirer. Veuillez déposer plus de pièces.",
        error: "Une erreur s'est produite. Veuillez réessayer plus tard.",
      },
    },
    sendAw: {
      page: "Envoyer AUSD",
      head: {
        viewTitle: "Envoyer AUSD",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Envoyer AUSD",
        inputTo: "A",
        placeholderTo: "Nom d'utilisateur",
        inputAmount: "AMontant à envoyer:",
        placeholderAmount: "--",
        yourBalance: "Votre équilibre:",
        button: "Procédez",
      },
      popup: {
        titleSuccess: "Félicitations!",
        subTitleSuccess: "Vos transactions sont terminées!",
        buttonSuccess: "Confirmer",
        buttonClose: "Fermer",
        insufficientBalance:
          "Vous n'avez pas un solde suffisant pour acheter. Veuillez déposer plus de pièces.",
        invalidUsername:
          " Le nom d'utilisateur que vous avez saisi n'est pas valide. Veuillez entrer un nom d'utilisateur valide.",
        error: "Une erreur s'est produite. Veuillez réessayer plus tard.",
      },
    },
    sendPusd: {
      page: "Envoyer PUSD",
      head: {
        viewTitle: "Envoyer PUSD",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Envoyer PUSD",
        inputTo: "A",
        placeholderTo: "Nom d'utilisateur:",
        inputAmount: "Montant à envoyer:",
        placeholderAmount: "--",
        yourBalance: "Votre équilibre:",
        button: "Procédez",
      },
      popup: {
        titleSuccess: "Félicitations!",
        subTitleSuccess: "Vos transactions sont terminées!",
        buttonSuccess: "Confirmer",
        buttonClose: "Fermer",
        insufficientBalance:
          "Vous n'avez pas un solde suffisant pour acheter. Veuillez déposer plus de pièces.",
        invalidUsername:
          " Le nom d'utilisateur que vous avez saisi n'est pas valide. Veuillez entrer un nom d'utilisateur valide.",
        error: "Une erreur s'est produite. Veuillez réessayer plus tard.",
      },
    },
    transaction: {
      page: "Transaction",
      head: {
        viewTitle: "Demande de transaction reçue",
        viewSubTitle: "",
      },
      inProgress: {
        title: "Terminez votre transaction",
        retry: "Si votre écran ne s'est pas ouvert correctement",
        retryButton: "Cliquez ici",
        buttonVerify: "Vérifier",
        subTitle:
          "Si la page permettant d'effectuer la transaction ne s'est pas ouverte correctement, vérifiez que le navigateur n'a pas bloqué la popup.",
        cta: "Retour au portefeuille",
      },
      completed: {
        title: "Félicitations!",
        subTitle: "Vos transactions sont terminées!",
      },
      error: {
        title: "Ops!",
        subTitle:
          "Une erreur semble s'être produite. Terminez votre transaction",
      },
    },
    swap: {
      page: "Échanger",
      head: {
        viewTitle: "Échanger",
        viewSubTitle: "",
      },
      swapBox: {
        title: "Échanger maintenant",
        input: "Entrée ",
        balanceText: "Équilibre:",
        enterAll: "Entrez tous",
        output: "Sortie",
        toAccept: "En participant à Swap, vous acceptez notre",
        legal: "Conditions d'utilisation",
        button: "Échanger",
      },
      confirmSwap: {
        preTitle: "Exchange",
        postTitle: "pour",
        receiveText: "Vous recevrez",
        fromText: "de:",
        buttonReject: "Rejeter",
        buttonAccept: "Confirmer l'échange",
      },
      table: {
        title: "Histoire",
        labelOne: "ID Transaction",
        labelTwo: "Date",
        labelThree: "Montant",
        labelFour: "Type",
      },
      popup: {
        titleSuccess: "Félicitations!",
        subTitleSuccess: "Vos transactions sont terminées!",
        buttonSuccess: "Retour à l'échange",
        buttonClose: "Fermer",
        insufficientBalance:
          "Vous n'avez pas un solde suffisant pour acheter le swap. Veuillez déposer plus de pièces.",
        error: "Une erreur s'est produite. Veuillez réessayer plus tard.",
      },
    },
    depositHistory: {
      page: "Historique des dépôts",
      head: {
        viewTitle: "Historique des dépôts",
        viewSubTitle: "",
      },
      table: {
        title: "L'historique de vos dépôts ",
        alert: "Cliquez sur l'ID de la transaction pour le copier",
        labelOne: "Statut ",
        labelTwo: "ID de commande ",
        labelThree: "ID de transaction ",
        labelFour: "Montant ",
        labelFive: "Créé à ",
      },
    },
    shopList: {
      page: "Ma Boutique",
      head: {
        viewTitle: "Ma boutique",
        viewSubTitle: "",
      },
      seed: {
        timer: {
          title: "Finit dans :",
          days: "Jours",
          hours: "Heures",
          minutes: "Secondes",
        },
        title: "Prix du marché des jetons",
        titleLabel: "Vente privée exclusive d'OmegaPro",
        discount: "Remise",
        pulsePrice: "par PULSE",
        limit: "Limité à",
        cardBuy: {
          title: "Paquets XPL",
          textButton: "Acheter",
          discount: "Remise",
          price: "Prix",
        },
        cardBuyLimited: {
          title: "Offres limitées dans le temps",
          textButton: "Acheter",
          discount: "Remise",
          price: "Prix",
        },
      },
    },
    shopBuy: {
      page: "Ma Boutique",
      head: {
        viewTitle: "Ma Boutique",
        viewSubTitle: "",
      },
      popupConfirm: {
        title: "Félicitations!",
        subTitle: "Bienvenue dans la communauté Pulse",
        button: "Voir vos paquets",
      },
      popupError: {
        insufficientBalance:
          "Votre solde n'est pas suffisant pour acheter ce forfait. Veuillez déposer plus de pièces.",
        invalidUsername:
          " Le nom d'utilisateur que vous avez saisi n'est pas valide. Veuillez entrer un nom d'utilisateur valide.",
        invalidPackage:
          " Le forfait que vous essayez d'acheter n'est pas valide ou épuisé. Veuillez réessayer plus tard.",
        error: "Une erreur s'est produite. Veuillez réessayer plus tard.",
        close: "Fermer",
      },
      formBuy: {
        labelOne: "Pour",
        optionOne: "Tu",
        optionTwo: "Autre utilisateur",
        labelTwo: "Nom d'utilisateur: ",
        placeholderTwo: "Entrez le nom d'utilisateur",
        labelThree: "Sélectionnez la position de dépôt:",
        net: "Rémunération nette par:",
        fee: "Frais:",
        button: "Payez maintenant",
      },
      depositButton: {
        title: "Dépôt",
      },
      timerExpired: {
        header: "Expiré",
        text: "Le délai d'achat du forfait a expiré",
        button: "Aller à la boutique",
        have: "Vous avez encore",
        payment: "pour effectuer le paiement",
      },
    },
    staking: {
      page: "Récompenses",
      head: {
        viewTitle: "Récompenses",
        viewSubTitle: "",
      },
      historyButton: "Histoire",
      noStakingStats: {
        title:
          "Vous n'avez actuellement aucun package en cours, consultez nos incroyables packages et commencez à recevoir des récompenses.",
        button: "Acheter",
      },
      yesStakingStats: {
        totalLabel: "Récompenses totales",
        freeLabel: "Récompenses débloquées",
        lockedLabel: "Récompenses verrouillées",
        buttonBuy: "Acheter",
        buttonRedeem: "Rédiger",
      },
      stakingPackages: {
        title: "Paquets de récompenses",
        noStakingpackages: "Aucune récompense en cours...",
        stakingCard: {
          labelOne: "Récompenses générées:",
          labelTwo: "Récompenses débloquées:",
          labelThree: "Récompenses verrouillées:",
          dateOfBuy: "Date d'achat:",
          dateOfExpiry: "Date d'achèvement:",
          days: "Jours",
        },
      },
      redeemPopup: {
        title: "Rédiger",
        text: "En rachetant le forfait, une pénalité sera appliquée et le taux d'intérêt passera à 0,25 % par jour.",
        textTwo: "Êtes-vous sûr de vouloir racheter ce paquet ?",
        buttonYes: "Oui",
        buttonNo: "Non",
      },
      swapPopup: {
        title: "Échanger",
        total: "Montant total :",
        amount: "Choisir le montant",
        fee: "Frais d'échange :",
        receive: "Vous recevrez :",
        close: "Fermer",
        swap: "Échanger",
      },
    },
    metaverseComing: {
      page: "Métaverse",
      title: "Pulse Métaverse",
      subTitle: "BIENTÔT DISPONIBLE",
      titleOne: "Métaverse",
      subTitleOne:
        "Explorez et voyagez dans Pulse Metaverse dans une carte immersive et élargissez votre territoire.",
      titleTwo: "Concerts et événements",
      subTitleTwo:
        "Assistez virtuellement à des événements et des concerts exclusifs avec les plus grands artistes du monde.",
      titleThree: "Marques",
      subTitleThree:
        "Trouvez et interagissez avec vos marques préférées et gagnez des jetons dans le processus.",
    },
    nftComing: {
      page: "NFT",
      title: "Pulse NFT",
      subTitle: "BIENTÔT DISPONIBLE",
      titleOne: "Place de marché NFT",
      subTitleOne:
        "Découvrez et recherchez des NFT hautement exclusifs et précieux sur Pulse Market Place.",
      titleTwo: "Lancez votre propre NFT",
      subTitleTwo:
        "Lancez votre propre NFT sur Pulse Market Place en toute simplicité.",
      titleThree: "Bibliothèque NFT",
      subTitleThree:
        "Présentez votre collection NFT à la communauté Pulse et obtenez des récompenses",
    },
    socialComing: {
      page: "Média social",
      title: "Pulse Socials",
      subTitle: "BIENTÔT DISPONIBLE",
      titleOne: "Chaînes de marque",
      subTitleOne:
        "Découvrez et interagissez avec vos marques préférées et gagnez des jetons. ",
      titleTwo: "Partage médiatique",
      subTitleTwo:
        "Partagez des photos, des vidéos, des centres d'intérêt et des passe-temps.",
      titleThree: "Messagerie anonyme",
      subTitleThree: "Communiquez avec vos amis par messages et chat vidéo.",
    },
    pageNotFound: {
      page: "404",
      title: "Page non trouvée",
      subTitle: "Désolé, nous n'avons pas trouvé la page que vous recherchez.",
    },
    maintenance: {
      page: "Maintenance",
      title: "Site en cours d'entretien",
      subTitle: "Nous reviendrons bientôt",
    },
    logout: {
      page: "Déconnexion",
      title: "Vous avez été déconnecté!",
      button: "Aller à la connexion",
    },
    terms: {
      page: "Conditions d'utilisation",
      head: {
        viewTitle: "Conditions d'utilisation",
        viewSubTitle: "",
      },
    },
    privacy: {
      page: "Politique de confidentialité",
      head: {
        viewTitle: "Politique de confidentialité",
        viewSubTitle: "",
      },
    },
    disclaimer: {
      page: "Avis de non-responsabilité",
      head: {
        viewTitle: "Avis de non-responsabilité",
        viewSubTitle: "",
      },
    },
    faq: {
      head: {
        viewTitle: "Questions fréquemment posées",
        viewSubTitle: "",
      },
    },
    generalTable: {
      showing: "Affichage de ",
      to: " à ",
      of: " des ",
      entries: " entrées",
      prev: "Précédent",
      next: "Suivant",
    },
    typeTable: {
      send: "Envoyer",
      sendw: "Envoi demandé",
      sendwAccepted: "Envoyer Accepté",
      sendwRefused: "Envoi Refusé",
      buyFrom: "Acheté à",
      buyFor: "Acheté pour",
      buy: "Acheter",
      swap: "Échanger",
      deposit: "Dépôt",
    },
    errorStaking: {
      successRedeem: "Vous avez échangé votre paquet avec succès",
      successSwap: "Vous avez réussi à échanger votre paquet",
      errorRedeem: "Une erreur s'est produite. Veuillez réessayer plus tard.",
      errorSwap: "Une erreur s'est produite. Veuillez réessayer plus tard.",
    },
  },
  ES: {
    updateProfile: {
      title: "Hola,",
      subtitle:
        'La información de su cuenta no está actualizada, asegúrese de actualizarla pulsando el botón "Actualizar perfil" que aparece a continuación.',
    },
    sidebar: {
      dashboard: "Tablero",
      myAsset: "Mi activo",
      wallet: "Cartera",
      exchange: "Intercambio",
      depositHistory: "Historial de depósitos",
      swap: "Intercambiar",
      myShop: "Mi tienda",
      myStaking: "Mis recompensas",
      metaverse: "Metaverso",
      nft: "NFT",
      legal: "Jurídico",
      term: "Condiciones generales",
      privacy: "Política de privacidad",
      disclaimer: "Aviso de riesgo",
      faq: "FAQ",
      social: "Medios sociales",
      support: "Soporte",
    },
    navbar: {
      profilePopupText: {
        logout: "Cerrar sesión",
        profile: "Perfil",
        recentDevices: "Dispositivos recientes",
      },
      notificationPopupText: {
        packagePurchased: "¡Paquete comprado correctamente!",
        packagePurchasedFor: "Paquete comprado correctamente para ",
        packagePurchasedFrom: "Paquete comprado correctamente en ",
        deposit: "¡Depósito realizado con éxito!",
        sendPusd: "PUSD enviado a ",
        sendAw: "AUSD envió a ",
        receivedPusd: "PUSD recibido de ",
        receivedAw: "AUSD recibió de ",
        swap: "¡Intercambio realizado con éxito!",
        pusdReceivedFromOmega: "El PUSD recibió",
        awReceivedFromOmega: "AUSD recibió",
        readAll: "Leer todo",
        noNotifications: "No hay notificaciones",
        titleActivities: "Últimas actividades",
        noActivities: "No hay actividades",
        depositUsdt: "Depósito realizado con éxito para USDT",
        giftReceived: "¡Regalo recibido!",
        depositPending: "La confirmación de su depósito está en curso",
      },
    },
    profile: {
      page: "Perfil",
      menu: {
        profile: "Perfil",
        recentDevices: "Dispositivos recientes",
      },
      basicInformation: {
        title: "Información básica",
        name: "Nombre completo",
        username: "Nombre de usuario",
        email: "Correo electrónico",
        phone: "Teléfono",
        country: "País",
        city: "Ciudad",
        zipCode: "Código postal",
        wallet: "Airdrop Cartera",
      },
      recentDevices: {
        browser: "Navegador",
        device: "Dispositivos",
        location: "Ubicación",
        ip: "Dirección",
        time: "Sello de tiempo",
      },
    },
    dashboard: {
      page: "Tablero",
      head: {
        viewTitle: "Tablero",
        viewSubTitle: "",
      },
      stats: {
        labelOne: "Marketcap",
        labelTwo: "Precio de XPL",
        labelThree: "Total PUSD",
        labelFour: "Total AUSD",
      },
      staking: {
        title: "Visión general de XPL",
        labelOne: "Total",
        labelTwo: "Recompensas diarias",
        buttonSwap: "Cartera",
        buttonSend: "Recompensas",
      },
      currency: {
        title: "Valor de cada moneda en USD",
        labelOne: "XPL en USD",
        ofWhat: "del total",
      },
      chart: {
        title: "Visión general de la cartera",
        time: "Últimos 7 días",
      },
    },
    wallet: {
      page: "Cartera",
      head: {
        viewTitle: "Cartera",
        viewSubTitle: "",
      },
      assetCoin: {
        xpl: {
          xTitle: "XPL",
          xAmount: "Importe total",
          xSwapButton: "Intercambiar",
          xSendButton: "Retirar",
        },
        pusd: {
          pTitle: "PUSD",
          pAmount: "Importe total",
          pDepositButton: "Depósito",
          pSendButton: "Enviar",
        },
        aw: {
          aTitle: "AUSD",
          aAmount: "Importe total",
          valueAmount: "AUSD",
          aSendButton: "Enviar",
        },
        usdt: {
          uTitle: "USDT para la Tasa",
          uAmount: "Importe total",
          uButton: "Depósito",
          uValueAmount: "USDT",
        },
      },
      depositConfirmation: {
        message:
          "Estamos procesando su transacción, le enviaremos una notificación cuando el importe se acredite en su cuenta.",
        button: "Cerrar",
      },
      popUp: {
        title: "Seleccione una ficha",
        title2: "Seleccione el importe",
        button: "Confirmar",
        feeText: "Tasa de depósito:",
        btcTime: "BTC puede tardar más que otras monedas",
        alert:
          "El depósito puede tardar hasta 4 horas. Recibirás una notificación cuando se haya realizado el depósito.",
      },
      chart: {
        title: "Visión general de la cartera",
        labelOne: "Total XPL",
        labelTwo: "Total PUSD",
        labelThree: "Total AUSD",
      },
      table: {
        title: "Historia",
        labelOne: "ID Transacción",
        labelTwo: "Fecha",
        labelThree: "Importe",
        labelFour: "Tipo",
      },
    },
    sendXpl: {
      page: "Retirar",
      head: {
        viewTitle: "Retirar XPL",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Retirar XPL",
        inputTo: "Dirección de la cartera - BSC (BEP20)",
        placeholderTo: "Dirección de la cartera",
        inputAmount: "Cantidad a enviar",
        placeholderAmount: "--",
        yourBalance: "Su saldo:",
        youWillReceive: "Tasa de retirada:",
        warning:
          "Warning: Si realiza una retirada, se verá afectado por un malus. Sus recompensas diarias en paquetes ya comprados bajarán a",
        warningEnd: "por día",
        button: "Proceder",
        netAmount: "Importe neto: ",
      },
      popup: {
        titleSuccess: "Enhorabuena!",
        subTitleSuccess: "Su solicitud ha sido enviada.",
        buttonSuccess: "Confirmar",
        buttonClose: "Cerrar",
        insufficientBalance:
          "No tiene saldo suficiente para comprar el retirada. Por favor, deposite más monedas.",
        error: "Se ha producido un error. Por favor, inténtelo más tarde.",
      },
    },
    sendAw: {
      page: "Enviar AUSD",
      head: {
        viewTitle: "Enviar AUSD",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Enviar AUSD",
        inputTo: "A",
        placeholderTo: "Nombre de usuario",
        inputAmount: "Cantidad a enviar:",
        placeholderAmount: "--",
        yourBalance: "Su saldo:",
        button: "Procede",
      },
      popup: {
        titleSuccess: "Enhorabuena!",
        subTitleSuccess: "Su transacción se ha completado.",
        buttonSuccess: "Confirmar",
        buttonClose: "Cerrar",
        insufficientBalance:
          "No tiene saldo suficiente para comprar. Por favor, deposite más monedas.",
        invalidUsername:
          " El nombre de usuario que has introducido no es válido. Por favor, introduzca un nombre de usuario válido.",
        error: "Se ha producido un error. Por favor, inténtelo más tarde.",
      },
    },
    sendPusd: {
      page: "Enviar PUSD",
      head: {
        viewTitle: "Enviar PUSD",
        viewSubTitle: "",
      },
      sendBox: {
        title: "Enviar PUSD",
        inputTo: "A",
        placeholderTo: "Nombre de usuario",
        inputAmount: "Cantidad a enviar:",
        placeholderAmount: "--",
        yourBalance: "Su saldo:",
        button: "Proceder",
      },
      popup: {
        titleSuccess: "Enhorabuena!",
        subTitleSuccess: "Su transacción se ha completado.",
        buttonSuccess: "Confirmar",
        buttonClose: "Cerrar",
        insufficientBalance:
          "No tiene saldo suficiente para comprar. Por favor, deposite más monedas.",
        invalidUsername:
          " El nombre de usuario que has introducido no es válido. Por favor, introduzca un nombre de usuario válido.",
        error: "Se ha producido un error. Por favor, inténtelo más tarde.",
      },
    },
    transaction: {
      page: "Transacción",
      head: {
        viewTitle: "Solicitud de transacción recibida",
        viewSubTitle: "",
      },
      inProgress: {
        title: "Complete su transacción",
        retry: "Si su pantalla no se ha abierto correctamente",
        retryButton: "Haga clic aquí",
        buttonVerify: "Verificar",
        subTitle:
          "Si la página para realizar la transacción no se ha abierto correctamente, compruebe que el navegador no ha bloqueado la ventana emergente.",
        cta: "Volver a la cartera",
      },
      completed: {
        title: "Enhorabuena!",
        subTitle: "Su transacción se ha completado.",
      },
      error: {
        title: "Ops!",
        subTitle:
          "Parece que se ha producido un error. Complete su transacción",
      },
    },
    swap: {
      page: "Intercambiar",
      head: {
        viewTitle: "Intercambiar",
        viewSubTitle: "",
      },
      swapBox: {
        title: "Intercambiar ahora",
        input: "Entrada",
        balanceText: "Saldo:",
        enterAll: "Introduzca todos",
        output: "Salida",
        toAccept: "Al hacer el intercambio, tu acepta nuestra",
        legal: "Condiciones generales",
        button: "Intercambiar",
      },
      confirmSwap: {
        preTitle: "Intercambio",
        postTitle: "para",
        receiveText: "Tu recibirá",
        fromText: "de:",
        buttonReject: "Rechazar",
        buttonAccept: "Confirmar intercambio",
      },
      table: {
        title: "Historia",
        labelOne: "ID Transacción",
        labelTwo: "Fecha",
        labelThree: "Importe",
        labelFour: "Tipo",
      },
      popup: {
        titleSuccess: "Enhorabuena!",
        subTitleSuccess: "Su transacción se ha completado.",
        buttonSuccess: "Volver al intercambio",
        buttonClose: "Cerrar",
        insufficientBalance:
          "No tiene saldo suficiente para comprar el canje. Por favor, deposite más monedas.",
        error: "Se ha producido un error. Por favor, inténtelo más tarde.",
      },
    },
    depositHistory: {
      page: "Historial de depósitos",
      head: {
        viewTitle: "Historial de depósitos",
        viewSubTitle: "",
      },
      table: {
        title: "Tu historial de depósitos",
        alert: "Haga clic en el ID de la transacción para copiarlo",
        labelOne: "Estado",
        labelTwo: "ID de la orden",
        labelThree: "ID de la transacción",
        labelFour: "Importe",
        labelFive: "Creado en",
      },
    },
    shopList: {
      page: "Mi Tienda",
      head: {
        viewTitle: "Mi tienda",
        viewSubTitle: "",
      },
      seed: {
        timer: {
          title: "Termina en:",
          days: "Días",
          hours: "Horas",
          minutes: "Actas",
        },
        title: "Precio del mercado de tokens",
        titleLabel: "Venta privada exclusiva de OmegaPro",
        discount: "Descuento",
        pulsePrice: "por PULSE",
        limit: "Limitado a",
        cardBuy: {
          title: "Paquetes XPL",
          textButton: "Comprar",
          discount: "Descuento",
          price: "Precio",
        },
        cardBuyLimited: {
          title: "Ofertas por tiempo limitado",
          textButton: "Comprar",
          discount: "Descuento",
          price: "Precio",
        },
      },
    },
    shopBuy: {
      page: "Mi Tienda",
      head: {
        viewTitle: "Mi tienda",
        viewSubTitle: "",
      },
      popupConfirm: {
        title: "Enhorabuena!",
        subTitle: "Bienvenido a la comunidad Pulse",
        button: "Vea sus paquetes",
      },
      popupError: {
        insufficientBalance:
          "No tiene suficiente saldo para comprar este paquete. Por favor, deposite más monedas.",
        invalidUsername:
          " El nombre de usuario que has introducido no es válido. Por favor, introduzca un nombre de usuario válido.",
        invalidPackage:
          " El paquete que intenta comprar no es válido o está agotado. Por favor, inténtelo de nuevo más tarde.",
        error: "Se ha producido un error. Por favor, inténtelo más tarde.",
        close: "Cerrar",
      },
      formBuy: {
        labelOne: "Para",
        optionOne: "Tu",
        optionTwo: "Otro usuario",
        labelTwo: "Nombre de usuario ",
        placeholderTwo: "Introduzca el nombre de usuario",
        labelThree: "Seleccione la posición del depósito:",
        net: "Pago neto por:",
        fee: "Tasa:",
        button: "Pagar ahora",
      },
      depositButton: {
        title: "Depósito",
      },
      timerExpired: {
        header: "Expirado",
        text: "El plazo para comprar el paquete ha expirado",
        button: "Ir a la tienda",
        have: "Aún dispone de",
        payment: "para efectuar el pago",
      },
    },
    staking: {
      page: "Recompensas",
      head: {
        viewTitle: "Recompensas",
        viewSubTitle: "",
      },
      historyButton: "Historia",
      noStakingStats: {
        title:
          "Actualmente no tiene ningún paquete en curso, compruebe nuestros increíbles paquetes y empiece a recibir recompensas",
        button: "Comprar",
      },
      yesStakingStats: {
        totalLabel: "Total de recompensas",
        freeLabel: "Recompensas desbloqueadas",
        lockedLabel: "Recompensas bloqueadas",
        buttonBuy: "Comprar",
        buttonRedeem: "Canjee",
      },
      stakingPackages: {
        title: "Paquetes de recompensas",
        noStakingpackages: "No hay recompensas en el progreso...",
        stakingCard: {
          labelOne: "Recompensas generadas:",
          labelTwo: "Recompensas desbloqueadas:",
          labelThree: "Recompensas bloqueadas",
          dateOfBuy: "Fecha de compra:",
          dateOfExpiry: "Fecha de terminación:",
          days: "Días",
        },
      },
      redeemPopup: {
        title: "Canjee",
        text: "Al rescatar el paquete, se aplicará una penalización y el tipo de interés bajará al 0,25% diario",
        textTwo: "¿Está seguro de que quiere canjear este paquete?",
        buttonYes: "Sí",
        buttonNo: "No",
      },
      swapPopup: {
        title: "Intercambiar",
        total: "Importe total:",
        amount: "Seleccione el importe",
        fee: "Tasa de intercambio:",
        receive: "Usted recibirá:",
        close: "Cerrar",
        swap: "Intercambiar",
      },
    },
    metaverseComing: {
      page: "Metaverso",
      title: "Pulse Metaverso",
      subTitle: "PRÓXIMAMENTE, EN BREVE, PRONTO",
      titleOne: "Metaverso",
      subTitleOne:
        "Explore y viaje en Pulse Metaverse en un mapa inmersivo y expanda su territorio.",
      titleTwo: "Conciertos y Eventos",
      subTitleTwo:
        "Asiste a eventos y conciertos exclusivos de forma virtual con los artistas más importantes del mundo.",
      titleThree: "Marcas",
      subTitleThree:
        "Encuentre e interactúe con sus marcas favoritas y gane fichas en el proceso.",
    },
    nftComing: {
      page: "NFT",
      title: "Pulse NFT",
      subTitle: "PRÓXIMAMENTE, EN BREVE, PRONTO",
      titleOne: "Mercado de NFT",
      subTitleOne:
        "Descubra y busque NFT altamente exclusivos y valiosos en Pulse Market Place.",
      titleTwo: "Lance su propio NFT",
      subTitleTwo:
        "Lance su propio NFT en Pulse Market Place de una manera fácil.",
      titleThree: "Biblioteca NFT",
      subTitleThree:
        "Muestre su colección NFT a la comunidad Pulse y obtenga recompensas",
    },
    socialComing: {
      page: "Medios sociales",
      title: "Pulse Socials",
      subTitle: "PRÓXIMAMENTE, EN BREVE, PRONTO",
      titleOne: "Canales de marca",
      subTitleOne:
        "Descubra e interactúe con sus marcas favoritas y gane tokens.",
      titleTwo: "Uso compartido de medios",
      subTitleTwo: "Comparte fotos, videos, intereses y pasatiempos.",
      titleThree: "Mensajería anónima",
      subTitleThree:
        "Comuníquese con amigos a través de mensajes y chat de video.",
    },
    pageNotFound: {
      page: "404",
      title: "Página no encontrada",
      subTitle: "Lo sentimos, no podemos encontrar la página que está buscando",
    },
    maintenance: {
      page: "Mantenimiento",
      title: "Sitio en mantenimiento",
      subTitle: "Volveremos pronto",
    },
    logout: {
      page: "Cerrar la sesión",
      title: "Se ha cerrado la sesión.",
      button: "Ir a Login",
    },
    terms: {
      page: "Condiciones generales",
      head: {
        viewTitle: "Condiciones generales",
        viewSubTitle: "",
      },
    },
    privacy: {
      page: "Política de privacidad",
      head: {
        viewTitle: "Política de privacidad",
        viewSubTitle: "",
      },
    },
    disclaimer: {
      page: "Aviso de riesgo",
      head: {
        viewTitle: "Aviso de riesgo",
        viewSubTitle: "",
      },
    },
    faq: {
      head: {
        viewTitle: "Preguntas frecuentes",
        viewSubTitle: "",
      },
    },
    generalTable: {
      showing: "Mostrando ",
      to: " a ",
      of: " de ",
      entries: " entradas",
      prev: "Anteriormente",
      next: "Siguiente",
    },
    typeTable: {
      send: "Enviar",
      sendw: "Envío solicitado",
      sendwAccepted: "Envío aceptado",
      sendwRefused: "Enviar rechazado",
      buyFrom: "Comprado en",
      buyFor: "Comprado para",
      buy: "Comprar",
      swap: "Intercambiar",
      deposit: "Depósito",
    },
    errorStaking: {
      successRedeem: "Ha canjeado correctamente su paquete",
      successSwap: "Ha intercambiado con éxito su paquete",
      errorRedeem: "Se ha producido un error. Por favor, inténtelo más tarde.",
      errorSwap: "Se ha producido un error. Por favor, inténtelo más tarde.",
    },
  },
};

export default data;
