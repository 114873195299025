import React from 'react'

// Styles
import Style from './Menu.module.css'

// Icons
import { /* TbLayoutList, */ TbLayoutCards } from 'react-icons/tb'

const Menu = ({ tab, setTab, profile, recentDevices }) => {
    return (
        <section className={Style.menu}>
            <ul>
                <li
                    className={tab === "profile" ? Style.activeTab : undefined}
                    onClick={() => setTab("profile")}>
                    <TbLayoutCards />
                    <div style={{ width: 55 }}>
                        {profile}
                    </div>
                </li>
                {/* <li
                    className={tab === "recentDevices" ? Style.activeTab : undefined}
                    onClick={() => setTab("recentDevices")}>
                    <TbLayoutList />
                    <div style={{ width: 126 }}>
                        {recentDevices}
                    </div>
                </li> */}
            </ul>
        </section>
    )
}

export default Menu