import React, { useState, useEffect } from "react";

//Libraries
import { Container } from "@hybris-software/ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";

// Components
import Banner from "./Components/Banner/Banner";
import BasicInformation from "./Components/BasicInformation/BasicInformation";
import Menu from "./Components/Menu/Menu";
import RecentDevices from "./Components/RecentDevices/RecentDevices";
import Loader from "../../Components/Commons/Loader/Loader";

// Hooks
import useText from "../../Hooks/useText";


const Profile = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const texts = useText("profile");
  const { menu, basicInformation, recentDevices, page } = texts;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const [tab, setTab] = useState();

  const profileApi = useQuery({
    url: "auth/user/",
    method: "GET",
    executeImmediately: true,
  });

  useEffect(() => {
    setTab(
      location.state && location.state.tab ? location.state.tab : "profile"
    );
  }, [location]);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={profileApi.isLoading}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        {profileApi.response && (
          <Banner data={profileApi.response.data} />
        )}
        <Menu tab={tab} setTab={setTab} {...menu} />
        {tab === "profile" && profileApi.response && (
          <BasicInformation
            {...basicInformation}
            data={profileApi.response.data}
          />
        )}
        {tab === "recentDevices" && <RecentDevices {...recentDevices} />}
      </Container>
    </AuthRoute>
  );
};

export default Profile;
