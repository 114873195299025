import React from 'react';

// Libraries
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "@hybris-software/ui-kit";
import { ApiProvider, generateApiClient } from '@hybris-software/use-query';
import { AuthProvider } from '@hybris-software/use-auth';

// Styles
import './Assets/css/index.css';
import Theme from './Assets/css/Theme.module.css';

// Data
import Config from './Data/config';

// App.js
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const apiClient = generateApiClient({
  baseUrl: Config.API_BASE_URL,
  authorizationHeader: "Authorization",
  authorizationPrefix: "Token ",
  timeout: 10000,
});


root.render(
  <ThemeProvider theme={{
    container: {
      // containerClassName: Theme.containerClass,
    },
    row: {
      columnGap: {
        horizontal: {
          xs: 30,
          sm: 30,
          md: 30,
          lg: 30,
          xl: 30,
          xxl: 30,
        },
        vertical: {
          xs: 50,
          sm: 50,
          md: 50,
          lg: 50,
          xl: 50,
          xxl: 50,
        }
      },
    },
    inputField: {
      baseClassName: Theme.inputFieldStyle,
      errorClassName: Theme.inputFieldErrorStyle,
      successClassName: Theme.inputFieldSuccessStyle,
      labelClassName: Theme.inputFieldLabelStyle,
    },
    button: {
      buttonClassName: Theme.buttonClass,
      buttonDisabledClassName: Theme.buttonDisabledClass,
      loader: <div className={Theme.buttonLoader}></div>,
    },
  }}>
    <ApiProvider apiClient={apiClient}>
      <AuthProvider apiClient={apiClient} authUrl="auth/user/">
        <App />
      </AuthProvider>
    </ApiProvider>
  </ThemeProvider>
);

