import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Icons
import { HiArrowRight } from "react-icons/hi";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./Balance.module.css";

const Balance = ({ data, tokenPrice }) => {
  const navigate = useNavigate();

  const texts = useText("dashboard");
  const { title, buttonSwap, buttonSend, labelOne, labelTwo } = texts.staking;

  return (
    <aside className={Style.balance}>
      <h5 className={Style.titlePrincipal}>{title}</h5>
      <div className={Style.grid}>
        <div className={Style.leftSide}>
          <div className={Style.myBalance}>
            <div className={Style.title}>{labelOne}</div>
            <div className={Style.number}>{data.totalXpl} XPL</div>
            <div className={Style.amount}>$ {(data.totalXpl * tokenPrice).toFixed(4)}</div>
          </div>

          <div className={Style.separated}></div>

          <div className={Style.asset}>
            <div className={Style.title}>{labelTwo}</div>
            <div className={Style.number}>{data.xplRewards} XPL</div>
            <div className={Style.amount}>$ {(data.xplRewards * tokenPrice).toFixed(4)}</div>
          </div>
        </div>

        <div className={Style.buttons}>
          <Button
            className={Style.button}
            onClick={() => navigate("/asset/wallet")}
          >
            {buttonSwap}
            <HiArrowRight />
          </Button>
          <Button
            className={Style.button}
            onClick={() => navigate("/my-rewards")}
          >
            {buttonSend}
            <HiArrowRight />
          </Button>
        </div>
      </div>
    </aside>
  );
};

export default Balance;
