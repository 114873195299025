import React from 'react'

// Libraire
import { Link } from 'react-router-dom'

//Style
import Style from './TermBox.module.css'

const TermBox = () => {
    return (
        <section className={Style.terms}>
            <div className={Style.title}>
                <h4>PULSE WORLD</h4>
                <div className={Style.date}><em>Last Updated: 27 October 2022</em></div>
            </div>
            <div className={Style.scrollable}>

                <p>This Privacy Policy ("Policy") governs the access to and use of all parts of the Website, Platform and Services branded as Pulse World ("Pulse", "We", "Us", "Our") and referring to the domain app.pulseworld.com
                    (hereinafter referred as the "Platform") and all documents, data, materials or other information made available on the Platform.</p>

                <p>This Policy applies to all visitors and/or users ("User", "you", "your") who act in their own personal capacity. Please read this Policy along with our <Link to="/legal/terms-and-condition">Terms of Use</Link> carefully, and note that by using Pulse World, you are consenting to the collection, storage, processing and transfer of your personal information as described in this Policy.</p>

                <p>This Policy may be made available in several languages; all versions are legally binding, but in the event of inconsistency between the English version and a translated version, the English version prevails.</p>

                <h5>1. DEFINITIONS</h5>

                <p><strong>"Personal data"</strong>means any information referring to an identified or identifiable natural and/or legal person</p>

                <p><strong>"Document(s)"</strong>  includes any medium in which data is recorded, whether printed, or on tape, or film or by electronic means, or otherwise; map, diagram, photograph, film, microfilm, video-tape, sound recording, or machine-readable record; any record which is capable of being produced from a machine-readable record by means of equipment or a programme, or a combination of both, or any equipment or a programme, or a combination of both, and is used for that purpose by the public body or private body which holds the record;</p>

                <h5>2. ACCEPTANCE</h5>

                <p>By accessing and using our Platform or our services you are consenting and accepting the terms of this Policy. If you do not agree or consent with the details we require with any aspect of this Policy, you shall at any time withdraw your consent and discontinue access or use of our services.</p>

                <h5>3. THE PERSONAL WE COLLECTS FROM USERS</h5>

                <p>We may collect different information from you such as your personal details, wallet address and other information upon your confirmation and approval. In addition, we may collect information via Cookies and Automatically collected information through the browser.</p>

                <h5>4. WHY USERS’ PERSONAL DATA IS USED?</h5>

                <p>We may use your Personal data to improve our services; to enforce our terms in our Terms of Use; to provide customer services; to ensure network and information security; to facilitate corporate acquisitions, mergers or transactions; to engage in marketing activities; for transaction services via credit/debit cards.</p>

                <h5>5. DATA TRANSFER</h5>

                <p>Except as described in this Policy, we will not disclose any of your information and we shall only share information in the following circumstances:</p>

                <ul className={Style.point}>
                    <li>We may share your Personal data if required by law or good-faith that such actions are necessary to comply with state laws, or in a need of a litigation process, judicial or other government warrant or to cooperate with applicable law enforcements or regulatory authorities.</li>
                    <li>We may share your Personal data with affiliates, selected third parties, including business suppliers, distributors and subcontractors, and/or outsourced service providers when it is necessary for the delivery of our services. These third parties may have access to or process information about you as part of providing services for us. Please note that the aforementioned third parties only collect, use and disclose your information in the ways indicated by us in order to provide adequate services.</li>
                    <li>We may share or transfer your Personal data to an acquirer, successor, or assignee as part of any merger, acquisition, debt financing, sale of assets, or similar transaction, or in the event of an insolvency, bankruptcy, or receivership in which such Personal data is transferred to one or more third parties as one of our business assets.</li>
                    <li>We may share your Personal data with our service providers under contracts to operate the business. For instances;
                        <ul className={Style.number}>
                            <li>Cloud storage</li>
                            <li>Payment process</li>
                            <li>Transaction Monitoring</li>
                            <li>Network Infrastructure</li>
                            <li>Security</li>
                            <li>Customer Support</li>
                            <li>Marketing</li>
                            <li>Data analytics</li>
                        </ul>
                    </li>
                    <li>We may share your Personal data with the financial Institution which we partner to process payments you have authorized</li>
                </ul>

                <p>Pulse World ensures that the service providers and affiliates/ business partners who process your Personal data or information, shall acknowledge the confidentiality of this information and to protect the User’s right to privacy by complying with all applicable privacy and data protection laws.</p>

                <h5>6. PROTECTION AND STORAGE OF PERSONAL DATA</h5>

                <p>Pulse World maintains physical, technical, administrative and procedural safeguards in connection with the collections, storage and disclosure of User’s Personal data. We use firewall barriers, encryption techniques and authentication procedures to maintain the security of User’s online sessions.</p>

                <h5>7. COOKIE POLICY</h5>

                <p>Cookies are small text files that are placed on your device like computer, mobile or any other device by a website, containing details of your browsing history. In simple terms it is explained as that it contains information about your browsing activity. Whenever you visit our Platform, the website sends a cookie to the device you are using to access our Platform. Your device automatically stores the Cookie in a file that’s located within your website browser.</p>

                <p>Cookies allow our Platform to recognize your device such as; when you revisit a site, the website will respond in a more personalized way, remembering your preferences, and it will make your page load faster. Cookies set by our operators are called "First party cookies". Cookies set by parties other than our platforms are called "Third party cookies".  Third party cookies enable third party features or functionality to be provided on or through the website for instance such as advertising, interactive content and analytics.</p>

                <p>We use the following Cookies to operate our Platform, such as;</p>

                <ul className={Style.point}>
                    <li>
                        <strong>Session Cookies</strong> are temporary and expire once you close your browser. It stores information for the time, or session when you are on our Platform.
                    </li>
                    <li><strong>Persistent Cookies </strong> encompasses all Cookies that remain on your hard drive until you erase or your browser does it. It depends on the Cookie’s expiration date. All persistent cookies have an expiration date written into their code, but their duration can vary.</li>
                    <li><strong>First-party-cookies</strong> are put on your device directly by our Platform you are visiting.</li>
                    <li><strong>Third-party-cookies</strong>are placed on your device, not by our Platform, but by a third party like an advertiser or an analytic system. Generally, the Third-Party Cookies consist of tracking Cookies used to identify online behavior, understand interests and then customize advertising the User on other websites.  Any information obtained from Third-Party Cookies is processed by the respective Collaborative Partners.</li>
                    <li><strong>Google Analytics Cookies</strong> to measure our User’s interactions with the content on our Platform. These Cookies collect information about your interactions with our Platform, such as unique visits, repeat visits, session duration and website activity;</li>
                    <li><strong>Facebook Pixel</strong> to process information about a User’s activity on our Platform, such as the website visited, Facebook identity, browser data, and more. The information processed from Facebook pixel is used to serve ads based on your interests through Facebook, as well as to measure cross-device conversions and User interactions on our Platform.</li>
                    <li><strong>Essential Cookies</strong> are known as strictly necessary Cookies for you to browse our Platform and use the features of it, such as accessing secure areas of our Platform. These Cookies are generally First-Party Session Cookies. These Cookies do not require consent from you. Essential Cookies helps to make our Platform easy to use by providing basic functions such as page navigation, language selections, authorization and filling in forms. Our Platform will not function without these Cookies and they cannot be disabled. These Cookies do not reveal the User’s identity or collect information. They are stored on the User’s device until the browser is closed.</li>
                    <li><strong>Functionality Cookies</strong> allow our Platform to remember choices you have made in the past like what language you prefer, what region you would like or what username and password are so you can automatically log in. We use Cookies for navigation and to facilitate your access to and use of this site. These Cookies are necessary for transmission of communications on the network or to supply services whatever requested by you. Further they help us to know which pages are the most and least popular and see how visitors move around the website. We use Third-Party Cookies, which are set on your device for up to two (2) years or deleted, if you erase your browser cookie data.</li>
                    <li><strong>Performance Cookies</strong> collect information about how you use our Platform, like which page you visited and which link you clicked on. None of this type of information can be used to identify you. The sole purpose of performance cookies is to improve our Platform functions. Analytics may collect information through log data, such as; (i) IP address, (ii) Type of browser and device, (iii) Operating system, (iv) Name of the Internet Service Provider, (v) Country information, (vi) Date and time of visit, (vii) Web page origin and exit.</li>
                    <li><strong>Marketing Cookies</strong> track your online activity to help advertisers deliver more relevant advertising or to limit how many times you see and ad. These Cookies can share information with other organizations or advertisers. However, these are Persistent Cookies which are third-party-provenance. These cookies may be set through the website by our advertising partners. If you do not allow these cookies, you will experience less targeted advertising. These cookies are set on your device for up to two (2) years or deleted, if you erase your browser cookie data.</li>

                    <p>You reserve the right to decide whether to accept or reject Cookies from our Platform. You can exercise your Cookie rights by setting your preferences in the Cookie Consent Manager. However, you will not be able to reject the Essential Cookies, as they are strictly necessary to provide you with our Services. In the event you choose to reject Cookies, you may still use our Platform though your access to some functionality and areas of our Platform may be restricted. You may also set or amend your web browser controls to accept or refuse Cookies. Be conscious of rejecting or disabling Cookies, where it may affect the functionality of our Platform. Therefore, it is recommended that you do not disable cookies. </p>
                </ul>

                <h5>8. DATA PROTECTIONS</h5>

                <p>We ensure to take certain physical and technical safeguards to protect your Personal data that we collect and maintain. However, please note and be aware that there are no security measures which are accurate and perfect or impenetrable. Under certain circumstances we do not guarantee that Personal data of yours will not be accessed, disclosed, or amended or destroyed by breach of any of our physical and technical safeguards.  If you choose to use our Platform from different jurisdictions/regions of the world with laws governing data collection, then please note that you are transferring your Personal data outside of those jurisdictions/regions for storage and processing purposes.</p>

                <h5>9. PRIVACY TERMS IN USING CRYPTOCURRENCY AND BLOCKCHAIN</h5>

                <p>The cryptocurrencies are recorded on a public blockchain.  It is a ledger which means a chain of blocks where each block contains the recording of any data transmission. Blockchains are decentralized or third-party networks which are not controlled or operated by any third-party service provider or affiliates or business partners of <strong>Pulse World</strong>, and we have no access or authority to erase, modify or alter any personal data from such networks.</p>

                <h5>10. MODIFICATIONS</h5>

                <p>We reserve the right, at our sole discretion, to modify, amend, supplement or replace this Policy at any time and such update details shall be indicated at the top of this page. We will review and revise this Policy when there are any material circumstances arisen that may affect our ability to apply this Policy pursuant to the provisions of statutory and law requirements. What constitutes a material change will be determined at our sole discretion.</p>

                <p>We will inform our Users on all material amendments to this Policy by publishing the updated version of this Policy on our Platform. When an updated version of this Policy is released, your continued access to the Platform means that you agree to the updated content and you abide by the updated Policy.</p>

                <h5>11. CONTACT INFORMATION</h5>

                <p>In the event of any comments, questions, inquiries or complaints regarding this Policy, the User has the right to submit questions and/or concerns at <a href="mailto:info@pulseworld.com">info@pulseworld.com</a>.</p>

            </div>
        </section>
    )
}

export default TermBox