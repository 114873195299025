import React, { useEffect, useState } from "react";
//Components
import LoaderGlobal from "../../../Components/Commons/LoaderGlobal/LoaderGlobal";

//Libraires
import { Button, InputField } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { AuthRoute } from "@hybris-software/use-auth";

//Images
import Logo from "../../../Assets/images/logos/logo-pulse-full.png";

//Styles
import Style from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "PULSE WORLD | Forgot Password";
  }, []);

  const form = useForm({
    inputs: {
      username: {
        required: true,
        errorOnEveryChange: true,
      },
    },
  });

  const forgotApi = useQuery({
    url: "auth/password-reset/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      setMessage(
        "Check your email, we have sent you a link to reset your password."
      );
    },
    onError: (error) => {},
  });

  return (
    <AuthRoute
      loader={<LoaderGlobal />}
      minimumLoadingTime={0}
      forLoggedUser={false}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <section className={Style.login}>
        <div className={Style.inner}>
          <img src={Logo} alt="Logo" />

          <h5>Forgot Password</h5>
          <p>
            Enter your username and we will send you instructions on how to
            reset your password in your email.
          </p>

          <div className={Style.input}>
            <InputField
              label={"Your username"}
              className={Style.inputPassword}
              type={"text"}
              placeholder={"Enter your username"}
              required={true}
              onChange={() => {
                setMessage("");
              }}
              {...form.getInputProps("username")}
            />
          </div>

          <Button
            className={Style.buttonLogin}
            isLoading={forgotApi.isLoading}
            disabled={!form.isValid()}
            onClick={() => {
              forgotApi.executeQuery(form.getApiBody());
            }}
          >
            Submit
          </Button>

          {message !== "" && (
            <div
              style={{
                marginTop: 10,
                maxWidth: 350,
                marginInline: "auto",
                fontSize: 13,
              }}
            >
              {message}
            </div>
          )}
        </div>
      </section>
    </AuthRoute>
  );
};

export default ForgotPassword;
