import React, { useEffect, useState } from 'react'

//Components
import LoaderGlobal from "../../../Components/Commons/LoaderGlobal/LoaderGlobal";

//Libraires
import { Button, InputField } from '@hybris-software/ui-kit';
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { AuthRoute } from "@hybris-software/use-auth";
import { useNavigate, Link } from 'react-router-dom';

//Images
import Logo from '../../../Assets/images/logos/logo-pulse-full.png';
// Utils
import validatePassword from '../../../Utils/validatePassword';
//Styles
import Style from "./ResetPassword.module.css";

const ResetPassword = () => {
    const [info, setInfo] = useState("");

    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "PULSE WORLD | Reset Password";
    }, [])


    const form = useForm({
        inputs: {
            password: {
                nature: "password",
                required: true,
                errorOnEveryChange: true,
                validator: validatePassword,
            },
            confirmPassword: {
                nature: "confirmPassword",
                required: true,
                errorOnEveryChange: true,
                sendToApi: false,
            },
            token: {
                required: true,
                value: token,
            }
        }
    })

    const resetApi = useQuery({
        url: "auth/password-reset/confirm/",
        method: "POST",
        executeImmediately: false,
        onSuccess: (response) => {
            setInfo("Password reset successfully, you can now login with your new password");
            setTimeout(() => {
                navigate("/auth/login");
            }, 5000);
            form.reset();
        },
        onError: (error) => {
            setInfo("Something went wrong, please try again later, or link are expired");
        },
    });


    return (
        <AuthRoute
            loader={<LoaderGlobal />}
            minimumLoadingTime={0}
            forLoggedUser={false}
            action={() => {
                navigate("/auth/login");
            }}
        >
            <section className={Style.login}>
                <div className={Style.inner}>
                    <img src={Logo} alt="Logo" />

                    <h5>Reset Password</h5>

                    <div className={Style.input}>
                        <InputField
                            label={"New Password"}
                            className={Style.inputUsername}
                            type={"password"}
                            placeholder={"Password"}
                            required={true}
                            {...form.getInputProps("password")}
                        />
                    </div>

                    <div className={Style.input}>
                        <InputField
                            label={"Confirm Password"}
                            className={Style.inputPassword}
                            type={"password"}
                            placeholder={"Confirm Password"}
                            required={true}
                            {...form.getInputProps("confirmPassword")}
                        />
                    </div>

                    <Button
                        className={Style.buttonLogin}
                        isLoading={resetApi.isLoading}
                        disabled={!form.isValid()}
                        onClick={() => {
                            resetApi.executeQuery({
                                newPassword: form.values["password"],
                                token: form.values["token"],
                            });
                        }}
                    >
                        Submit
                    </Button>

                    <div style={{ maxWidth: 250, marginInline: "auto", marginTop: 10, fontSize: 13 }}>
                        {info}
                    </div>

                    <div className={Style.forgot}>
                        <Link to="/auth/login">Go to Login</Link>
                    </div>
                </div>
            </section>
        </AuthRoute>

    )
}

export default ResetPassword