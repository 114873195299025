import React from 'react'

// Libraries
import { Button } from '@hybris-software/ui-kit';

// Images
import XplCoin from '../../../../Assets/images/icons/xpl-coin.png';

//Hooks
import useText from '../../../../Hooks/useText';

// Styles
import Style from "./RedeemConfirm.module.css";

const RedeemConfirm = ({ redeemable, setShow, redeemPackagesApi }) => {

    const texts = useText("staking");
    const { title, text, textTwo, buttonYes, buttonNo } = texts.redeemPopup;

    return (
        <div className={Style.confirmRedeem}>
            <div className={Style.header}>
                <h3>{title}</h3>
                <h4><img src={XplCoin} alt="" />{redeemable} XPL</h4>
                <p className={Style.alert}>{text}</p>
                <p>{textTwo}</p>
            </div>
            <div className={Style.buttons}>
                <Button
                    className={Style.button}
                    onClick={() => setShow(false)}
                >
                    {buttonNo}
                </Button>
                <Button
                    disabled={redeemable <= 0}
                    isLoading={redeemPackagesApi.isLoading}
                    className={Style.button}
                    onClick={() => { redeemPackagesApi.executeQuery(); setShow(false) }}
                >
                    {buttonYes}
                </Button>
            </div>
        </div>
    )
}

export default RedeemConfirm