import React, { useEffect, useState } from "react";

// Components
import LoaderGlobal from "../../../Components/Commons/LoaderGlobal/LoaderGlobal";

//Libraires
import { Button } from "@hybris-software/ui-kit";
import { useLocation, useNavigate, Link } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { PermissionRoute } from "@hybris-software/use-auth";
import AuthCode from "react-auth-code-input";

//Images
import Logo from "../../../Assets/images/logos/logo-pulse-full.png";

//Styles
import Style from "./TwoFactor.module.css";

const TwoFactor = () => {
  const [authCode, setAuthCode] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "PULSE WORLD | Two Step Verification";
    // if (location?.state === null) {
    //     navigate("/auth/login");
    // }
  }, [navigate, location]);

  const twoFactorApi = useQuery({
    url: "auth/self-login/confirm/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      localStorage.setItem("token", response.data.token);
      localStorage.removeItem("loginToken");
      localStorage.removeItem("otpMethod");
      navigate("/");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const verifyOtp = () =>
    twoFactorApi.executeQuery({
      loginToken: localStorage.getItem("loginToken"),
      otp: authCode,
    });

  useEffect(() => {
    authCode.length === 6 && verifyOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);

  return (
    <PermissionRoute
      loader={<LoaderGlobal />}
      minimumLoadingTime={1000}
      forLoggedUser={false}
      unAuthorizedAction={() => {
        navigate("/auth/login");
      }}
      permissionController={() => {
        if (localStorage.getItem("loginToken") !== null) {
          return true;
        } else {
          navigate("/auth/login");
        }
      }}
    >
      <section className={Style.login}>
        <div className={Style.inner}>
          <img src={Logo} alt="Logo" />

          <h5>2-Step verification</h5>
          <p>
            We sent a verification code to your email. Enter the code from the
            email in the field below.
          </p>

          <div className={Style.input}>
            <div className={Style.form}>
              <AuthCode
                allowedCharacters="numeric"
                onChange={(e) => {
                  setAuthCode(e);
                }}
              />
            </div>
          </div>
          <div className={Style.error}>
            {twoFactorApi.isError &&
              twoFactorApi?.error?.response?.data?.message}
          </div>
          <Button
            className={Style.buttonLogin}
            isLoading={twoFactorApi.isLoading}
            disabled={authCode.length < 6}
            onClick={verifyOtp}
          >
            Verify
          </Button>

          <div className={Style.forgot}>
            <Link to="/auth/login">Go to Login</Link>
          </div>
        </div>
      </section>
    </PermissionRoute>
  );
};

export default TwoFactor;
