import React from "react";

// Componetns
import CardShopLimited from "../../../../Components/Commons/CardShopLimited/CardShopLimited";

// Styles
import Style from "./LimitedCardGrid.module.css";

const LimitedCardGrid = ({ textButton, title, data, roundInfo }) => {

  return (
    <section>
      {data.length > 0 && <h5 className={Style.title}>{title}</h5>}
      <div className={Style.grid}>
        {data.map(
          (item, index) =>
            item.canBuy === true && (
              <CardShopLimited
                key={index}
                roundInfo={roundInfo}
                item={item}
              />
            )
        )}
      </div>
    </section>
  );
};

export default LimitedCardGrid;
