import React from 'react'

// Libraires 
import { Container } from '@hybris-software/ui-kit'

// Styles
import Style from './ComingSoon.module.css'

const ComingSoon = ({ iconOne, iconTwo, iconThree, texts, video }) => {

    const { title, subTitle, titleOne, subTitleOne, titleTwo, subTitleTwo, titleThree, subTitleThree } = texts;
    return (
        <Container>
            <section>
                <div className={Style.header}>
                    <h2>{title}</h2>
                    <h6>{subTitle}</h6>
                </div>

                <div className={Style.cards}>
                    <div className={Style.card}>
                        <div className={Style.icon}>
                            <img src={iconOne} alt="" />
                        </div>
                        <div className={Style.text}>
                            <h5>{titleOne}</h5>
                            <p>{subTitleOne}</p>
                        </div>
                    </div>
                    <div className={Style.card}>
                        <div className={Style.icon}>
                            <img src={iconTwo} alt="" />
                        </div>
                        <div className={Style.text}>
                            <h5>{titleTwo}</h5>
                            <p>{subTitleTwo}</p>
                        </div>
                    </div>
                    <div className={Style.card}>
                        <div className={Style.icon}>
                            <img src={iconThree} alt="" />
                        </div>
                        <div className={Style.text}>
                            <h5>{titleThree}</h5>
                            <p>{subTitleThree}</p>
                        </div>
                    </div>
                </div>


                <div className={Style.boxImage}>
                    <video controls={true} muted={true} autoPlay={true} loop={true} width="100%" height="100%">
                        <source src={video} type="video/mp4" />
                    </video>
                </div>

            </section>
        </Container>
    )
}

export default ComingSoon