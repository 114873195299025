import { Button } from '@hybris-software/ui-kit';
import React from 'react'

// libraries
import { useNavigate } from 'react-router-dom';

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from './TimerExpired.module.css'

const TimerExpired = () => {

    const navigate = useNavigate();

    const texts = useText("shopBuy");
    const { header, text, button } = texts.timerExpired;

    return (
        <div className={Style.timer}>
            <h3>{header}</h3>
            <p>{text}</p>
            <Button
                className={Style.button}
                onClick={() => navigate("/my-shop")}
            >
                {button}
            </Button>
        </div>
    )
}

export default TimerExpired