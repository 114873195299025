import React, { useEffect, useState } from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthRoute } from "@hybris-software/use-auth";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import Loader from "../../Components/Commons/Loader/Loader";

// Hooks
import useText from "../../Hooks/useText";
import InProgress from "./Components/InProgress/InProgress";

const Transaction = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const texts = useText("transaction");
  const { head, inProgress, page } = texts;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const [permissionUrl, setPermissionUrl] = useState(true);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (!location.state) {
      navigate("/asset/wallet", { replace: true });
    } else {
      if (location.state.url !== undefined) {
        setPermissionUrl(false);
        setUrl(location.state.url);
      } else {
        navigate("/asset/wallet", { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={permissionUrl}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        <Heading {...head} />
        {url && <InProgress {...inProgress} data={url} />}
      </Container>
    </AuthRoute>
  );
};

export default Transaction;
