import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Images
import XplCoin from "../../../Assets/images/icons/xpl-coin.png";

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./CardShop.module.css";

const CardShop = ({ item, roundInfo, tokenPrice }) => {
  const navigate = useNavigate();

  const texts = useText("shopList");
  const { textButton, price } = texts.seed.cardBuy;

  return (
    <div className={Style.deep} style={{ opacity: item.canBuy ? 1 : 0.7 }}>
      <div className={Style.card}>
        <div className={Style.packageName}>{item.name}</div>
        <div className={Style.amount}>
          <div>$ {item.price}</div>
        </div>
        <div className={Style.coin}>
          <div>
            <img src={XplCoin} alt="" />
            <div className={Style.plx}>{(item.price / tokenPrice).toFixed(4)} XPL</div>
            <div className={Style.lineThrough}>
              {price} $ {tokenPrice.toFixed(4)}
            </div>
          </div>
        </div>
        <div className={Style.buttons}>
          <Button
            disabled={!item.canBuy}
            className={Style.button}
            onClick={() => {
              navigate(`/my-shop/buy?packageId=${item.id}`);
            }}
          >
            {textButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardShop
