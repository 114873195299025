import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import { useNavigate } from "react-router-dom";

// Images
import XplCoin from "../../../Assets/images/icons/xpl-coin.png";

// Hooks
import useText from "../../../Hooks/useText";

// Styles
import Style from "./CardShopLimited.module.css";

const CardShopLimited = ({ roundInfo, item }) => {
  const navigate = useNavigate();

  const texts = useText("shopList");
  const { textButton, discount, price } = texts.seed.cardBuyLimited;

  return (
    <div
      className={
        item.specialType === "date"
          ? Style.limitedThree
          : item.specialType === "packagecount"
          ? Style.limitedOne
          : Style.limitedTwo
      }
    >
      <div className={Style.card}>
        <div className={Style.amount}>
          <div>$ {item.price}</div>
        </div>
        <div className={Style.coin}>
          <div>
            <img src={XplCoin} alt="" />
            <div className={Style.plx}>{item.xplAmount} XPL</div>
            <div className={Style.lineThrough}>{item.marketXplAmount} XPL</div>
          </div>

          <div className={Style.discount}>
            <div>
              {discount} {item.specialDiscount} %
            </div>
            <div>
              {price} $ {item.specialTokenPrice}
            </div>
          </div>
        </div>
        {item.specialType === "date" ? (
          <div className={Style.bottomBar}>
            <div className={Style.bar}>
              Past days: {item.totalDays - item.daysLeft} / {item.totalDays}
              <div className={Style.progressBar}>
                <div
                  className={Style.progessActive}
                  style={{
                    width: `${Math.min(
                      ((item.totalDays - item.daysLeft) / item.totalDays) * 100,
                      100
                    )}%`,
                  }}
                ></div>
              </div>
            </div>
            <Button
              className={Style.button}
              onClick={() => {
                navigate(`/my-shop/buy?packageId=${item.id}`);
              }}
            >
              {textButton}
            </Button>
          </div>
        ) : item.specialType === "packagecount" ? (
          <div className={Style.bottomBar}>
            <div className={Style.bar}>
              Packages Sold: {Math.min(item.soldCount, item.maxCount)} /{" "}
              {item.maxCount}
              <div className={Style.progressBar}>
                <div
                  className={Style.progessActive}
                  style={{
                    width: `${(item.soldCount / item.maxCount) * 100}%`,
                  }}
                ></div>
              </div>
            </div>
            <Button
              className={Style.button}
              onClick={() => {
                navigate(`/my-shop/buy?packageId=${item.id}`);
              }}
            >
              {textButton}
            </Button>
          </div>
        ) : (
          <div className={Style.bottomBar}>
            <div className={Style.bar}>
              XPL Sold: {Math.min(item.soldTokens, item.maxTokens)} /{" "}
              {item.maxTokens}
              <div className={Style.progressBar}>
                <div
                  className={Style.progessActive}
                  style={{
                    width: `${Math.min(
                      (item.soldTokens / item.maxTokens) * 100,
                      100
                    )}%`,
                  }}
                ></div>
              </div>
            </div>
            <Button
              className={Style.button}
              onClick={() => {
                navigate(`/my-shop/buy?packageId=${item.id}`);
              }}
            >
              {textButton}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardShopLimited;
