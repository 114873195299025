import React from "react";

// Libraires
// import { Button } from "@hybris-software/ui-kit";
import { Link } from "react-router-dom";

// Styles
import Style from "./InProgress.module.css";

const InProgress = ({
  title,
  subTitle,
  retry,
  retryButton,
  cta,
  data,
}) => {
  return (
    <section className={Style.progress}>
      {title && <h2>{title}</h2>}

      {retry && (
        <div className={Style.retry}>
          {retry}{" "}
          <a target="_blank" rel="noopener noreferrer" href={data}>
            {retryButton}
          </a>
        </div>
      )}

      {/* {buttonVerify && <Button className={Style.button}>{buttonVerify}</Button>} */}

      {subTitle && <p className={Style.subtitle}>{subTitle}</p>}

      {cta && (
        <Link to="/asset/wallet" className={Style.cta}>
          {cta}
        </Link>
      )}
    </section>
  );
};

export default InProgress;
