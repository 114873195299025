import React, { useState, useEffect, useRef } from "react";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Icons
import { /* TbLayoutList, */ TbLayoutCards } from "react-icons/tb";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FaReadme } from "react-icons/fa";
import { BiMenuAltLeft } from "react-icons/bi";
import { VscBell } from "react-icons/vsc";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { MdMarkEmailRead } from "react-icons/md";

// Images
import AvatarPlaceholder from "../../../Assets/images/placeholder-avatar.jpg";
import EnFlag from "../../../Assets/images/icons/en.svg";
import FrFlag from "../../../Assets/images/icons/fr.svg";
import EsFlag from "../../../Assets/images/icons/es.svg";

// Hooks
import useText from "../../../Hooks/useText";
import useLanguages from '../../../Hooks/useLanguages'

// Utils
import getDataFromNotification from "../../../Utils/getDataFromNotification";

// Styles
import Style from "./Navbar.module.css";

const Navbar = (props) => {
	const texts = useText("navbar");
	const { language, setLanguage } = useLanguages()

	// Popup
	const { profilePopupText, notificationPopupText } = texts;
	const [profilePopup, setProfilePopup] = useState("none");
	const [notificationsPopup, setNotificationsPopup] = useState("none");
	const [languagePopup, setLanguagePopup] = useState("none");

	// Data from Api
	const [notification, setNotification] = useState([]);

	const notificationsPopupRef = useRef(notificationsPopup);

	const notificationsApi = useQuery({
		url: "notifications/",
		method: "GET",
		executeImmediately: true,
		onSuccess: (response) => {
			setNotification(response.data);
		},
		onError: () => {
			setNotification([]);
		},
	});

	function updateNotifications() {
		if (notificationsPopupRef.current === "none") {
			notificationsApi.executeQuery();
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			updateNotifications();
		}, 120000);
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		notificationsPopupRef.current = notificationsPopup;
	}, [notificationsPopup]);

	return (
		<div className={Style.mainContainer}>
			<div className={Style.content}>
				<div className={Style.contentLeft}>
					<div
						className={Style.menuButton}
						onClick={() => props.setSidebarIsOpen(!props.sidebarIsOpen)}
					>
						{props.sidebarIsOpen ? <BiMenuAltLeft /> : <HiOutlineMenuAlt1 />}
					</div>
				</div>

				<div className={Style.actions}>

					<div className={Style.boxLanguage}
						style={
							(language === "EN" && { backgroundImage: `url(${EnFlag})` }) ||
							(language === "FR" && { backgroundImage: `url(${FrFlag})` }) ||
							(language === "ES" && { backgroundImage: `url(${EsFlag})` }) ||
							({ backgroundImage: `url(${EnFlag})` })
						}
						onClick={() => {
							setProfilePopup("none");
							setNotificationsPopup("none");
							if (setLanguagePopup === "block") setLanguagePopup("none");
							else setLanguagePopup("block");
						}}
					>
						<LanguagePopup
							setLanguage={setLanguage}
							languagePopup={languagePopup}
							setLanguagePopup={setLanguagePopup}
						/>
					</div>

					<div className={Style.notificationsContainer}>
						<div
							className={Style.icon}
							onClick={() => {
								setLanguagePopup("none");
								setProfilePopup("none");
								if (notificationsPopup === "none") {
									setNotificationsPopup("block");
								} else {
									setNotificationsPopup("none");
								}
							}}
						>
							{notification.length > 0 && (
								<span className={Style.notification}></span>
							)}
							<VscBell />
						</div>
						<NotificationsPopup
							display={notificationsPopup}
							setDisplay={setNotificationsPopup}
							texts={notificationPopupText}
							notifications={notification}
							setNotifications={setNotification}
							loading={notificationsApi.isLoading}
						/>
					</div>

					<div
						className={Style.boxAvatar}
						onClick={() => {
							setLanguagePopup("none");
							setNotificationsPopup("none");
							if (profilePopup === "block") {
								setProfilePopup("none");
							} else {
								setProfilePopup("block");
							}
						}}
					>
						<ProfilePopup
							display={profilePopup}
							setDisplay={setProfilePopup}
							texts={profilePopupText}
						/>
						<div
							className={Style.avatar}
							style={
								props.avatar
									? { backgroundImage: `url(${props.avatar})` }
									: { backgroundImage: `url(${AvatarPlaceholder})` }
							}
						></div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default Navbar;

const ProfilePopup = ({ display, setDisplay, texts }) => {
	const navigate = useNavigate();

	const logoutApi = useQuery({
		url: "auth/logout/",
		method: "POST",
		executeImmediately: false,
	});

	return (
		<div
			className={Style.profilePopupContainer}
			style={{ display: display }}
			onMouseLeave={() => setDisplay("none")}
		>
			<div className={Style.profilePopup}>
				<div
					className={Style.popupLink}
					onClick={() => {
						navigate("/profile", { state: { tab: "profile" } });
						setDisplay("none");
					}}
				>
					<TbLayoutCards />
					<h5>{texts.profile}</h5>
				</div>
				{/* <div
          className={Style.popupLink}
          onClick={() => {
            navigate("/profile", { state: { tab: "recentDevices" } });
            setDisplay("none");
          }}
        >
          <TbLayoutList />
          <h5>{texts.recentDevices}</h5>
        </div> */}
				<div className={Style.divider}></div>
				<div
					className={Style.popupLink}
					onClick={() => {
						logoutApi.executeQuery();
						navigate("/auth/logout");
					}}
				>
					<RiLogoutCircleLine />
					<h5>{texts.logout}</h5>
				</div>
			</div>
		</div>
	);
};

const LanguagePopup = ({ languagePopup, setLanguagePopup, setLanguage }) => {

	return (
		<div
			className={Style.profilePopupContainerLang}
			style={{ display: languagePopup }}
			onMouseLeave={() => setLanguagePopup("none")}
		>
			<div className={Style.profilePopup}>

				<div
					className={Style.popupLink}
					onClick={() => {
						setLanguage("EN");
						localStorage.setItem("lng", "EN");
					}}
					onTouchStart={() => {
						setLanguage("EN");
						localStorage.setItem("lng", "EN");
					}}
					onTouchEnd={() => setLanguagePopup("none")}
				>
					<div className={Style.langCircle} style={{ backgroundImage: `url(${EnFlag})` }}></div>
					<h5>English</h5>
				</div>
				<div className={Style.divider}></div>
				<div
					className={Style.popupLink}
					onClick={() => {
						setLanguage("FR");
						localStorage.setItem("lng", "FR");
					}}
					onTouchStart={() => {
						setLanguage("FR");
						localStorage.setItem("lng", "FR");
					}}
					onTouchEnd={() => setLanguagePopup("none")}
				>
					<div className={Style.langCircle} style={{ backgroundImage: `url(${FrFlag})` }}></div>
					<h5>French</h5>
				</div>
				<div className={Style.divider}></div>
				<div
					className={Style.popupLink}
					onClick={() => {
						setLanguage("ES");
						localStorage.setItem("lng", "ES");
					}}
					onTouchStart={() => {
						setLanguage("ES");
						localStorage.setItem("lng", "ES");
					}}
					onTouchEnd={() => setLanguagePopup("none")}
				>
					<div className={Style.langCircle} style={{ backgroundImage: `url(${EsFlag})` }}></div>
					<h5>Spanish</h5>
				</div>
			</div>
		</div>
	);
};

const NotificationsPopup = ({
	display,
	setDisplay,
	texts,
	notifications,
	setNotifications,
	loading,
}) => {
	const notificationsApi = useQuery({
		url: "notifications/view/",
		method: "POST",
		executeImmediately: false,
	});

	return (
		<div
			className={Style.notificationsPopupContainer}
			style={{ display: display }}
			onMouseLeave={() => setDisplay("none")}
		>
			<div className={Style.notificationsPopup}>
				{loading ? (
					<div className={Style.notificationsLoader}>
						<div className={Style.buttonLoader}></div>
					</div>
				) : (
					<>
						{notifications.length > 0 ? (
							<>
								{notifications.map((notification, index) => {
									const tmpTextOtherUser = getDataFromNotification(
										notification.notificationType,
										notification.otherUser
									);
									return (
										<div
											id={notification.id}
											key={notification.id}
											className={Style.notificationMessage}
										>
											<div
												className={Style.notificationOver}
												onClick={() => {
													notificationsApi.executeQuery({
														notificationIds: [notification.id],
													});
													setNotifications((oldNotifications) => {
														return oldNotifications.filter(
															(item) => notification.id !== item.id
														);
													});
												}}
											>
												<MdMarkEmailRead />
											</div>
											<h5>
												{texts[notification.notificationType] +
													tmpTextOtherUser}
											</h5>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													columnGap: "10px",
												}}
											>
												<p>{notification.date}</p>
												<p>{notification.amount}</p>
											</div>
										</div>
									);
								})}
								<>
									<div className={Style.divider}></div>
									<div
										className={Style.notificationLink}
										onClick={() => {
											notificationsApi.executeQuery({
												notificationIds: notifications.map((item) => item.id),
											});
											setNotifications([]);
										}}
									>
										<FaReadme />
										<h5>{texts.readAll}</h5>
									</div>
								</>
							</>
						) : (
							<>
								<div className={Style.noNotifications}>
									<p>{texts.noNotifications}</p>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};
