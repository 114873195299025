import React from 'react'

// Libraries
import { Button } from '@hybris-software/ui-kit'
import { useNavigate } from 'react-router-dom'

// Images 
import XplIcon from '../../../../Assets/images/icons/xpl-coin.png'

// Styles
import Style from './NoStakingStats.module.css'

const NoStakingStats = ({ title, button }) => {

    const navigate = useNavigate();

    return (
        <div className={Style.advertiser}>
            <div className={Style.coin}>
                <img src={XplIcon} alt="icon" />
                XPL
            </div>
            <div className={Style.info}>
                <div className={Style.label}>
                    {title}
                </div>
                <div className={Style.buttonBuy}>
                    <Button
                        className={Style.button}
                        onClick={() => navigate('/my-shop')}
                    >
                        {button}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NoStakingStats