import React from "react";

// Libraries
import { useNavigate } from "react-router-dom";

// Images
import AwCoin from "../../../../Assets/images/icons/aw-coin.png";
import XplCoin from "../../../../Assets/images/icons/xpl-coin.png";
import PusdCoin from "../../../../Assets/images/icons/pusd-coin.png";

// Icons
import { AiOutlineBarChart } from "react-icons/ai";


// Hooks
import useText from '../../../../Hooks/useText'

// Styles
import Style from "./RowValueStats.module.css";
import { Button } from "@hybris-software/ui-kit";

const RowValueStats = ({ data, tokenPrice }) => {

	const navigate = useNavigate();

	const texts = useText('dashboard')
	const { labelOne, labelTwo, labelThree, labelFour } = texts.stats

	return (
		<section className={Style.stats}>
			<div className={Style.valueBox}>
				<AiOutlineBarChart />
				<div className={Style.title}>{labelOne}</div>
				<div className={Style.numbers}>
					<div className={Style.number}>
						${" "}
						<span>{data.xplMarketCap}</span>
					</div>
				</div>
			</div>

			<div className={Style.valueBox}>
				<img src={XplCoin} alt="" />
				<div className={Style.title}>{labelTwo}</div>
				<div className={Style.numbers}>
					<div className={Style.number}>
						${" "}
						<span>{tokenPrice.toFixed(4)}</span>
					</div>
				</div>
			</div>

			<div className={Style.valueBox}>
				<div className={Style.deposit}>
					<img src={PusdCoin} alt="" />
					<Button
						className={Style.depositBtn}
						onClick={() => { navigate("/asset/wallet", { state: { popup: true } }) }}
					>Deposit</Button>
				</div>
				<div className={Style.title}>{labelThree}</div>
				<div className={Style.numbers}>
					<div className={Style.number}>
						<span>{data.pusdBalance}</span>
						{" "}PUSD</div>
				</div>
			</div>

			<div className={Style.valueBox}>
				<img src={AwCoin} alt="" />
				<div className={Style.title}>{labelFour}</div>
				<div className={Style.numbers}>
					<div className={Style.number}>
						<span>{data.pWalletBalance}</span>
						{" "}AUSD
					</div>
				</div>
			</div>
		</section >
	);
};

export default RowValueStats;
