import React, { useState } from "react";

// Components
import LoaderGlobal from "../../Components/Commons/LoaderGlobal/LoaderGlobal";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import Navbar from "../../Components/Commons/Navbar/Navbar";
import Sidebar from "../../Components/Commons/Sidebar/Sidebar";
import Disclaimer from "../../Components/Commons/Disclaimer/Disclaimer";

// Contexts
import SidebarContext from "../../Contexts/sidebarContext";

// Libraries
import { AuthRoute } from "@hybris-software/use-auth";
import { Outlet } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Styles
import Style from "./MainLayout.module.css";
import CheckInfoUser from "../../Components/Commons/CheckInfoUser/CheckInfoUser";


const MainLayout = () => {

  const navigate = useNavigate();
  const [theme, setTheme] = useState("dark");

  /* Disclaimer */
  const disclaimerShow = sessionStorage.getItem("disclaimer");
  const [disclaimer, setDisclaimer] = useState(disclaimerShow === "true" ? true : false);
  const [checkUser, setCheckUser] = useState(null);

  /* Sidebar */
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const closeSidebar = () => {
    if (window.innerWidth < 991 && sidebarIsOpen) {
      setSidebarIsOpen(false);
    }
  };

  const popUpApi = useQuery({
    url: "dashboard/popup/",
    method: "GET",
    executeImmediately: true,
  })

  const features = useQuery({
    url: "configuration/features/",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setCheckUser(response.data.userInfoUpdateEnabled)
    },
  })

  const profileApi = useQuery({
    url: "auth/user/",
    method: "GET",
    executeImmediately: true,
  });


  return (
    <AuthRoute
      loader={<LoaderGlobal />}
      forLoggedUser={true}
      apiLoading={popUpApi.isLoading || features.isLoading || profileApi.isLoading}
      action={() => {
        navigate("/auth/login")
      }}
    >
      <SidebarContext.Provider value={sidebarIsOpen}>

        <div className={Style.layouting} data-theme={theme}>
          <>
            {disclaimer !== true && popUpApi.response?.data.text !== null &&
              <GeneralPopup style={{ maxWidth: 500 }}>
                <Disclaimer setDisclaimer={setDisclaimer} data={popUpApi.response?.data} />
              </GeneralPopup>
            }
            {checkUser === true && (!profileApi.response?.data?.email || !profileApi.response?.data?.country) &&
              <GeneralPopup GeneralPopup style={{ maxWidth: 500 }}>
                <CheckInfoUser setCheckUser={setCheckUser} />
              </GeneralPopup>
            }

            <Sidebar
              theme={theme}
              setTheme={setTheme}
              closeSidebar={closeSidebar}
              sidebarIsOpen={sidebarIsOpen}
              setSidebarIsOpen={setSidebarIsOpen}
            />
            <div className={Style.mainContainer} onClick={() => closeSidebar()}>
              <Navbar
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
              />
              <main className={Style.contentContainer}>
                <Outlet />
              </main>
            </div>
          </>
        </div>
      </SidebarContext.Provider>
    </AuthRoute >
  );
};

export default MainLayout;
