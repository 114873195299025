import React, { useState } from "react";

// libraires
import { Button, InputField } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";
import useForm from "@hybris-software/use-ful-form";

// Images
import UsdtCoin from "../../../Assets/images/icons/tether-coin.png";
// import UsdcCoin from "../../../Assets/images/icons/usdc-coin.svg";
import EthCoin from "../../../Assets/images/icons/eth-coin.png";
import OpUsdtCoin from "../../../Assets/images/icons/op-coin-tether.png";
// import OpUsdcCoin from "../../../Assets/images/icons/op-usdc-coin.svg";
import OpEthCoin from "../../../Assets/images/icons/op-eth-coin.png";
import OpBtcCoin from "../../../Assets/images/icons/op-btc-coin.png";
import BtcCoin from "../../../Assets/images/icons/btc-coin.png";
import MiniChainEth from "../../../Assets/images/icons/mini-chain-eth.png";
import MiniChainTron from "../../../Assets/images/icons/mini-chain-tron.png";

// Icons
import { IoCloseOutline } from "react-icons/io5";

// Utils
import classNames from "../../../Utils/classNames";

// Styles
import Style from "./SelectToken.module.css";

const SelectToken = ({
  title,
  title2,
  button,
  setIsOpen,
  setDepositTokenData,
  depositTokenData,
  feeText,
  btcTime,
  alert,
  fees,
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const form = useForm({
    inputs: {
      cryptoAmount: {
        required: true,
        formatter: (value) => {
          // Sono consetiti solo 12 numeri prima della virgola, virgola e punto come separatore decimale (non è possibile inserire più di un punto o virgola), e non più di 4 cifre decimali (non è possibile inserire più di 4 cifre decimali dopo il punto o la virgola).
          const regex = /^([0-9]{0,12}([.,][0-9]{0,4})?|[.,][0-9]{0,4})$/;
          if (regex.test(value)) {
            return value;
          } else {
            return value.slice(0, -1);
          }
        },
        validator: (value) => {
          // se non è vuoto ed è maggiore di 0
          if (value && value > 0) {
            return [true, null]
          } else {
            return [false, "Please enter a valid amount"]

          }
        },
      },
    }
  })

  const depositApi = useQuery({
    url: "wallets/deposit/",
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      navigate("/asset/wallet/transaction", {
        state: { url: response.data.url },
      });
      window.open(response.data.url, "_blank");
    },
  });


  return (
    <>
      {step === 1 && (
        <section className={Style.depositPopup}>
          <div className={Style.header}>
            <div>{title}</div>
            <IoCloseOutline
              size={25}
              className={Style.closeIcon}
              onClick={() => {
                setIsOpen(false);
                setDepositTokenData(null);
              }}
            />
          </div>
          <div className={Style.boxChoise}>
            <ListAssets
              chainIcon={MiniChainEth}
              id={"usdt-erc20"}
              depositTokenData={depositTokenData}
              coin={UsdtCoin}
              bg={OpUsdtCoin}
              assets={"USDT"}
              chain={"ERC-20"}
              onClick={() => setDepositTokenData("usdt-erc20")}
            />
            <ListAssets
              chainIcon={MiniChainTron}
              id={"usdt-trc20"}
              depositTokenData={depositTokenData}
              coin={UsdtCoin}
              bg={OpUsdtCoin}
              assets={"USDT"}
              chain={"TRC-20"}
              onClick={() => setDepositTokenData("usdt-trc20")}
            />
            {/* <ListAssets
          id={"usdc"}
          depositTokenData={depositTokenData}
          coin={UsdcCoin}
          bg={OpUsdcCoin}
          assets={"USDC"}
          chain={"ERC-20"}
          chainIcon={MiniChainEth}
          onClick={() => setDepositTokenData("usdc")}
        /> */}
            <ListAssets
              id={"btc"}
              depositTokenData={depositTokenData}
              coin={BtcCoin}
              bg={OpBtcCoin}
              assets={"BTC"}
              onClick={() => setDepositTokenData("btc")}
            />
            <ListAssets
              id={"eth"}
              depositTokenData={depositTokenData}
              coin={EthCoin}
              bg={OpEthCoin}
              assets={"ETH"}
              chain={"ERC-20"}
              chainIcon={MiniChainEth}
              onClick={() => { setDepositTokenData("eth"); }}
            />
            {/* <ListAssets
          id={"trx"}
          depositTokenData={depositTokenData}
          coin={EthCoin}
          bg={OpEthCoin}
          assets={"TRX"}
          onClick={() => setDepositTokenData("trx")}
        /> */}

            <div className={Style.fees}>
              {depositTokenData === "btc" && (
                <p style={{ fontSize: 14, fontWeight: 600, marginBottom: 2 }}>{btcTime}</p>
              )}
              <p style={{ fontSize: 14, fontWeight: 600, color: "#df4848" }}>
                {alert}
              </p>
            </div>

            <Button
              className={Style.btnNext}
              disabled={!depositTokenData}
              onClick={() => {
                setStep(2);
              }}
            >
              {button}
            </Button>
          </div>
        </section>
      )}

      {step === 2 && (
        <section className={Style.depositPopup}>
          <div className={Style.header}>
            <div>{title2}</div>
            <IoCloseOutline
              size={25}
              className={Style.closeIcon}
              onClick={() => {
                setIsOpen(false);
                setDepositTokenData(null);
                setStep(1);
              }}
            />
          </div>
          <InputField
            style={
              (depositTokenData === "eth" && { backgroundImage: `url(${OpEthCoin})` }) ||
              (depositTokenData === "btc" && { backgroundImage: `url(${OpBtcCoin})` }) ||
              ({ backgroundImage: `url(${OpUsdtCoin})` })
            }
            className={Style.inputAmount}
            placeholder="0.0000"
            {...form.getInputProps("cryptoAmount")}
          />
          <Button
            className={Style.btnNext}
            isLoading={depositApi.isLoading}
            disabled={!form.isValid()}
            onClick={() => {
              depositApi.executeQuery({
                ...form.getApiBody(),
                currency: depositTokenData,
                destinationWallet: "pusd",
                // currency: "tusdt",
              }
              );
            }}
          >
            {button}
          </Button>
        </section>
      )}
    </>

  );
};

const ListAssets = ({
  coin,
  bg,
  assets,
  chain,
  onClick,
  className,
  id,
  depositTokenData,
  chainIcon,
}) => {
  return (
    <div
      className={classNames(
        depositTokenData === id && Style.labelCardSelected,
        Style.labelCard,
        className
      )}
      onClick={onClick}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={Style.miniature}>
        <img src={coin} alt={assets} width={45} />
        {chainIcon && (
          <img src={chainIcon} className={Style.miniIcon} alt="icon" />
        )}
      </div>
      <div className={Style.info}>
        <h3 className={Style.assetsName}>{assets}</h3>
        {chain && <span className={Style.labelChain}>{chain}</span>}
      </div>
    </div>
  );
};

export default SelectToken;
