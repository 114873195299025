import React from "react";

// Utils
import chooseIcon from "../../../../Utils/chooseIcon";
import getDataFromNotification from "../../../../Utils/getDataFromNotification";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./LatestActivities.module.css";

const LatestActivities = ({ data }) => {
	const texts = useText("navbar");
	const { notificationPopupText } = texts;

	return (
		<aside className={Style.activities}>
			<h6 className={Style.title}>{notificationPopupText.titleActivities}</h6>

			<div className={Style.group}>
				{data.length > 0 ? (
					data.map((activity, index) => {
						return (
							<SingleActivity
								key={index}
								transaction={activity.notificationType}
								date={activity.date}
								amount={activity.amount}
								type={activity.type}
								otherUser={activity.otherUser}
								texts={notificationPopupText}
							/>
						);
					})
				) : (
					<div className={Style.noActivities}>{notificationPopupText.noActivities}</div>
				)}
			</div>
		</aside>
	);
};

const SingleActivity = ({
	transaction,
	date,
	amount,
	type,
	otherUser,
	texts,
}) => {
	const tmpText = getDataFromNotification(transaction, otherUser);
	return (
		<div className={Style.singleBox}>
			<div>{chooseIcon(type)}</div>
			<div className={Style.info}>
				<div className={Style.transaction}>
					{`${texts[transaction]} ${tmpText}`}
				</div>
				<div className={Style.status}>
					<div className={Style.date}>{date}</div>
				</div>
				<div className={Style.amount}>{amount}</div>
			</div>
		</div>
	);
};

export default LatestActivities;
