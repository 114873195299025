import React, { useState } from "react";

// Components
import GeneralPopup from "../../../../Components/Commons/GeneralPopup/GeneralPopup";
import RedeemConfirm from "../RedeemConfirm/RedeemConfirm";
import Feedback from "../Feedback/Feedback";

// Libraries
import { Button } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Icon
import { IoMdSwap } from "react-icons/io";
import { ImArrowUp2 } from "react-icons/im";

// Images
import NoResult from "../../../../Assets/images/no-result.png";

// Hooks
import useText from "../../../../Hooks/useText";

// Styles
import Style from "./StakingPackages.module.css";
import Swap from "../Swap/Swap";

const StakingPackages = ({ stakingPackagesApi, stakingBalanceApi, title, stakingCard, stakingExist, data, noStakingpackages }) => {

	const texts = useText("errorStaking");
	const { successRedeem, successSwap, errorRedeem, errorSwap } = texts

	const [show, setShow] = useState(false);
	const [showSwap, setShowSwap] = useState(false);
	const [idpackage, setIdpackage] = useState(null);
	const [message, setMessage] = useState("");
	const [typeFeedback, setTypeFeedback] = useState("");
	const [showFeedback, setShowFeedback] = useState(false);
	/* Filter data from id selected */
	const filterData = data.filter((item) => item.id === idpackage);
	const redeemable = filterData[0]?.xplAvailableFree;


	const redeemPackagesApi = useQuery({
		url: `xpl/packages/${idpackage}/redeem/`,
		method: "POST",
		executeImmediately: false,
		onSuccess: () => {
			setTypeFeedback("success");
			setMessage(successRedeem);
			setShowFeedback(true);

		},
		onError: () => {
			setShowFeedback(true);
			setTypeFeedback("error");
			setMessage(errorRedeem);
		}
	});

	const swapPackagesApi = useQuery({
		url: `xpl/packages/${idpackage}/swap/`,
		method: "POST",
		executeImmediately: false,
		onSuccess: () => {
			setTypeFeedback("success");
			setMessage(successSwap);
			setShowFeedback(true);
		},
		onError: () => {
			setShowFeedback(true);
			setTypeFeedback("error");
			setMessage(errorSwap);
		}
	});

	const feeInfoApi = useQuery({
		url: "configuration/fees/",
		method: "GET",
		executeImmediately: true,
	});

	return (
		<section className={Style.packages}>

			{showFeedback && (
				<GeneralPopup>
					<Feedback message={message} setShowFeedback={setShowFeedback} typeFeedback={typeFeedback} stakingBalanceApi={stakingBalanceApi} stakingPackagesApi={stakingPackagesApi} />
				</GeneralPopup>
			)}

			{show && (
				<GeneralPopup>
					<RedeemConfirm redeemable={redeemable} setShow={setShow} redeemPackagesApi={redeemPackagesApi} />
				</GeneralPopup>
			)}

			{showSwap && (
				<GeneralPopup>
					<Swap redeemable={redeemable} setShowSwap={setShowSwap} fees={feeInfoApi.response.data} swapPackagesApi={swapPackagesApi} />
				</GeneralPopup>
			)}

			{stakingExist && (
				<>
					{title && <h5 className={Style.title}>{title}</h5>}
					{data.map((item, index) => {
						return <StakingCard key={index} {...stakingCard} item={item} setShow={setShow} setIdpackage={setIdpackage} setShowSwap={setShowSwap} feeInfoApi={feeInfoApi} />;
					})}
				</>
			)}

			{!stakingExist && (
				<div className={Style.progress}>
					<div className={Style.item}>
						<img src={NoResult} alt="" width={120} />
						<div>{noStakingpackages}</div>
					</div>
				</div>
			)}
		</section>
	);
};

const StakingCard = ({ setIdpackage, labelOne, labelTwo, labelThree, dateOfBuy, dateOfExpiry, days, item, setShow, setShowSwap, feeInfoApi }) => {

	// const navigate = useNavigate();
	return (
		<div className={Style.cardStak}>
			<div className={Style.quantity}>
				{item.xplTotalAmount} XPL
				<div className={Style.buttons}>
					<Button
						disabled={item.xplAvailableFree <= 0 && feeInfoApi.response}
						className={Style.buttonSwap}
						onClick={() => {
							setIdpackage(item.id)
							setShowSwap(true);
						}}
					>
						<IoMdSwap /> Swap
					</Button>
					<Button
						disabled={item.xplAvailableFree <= 0}
						className={Style.buttonRedeem}
						onClick={() => {
							setIdpackage(item.id)
							setShow(true);
						}}
					>
						<ImArrowUp2 /> Redeem
					</Button>
				</div>
			</div>

			<div className={Style.fee}>
				<div className={Style.rewards}></div>

				<div className={Style.free}>
					{labelOne}
					<div>
						{" "}
						{item.xplGeneratedTotal} XPL
					</div>
				</div>

				<div className={Style.locked}></div>
			</div>

			<div className={Style.date}>
				<div className={Style.start}>
					{dateOfBuy} {item.createdAt}
				</div>
				<div className={Style.end}>
					{dateOfExpiry} {item.expiryAt}
				</div>
			</div>

			<div className={Style.step}>
				{[...Array(item.months)].map((e, i) => (
					<PassedStep key={i} />
				))}

				{[...Array(16 - item.months)].map((e, i) => (
					<NotPassedStep key={i} />
				))}
			</div>

			<div className={Style.percenteage}>
				<div>Rate: {item.penalty === false ? "160%" : "120%"}</div>
				{item.days} / 480 {days}
			</div>
		</div >
	);
};
const PassedStep = () => {
	return <div className={Style.passed}></div>;
};
const NotPassedStep = () => {
	return <div className={Style.notPassed}></div>;
};

export default StakingPackages;
