import React, { useEffect } from 'react'

// Libraries
import { useNavigate } from 'react-router-dom'

// Images
import NotFound from '../../../Assets/images/404.png'
import PointRight from '../../../Assets/images/point-right.png'
import PointLeft from '../../../Assets/images/point-left.png'
import LogoPulseFull from '../../../Assets/images/logos/logo-pulse-full.png'

// Hooks
import useText from '../../../Hooks/useText'

// Styles
import Style from './Page404.module.css'

const Page404 = () => {

  const texts = useText('pageNotFound');
  const { title, subTitle, page } = texts;

  useEffect(() => {
    document.title = 'PULSE WORLD | ' + page
  }, [page])

  const navigate = useNavigate();

  return (
    <section className={Style.page}>
      <img src={LogoPulseFull} alt="" className={Style.logo} style={{ cursor: "pointer" }} onClick={() => { navigate("/") }} />
      <img src={PointRight} alt="" className={Style.pointRight} />
      <img src={PointLeft} alt="" className={Style.pointLeft} />
      <div className={Style.center}>
        <img src={NotFound} alt="" />
        <div className={Style.text}>
          <h1>{title}</h1>
          <h4>{subTitle}</h4>
        </div>
      </div>
    </section>
  )
}

export default Page404