import React, { useState, useEffect } from "react";

// Libraires
import { Container } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import FormBuy from "./Components/FormBuy/FormBuy";
import GeneralPopup from "../../Components/Commons/GeneralPopup/GeneralPopup";
import Loader from "../../Components/Commons/Loader/Loader";
import ConfirmationBuy from "./Components/ConfirmationBuy/ConfirmationBuy";

// Hooks
import useText from "../../Hooks/useText";

const ShopBuy = () => {
  const navigate = useNavigate();
  const texts = useText("shopBuy");

  const { head, popupConfirm, formBuy, page } = texts;

  const [showPopup, setShowPopup] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const packageId = urlParams.get("packageId");

  const getPackageInfoApi = useQuery({
    url: `xpl/buy/`,
    method: "POST",
    executeImmediately: false,
    onError: () => {
      navigate("/my-shop");
    },
  });


  useEffect(() => {
    if (packageId === null) {
      navigate("/my-shop");
    } else {
      getPackageInfoApi.executeQuery({
        offerId: packageId,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={getPackageInfoApi.isLoading}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        {showPopup && (
          <GeneralPopup style={{ maxWidth: 500 }}>
            <ConfirmationBuy {...popupConfirm} />
          </GeneralPopup>
        )}
        {getPackageInfoApi.response && (
          <>
            <Heading {...head} />
            <FormBuy
              setShowPopup={setShowPopup}
              {...formBuy}
              data={getPackageInfoApi.response.data}
            />
          </>
        )}
      </Container>
    </AuthRoute>
  );
};

export default ShopBuy;
