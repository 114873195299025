const validatePassword = (password) => {
    if (password.length <= 10 || !password.match(/[a-z]/i)) {
        return [
            false,
            {
                message:
                    "Password Must cotain at least 10 characters and one lowercase letter",
                security: "inputLow",//#EA4479
            },
        ];
    } else if (!password.match(/[0-9]/)) {
        return [
            false,
            {
                message: "Password must contain at least one number",
                security: "inputLow",//#EA4479
            },
        ];
    } else if (!password.match(/[A-Z]/)) {
        return [
            false,
            {
                message: "Password must contain at least one uppercase letter",
                security: "inputMedium",//#F0DC57
            },
        ];
    } else if (!password.match(/[^a-zA-Z0-9]/g)) {
        return [
            false,
            {
                message: "Password must contain at least one special character",
                security: "inputHigh", // #55ADD9
            },
        ];
    } else {
        return [
            true,
            {
                security: "inputStrong", //#5BC142
            },
        ];
    }
}

export default validatePassword;
