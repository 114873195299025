import React from "react";

// Libraries
import { Outlet } from "react-router-dom";

//Images
import PointRight from '../../Assets/images/point-right.png'
import PointLeft from '../../Assets/images/point-left.png'

// Styles
import Style from "./AuthLayout.module.css";

const AuthLayout = () => {
  return (
    <div className={Style.layoutContainer}>
      <img src={PointRight} alt="" className={Style.pointRight} />
      <img src={PointLeft} alt="" className={Style.pointLeft} />
      <div className={Style.outletContainer}>
        <Outlet />
      </div>
    </div>
  )
};

export default AuthLayout;
