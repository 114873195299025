import React, { useEffect, useState } from "react";

// Libraries
import { Button, Container } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router-dom";

// Components
import Heading from "../../Components/Commons/Heading/Heading";
import Loader from "../../Components/Commons/Loader/Loader";
import SimpleSeedLabel from "./Components/SimpleSeedLabel/SimpleSeedLabel";
import RowValueStats from "./Components/RowValueStat/RowValueStats";
import GridLayoutDashboard from "./Components/GridLayoutDashboard/GridLayoutDashboard";

// images
import Omega from "../../Assets/images/omega.jpg";

// Hooks
import useText from "../../Hooks/useText";

import Style from "./Dashboard.module.css";

const DashBoard = () => {
  const navigate = useNavigate();
  const texts = useText("dashboard");
  const { head, page } = texts;

  const textsTwo = useText("shopList");
  const { seed } = textsTwo;

  const [tokenPrice, setTokenPrice] = useState(1);

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const boxesApi = useQuery({
    url: "dashboard/market-info/",
    method: "GET",
    executeImmediately: true,
  });

  const balanceApi = useQuery({
    url: "xpl/balance/",
    method: "GET",
    executeImmediately: true,
  });

  const donutChartApi = useQuery({
    url: "dashboard/balance/",
    method: "GET",
    executeImmediately: true,
  });

  const lastActivitiesApi = useQuery({
    url: "notifications/latest/?limit=6",
    method: "GET",
    executeImmediately: true,
  });

  const lineChartApi = useQuery({
    url: "wallets/balance-history/",
    method: "GET",
    executeImmediately: true,
  });

  const roundInfoApi = useQuery({
    url: "xpl/round/",
    method: "GET",
    executeImmediately: true,
  });

  const tokenPriceApi = useQuery({
    url: "https://openapi.tokex.pro/sapi/v1/ticker?symbol=xplusdt",
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {
      setTokenPrice(parseFloat(response.data.last));
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      tokenPriceApi.executeQuery();
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={
        boxesApi.isLoading ||
        balanceApi.isLoading ||
        donutChartApi.isLoading ||
        lastActivitiesApi.isLoading ||
        lineChartApi.isLoading ||
        roundInfoApi.isLoading
      }
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        <Heading {...head} />
        <div className={Style.image}>
          {roundInfoApi.response && (
            <SimpleSeedLabel
              {...seed}
              roundName={roundInfoApi.response.data.name}
              tokenPrice={tokenPrice}
            />
          )}
        </div>
        {boxesApi.response && (
          <RowValueStats
            data={boxesApi.response.data}
            tokenPrice={tokenPrice}
          />
        )}
        {balanceApi.response &&
          donutChartApi.response &&
          lastActivitiesApi.response &&
          lineChartApi.response && (
            <GridLayoutDashboard
              tokenPrice={tokenPrice}
              balance={balanceApi.response.data}
              donut={donutChartApi.response.data}
              notifications={lastActivitiesApi.response.data}
              lineChart={lineChartApi.response.data}
            />
          )}
      </Container>
    </AuthRoute>
  );
};

export default DashBoard;
