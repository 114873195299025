import React, { useEffect } from "react";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { AuthRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";

// Libraires
import { useNavigate } from "react-router-dom";

// Components
import SendBox from "./Components/SendBox";
import Heading from "../../Components/Commons/Heading/Heading";
import Loader from "../../Components/Commons/Loader/Loader";

// Hooks
import useText from "../../Hooks/useText";

const SendXpl = () => {

  const navigate = useNavigate();
  const texts = useText("sendXpl");
  const { head, sendBox, page } = texts;

  useEffect(() => {
    document.title = "PULSE WORLD | " + page;
  }, [page]);

  const xplBalance = useQuery({
    url: "xpl/balance/",
    method: "GET",
    executeImmediately: true,
  });

  const feesApi = useQuery({
    url: "configuration/fees/",
    method: "GET",
    executeImmediately: true,
  });

  return (
    <AuthRoute
      loader={<Loader />}
      minimumLoadingTime={1000}
      forLoggedUser={true}
      apiLoading={xplBalance.isLoading || feesApi.isLoading}
      action={() => {
        navigate("/auth/login");
      }}
    >
      <Container>
        <Heading {...head} />
        {xplBalance.response && feesApi.response && (
          <SendBox
            {...sendBox}
            data={xplBalance.response.data}
            updateBalance={xplBalance}
            fees={feesApi.response.data}
          />
        )}
      </Container>
    </AuthRoute>
  );
};

export default SendXpl;
