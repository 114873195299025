import React from 'react'

// Components 
import { Button } from '@hybris-software/ui-kit'

// Styles
import Style from "./Disclaimer.module.css";

const Disclaimer = ({ setDisclaimer, data }) => {

    const { title, text, ratio, picture } = data;

    return (
        <section className={Style.disclaimer}>
            <div className={Style.header}>
                <h3>PULSE WORLD</h3>
                <h6>{title}</h6>
            </div>

            {picture && ratio &&
                <div
                    className={Style.backgroundImage}
                    style={
                        (ratio === '16:9' && { backgroundImage: `url(${picture})`, aspectRatio: '16/9' })
                        || (ratio === '4:3' && { backgroundImage: `url(${picture})`, aspectRatio: '4/3' })
                        || (ratio === '3:2' && { backgroundImage: `url(${picture})`, aspectRatio: '3/2' })
                        || (ratio === '1:1' && { backgroundImage: `url(${picture})`, aspectRatio: '1/1' })
                    }
                >
                </div>
            }

            <div className={Style.body}>
                <p className={Style.site}>{text.split("\n").map(function (item, i) {
                    return (
                        <span key={i}>
                            {item}
                            <br />
                        </span>
                    )
                })}
                </p>
            </div>

            <Button
                className={Style.button}
                onClick={() => { sessionStorage.setItem("disclaimer", "true"); setDisclaimer(true) }}
            >
                Close
            </Button>
        </section>
    )
}

export default Disclaimer